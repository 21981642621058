<template>
  <div class="content gameinfo">
    <div class="panel">
      <div @click="func.tabPanelItem(idx)" :class="{'active':idx==formData.curIdx}" class="panel-item" v-for="(item,idx) in formData.tableData" :key="idx">{{item.Name}}</div>
    </div>
    <div class="item">
      <div class="inp">{{formData.curItem.Name}}</div>
      <VueEditor class="editor" v-model="formData.curItem.Content"></VueEditor>
    </div>
    <div class="footer">
      <el-button type="success" @click="func.save()">保存</el-button>
    </div>
  </div>
</template>

<script>
import { reactive, onMounted } from 'vue'
import axios from 'axios'
import { shortcuts } from '@/data/default'
import { toDay,tips,loading } from "@/common/func"

import { VueEditor } from "vue3-editor";

export default {
  name: 'SysInfo',
  components:{
    VueEditor
  },
  setup () {
    console.log("VueEditor",VueEditor)
    const tableData = []
    const render = {
      shortcuts,
    }
    const formData = reactive({
      tableData,
      curItem:{},
      curIdx:0,
    })
    const func = {
      tabPanelItem(idx){
        formData.curIdx = idx
        formData.curItem = formData.tableData[idx]
      },
      async save(){
        loading.show()
        try{
          let data = {
            id:formData.curItem.Id,
            content:formData.curItem.Content
          }
          await axios.post('/admin/gameinfo',data)
          // await this.loadData()
        }catch(e){
          console.log("err",e)
        }
        loading.hide()
        tips("操作成功")
      },
      async loadData(){
        try{
          formData.tableData = []
          let res = await axios.get(`/admin/gameinfo`)
          res.map((item)=>{
            item.CreatedAtStr = toDay(item.CreatedAt)
            item.UpdatedAtStr = toDay(item.UpdatedAt)
          })
          formData.tableData = res
          if(formData.tableData.length){
            func.tabPanelItem(0)
          }
        }catch(e){
          console.log("loadData",e)
        }
      }
    }
    onMounted(() => {
      console.log("onMounted")
      func.loadData()
    })
    return {
      func,
      render,
      formData
    }
  }
}
</script>

<style lang="scss" scoped>
.content{
  box-sizing: border-box;
  padding: 1.25rem;
  margin-bottom: 6.25rem;
  .panel{
    padding: 2rem ;
    box-sizing: border-box;
    display: grid;
    grid-template-columns: repeat(4,1fr);
    gap: 2rem;
    margin-bottom: 2.5rem;
    .panel-item{
        border: 1px solid #333;
        color: #333;
        background-color: #fff;
        padding: 0.625rem 0;
        border-radius: 0.5rem;
        cursor: pointer;
        &.active{
          border: 1px solid $color-primary;
          color: #fff;
          background-color: $color-primary;
        }
    }
  }
  &.gameinfo{
    >.footer{
      text-align: right;
    }
    >.item{
      background-color: #fff;
      margin-bottom: 2rem;
      .inp{
        font-size: 3rem;
        padding: 1.5rem 0;
      }
      .editor{
        width: 100%;
        height: 60rem;
        overflow: hidden;
      }
    }
  }
  .avatar{
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
  }
  .inp-block{
    display: block;
  }
  >.card-box{
    font-size: 0.875rem;
    background-color: #fff;
    box-shadow: $box-shadow;
    box-sizing: border-box;
    padding: 1.25rem;
    border-radius: 0.3rem;
    margin-bottom: 1.25rem;
    .header{
      text-align:left;
      margin-bottom: 1.25rem;
      position: relative;
      >.title{
        font-size:2rem;
        font-weight: 500;
      }
      >.header-icons{
        position: absolute;
        right: 10px;
        top: 0px;
        z-index: 100;
        .header-icon{
          font-size: 2rem;
          font-weight: 700;
          border: 2px solid #ddd;
          color:#969696;
          padding: 0.5rem;
          border-radius: 50%;
          color: pointer;
          margin-left: 0.625rem;
          cursor: pointer;
        }
      }
    }
    >.btn-ct{
      text-align: right;
      :deep(.el-button--small){
        padding: 0.625rem 2rem;
      }
    }
    .inp-list{
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: space-between;
      >.item{
        width: 32%;
        display: flex;
        align-items: center;
        margin-bottom: 1.25rem;
        .label{
          margin-right: 0.625rem;
          width: 6.375rem;
          font-size: 1.8rem;
        }
        :deep(.el-range-editor--small.el-input__inner){
          flex: 1;
        }
        .inp{
          flex: 1;
        }
      }
    }
  }
  >.el-alert-my{
    margin-bottom: 1.25rem;
    box-shadow: $box-shadow;
    padding: 1.25rem;
    .el-alert-my-txt{
      font-size: 1.8rem;
      >.tips{
        font-weight: bold;
      }
    }
    :deep(.el-alert__closebtn){
      top: 1.25rem;
      font-size: 1rem;
    }
  }
  >.data-table{
    :deep(.el-table){
      .cell{
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      .el-table__row{
        cursor: pointer;
      }
    }
  }
  >.pagination{
    text-align: right;
    margin-top: 1.25rem;
  }
  .el-form-row{
    display:flex;
    justify-content: space-between;
    :deep(.el-form-item){
      width: 49%;
    }
    &.el-form-row-2{
      :deep(.el-form-item){
        width: 100%;
      }
    }
  }
  .el-select-my{
    text-align: left;
    display: block;
  }
}
</style>

export function getType(type){
  let typeStr =  ""
  switch(type){
    case -1:
      typeStr = "全部"
      break
    case 0:
      typeStr = "充值"
      break
    case 1:
      typeStr = "提现"
      break
    case 2:
      typeStr = "下注"
      break
    case 3:
      typeStr = "赢注"
      break
    case 4:
      typeStr = "回水"
      break
    case 5:
      typeStr = "提现失败"
      break
    case 6:
      typeStr = "取消下注"
      break
    case 7:
      typeStr = "管理员操作"
    case 8:
      typeStr = "充值赠送"
      break
    case 9:
      typeStr = "回红"
      break
    case 10:
      typeStr = "福利"
      break
  }
  return typeStr
}
const choose = []
for(let i = -1;i<=10;i++){
  choose.push({
    label:getType(i),
    value:i
  })
}
export const typeChoose = choose

<template>
  <div class="content">
    <div class="panel panel-4 panel-bottom">
      <div class="title">
        <div class="txt">回水统一设置</div>
        <div class="note">*此处设定影响回水记录计算按钮的计算结果,1.6玩与其它玩法分开计算</div>
      </div>
      <div class="body">
        <div class="row" style="display: none;">
          <div class="left">
            自动返水设置:
          </div>
          <div class="right">
            <div class="radio">
                <el-radio class="rd" v-model="data.setting.IsAuto" :label="1" size="large">自动</el-radio>
                <el-radio class="rd" v-model="data.setting.IsAuto" :label="0" size="large">手动</el-radio>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="left">
            返水方式:
          </div>
          <div class="right">
            <div class="radio">
                <el-radio class="rd" v-model="data.setting.Type" :label="0" size="large">无返水</el-radio>
                <el-radio class="rd" v-model="data.setting.Type" :label="1" size="large">按流水</el-radio>
                <el-radio class="rd" v-model="data.setting.Type" :label="2" size="large">按盈利</el-radio>
                <el-radio class="rd" v-model="data.setting.Type" :label="3" size="large">按亏损</el-radio>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="left">
            返水条件:
          </div>
          <div class="right">
            <el-input type="number" class="inp" size="small" v-model="data.setting.MinTerm">
              <template #prepend>下注期数低于</template>
              <template #append>期,无返水</template>
            </el-input>
            <el-input type="number" class="inp" size="small" v-model="data.setting.MinCombo">
              <template #prepend>当下注总额少于</template>
              <template #append>以下,无返水</template>
            </el-input>
          </div>
        </div>
        <div class="row">
          <div class="left">
            返水比例:
          </div>
          <div class="right">
            <div class="item" v-for="(item,idx) in data.cases" :key="idx">
              <el-input type="number" class="inp" size="mini" v-model="item.MinMoney">
                <template #prepend>组合额占总额</template>
                <template #append>%以上,按</template>
              </el-input>
              <el-input type="number" class="inp" size="mini" v-model="item.Rate">
                <template #append>%回水</template>
              </el-input>
              <el-button class="btn" size="mini" @click="methods.add" type="primary" round>新增</el-button>
              <el-button size="mini" @click="methods.del(idx)" type="danger" round>删除</el-button>
            </div>
          </div>
        </div>
      </div>
      <div class="bottom">
        <el-button @click="methods.save" type="success" round>保存</el-button>
      </div>
    </div>
  </div>
</template>

<script>
  import axios from 'axios'
  import { onMounted,reactive } from 'vue'
  import { loading,tips,warn } from '@/common/func'
  export default{
    name:"WaterSetting",
    setup(props){
      const data = reactive({
        setting:{},
        cases:[],
      })
      const methods = {
        add(){
          data.cases.push({
            MinMoney:0,
            Rate:0
          })
        },
        async del(idx){
          if(data.cases.length<=1){
            return warn("最少要留一个")
          }
          loading.show()
          try{
            await axios.delete('/admin/water/case?id='+data.cases[idx].Id)
            data.cases.splice(idx,1)
          }catch(e){
            console.log("err",e)
          }
          loading.hide()
        },
        async save(){
          try{
            loading.show()
            //lot
            await axios.post('/admin/water/setting',{
              isAuto:data.setting.IsAuto,
              waterType:data.setting.Type,
              minTerm:data.setting.MinTerm,
              minCombo:data.setting.MinCombo,
            })
            //case
            for(let item of data.cases){
              if(item.Id){
                //更新
                await axios.post('/admin/water/case',{
                  id:item.Id,
                  rate:item.Rate,
                  minMoney:item.MinMoney
                })
              }else{
                //添加
                await axios.put('/admin/water/case',{
                  rate:item.Rate,
                  minMoney:item.MinMoney
                })
              }
            }
            await init()
          }catch(e){
            console.log("err",e)
          }finally{
            loading.hide()
          }
          tips("修改成功")
        }
      }
      const init = async ()=>{
        let res = await axios.get('/admin/water/setting')
        data.setting = res
        data.cases = res.Cases
      }
      onMounted(()=>{
        init()
      })
      return {
        methods,
        data
      }
    }
  }
</script>

<style lang="scss" scoped>
  .content{
    padding: 2rem ;
    box-sizing: border-box;
    .panel{
      background-color: #fff;
      border: 1px solid $border-color;
      border-top: 3px solid $color-info;
      border-radius: 0.5rem;
      >.bottom{
        text-align: right;
        padding: 2rem;
        box-sizing: border-box;
      }
      >.title{
        font-weight: 700;
        font-size: 2.5rem;
        border-bottom: 1px solid $border-color;
        padding: 1.5rem 2rem;
        text-align: left;
        >.note{
          font-size: 1.5rem;
          margin-top: 1rem;
          color: $color-default;
        }
      }
      >.body{
        padding: 1.5rem 2rem;
        box-sizing: border-box;
        >.row{
          display: flex;
          align-items: center;
          border: 1px solid $border-color;
          >div{
            font-size: 1.8rem;
            padding: 2rem;
            box-sizing: border-box;
          }
          .left{
            width: 30%;
          }
          .right{
            flex: 1;
            border-left: 1px solid $border-color;
            >.item{
              display: flex;
              align-items: center;
              .inp{
                margin-bottom: 0;
              }
              .btn{
                margin-left: 1rem;
              }
            }
          }
        }
        .inp{
          margin-bottom: 1rem;
          --el-color-info:#333;
          &.inline-inp{
            display: flex;
          }
          .inp-prepend{
            >.cb{
              --el-checkbox-font-size: 1.8rem;
            }
          }
        }
      }
    }
  }
</style>

<template>
  <div class="content">
    <div class="title">{{data.lot.Name}} - 赔率设置</div>
    <div class="grid">
      <div class="panel" :class="item.class" v-for="(item,idx) in data.cats" :key="idx">
        <div class="title">
          <div class="txt">{{item.Name}}设置</div>
          <el-checkbox v-model="item.Enable" label="启用" class="cb"></el-checkbox>
        </div>
        <div class="body">
          <el-input type="number" class="inp" size="small" v-model="item.Odds" v-if="item.Name != '单点' && item.Name != '组合'">
            <template #prepend>赔率</template>
            <template #append>倍</template>
          </el-input>
          <el-input type="number" class="inp" size="small" v-model="item.MaxScore">
            <template #prepend>总注封顶</template>
          </el-input>
          <div class="conf" v-for="(item2,idx2) in item.Lot28AutoOdds" :key="idx2">
            <div class="inp inline-inp" v-if="item2.Cond>=4">
              <el-input class="inp-2" size="small" v-model="item2.Total">
                <template #prepend>
                  <div class="inp-prepend">
                    <el-checkbox v-model="item2.Enable" label="总注 > " class="cb"></el-checkbox>
                  </div>
                </template>
              </el-input>
              <el-input class="inp-2" size="small" v-model="item2.Odds">
                <template #prepend>时 出13/14</template>
                <template #append>倍</template>
              </el-input>
            </div>
            <el-input class="inp" size="small"  v-if="item2.Cond == 0" v-model="item2.Odds">
              <template #prepend>
                <div class="inp-prepend">
                  <el-checkbox v-model="item2.Enable" label="出09时" class="cb"></el-checkbox>
                </div>
              </template>
              <template #append>倍</template>
            </el-input>
            <el-input class="inp" size="small"  v-if="item2.Cond == 1" v-model="item2.Odds">
              <template #prepend>
                <div class="inp-prepend">
                  <el-checkbox v-model="item2.Enable" label="出对子时" class="cb"></el-checkbox>
                </div>
              </template>
              <template #append>倍</template>
            </el-input>
            <el-input class="inp" size="small"  v-if="item2.Cond == 2" v-model="item2.Odds">
              <template #prepend>
                <div class="inp-prepend">
                  <el-checkbox v-model="item2.Enable" label="出顺子时" class="cb"></el-checkbox>
                </div>
              </template>
              <template #append>倍</template>
            </el-input>
            <el-input class="inp" size="small"  v-if="item2.Cond == 3" v-model="item2.Odds">
              <template #prepend>
                <div class="inp-prepend">
                  <el-checkbox v-model="item2.Enable" label="出豹子时" class="cb"></el-checkbox>
                </div>
              </template>
              <template #append>倍</template>
            </el-input>
          </div>
          <div class="lot-item" v-if="item.Name == '单点' || item.Name == '组合'">
            <el-input type="number" v-for="(item3,idx3) in item.LotItems" :key="idx3" class="inp" size="small" v-model="item3.Odds">
              <template #prepend>{{item3.Name}}</template>
              <template #append>倍</template>
            </el-input>
          </div>
        </div>
      </div>
      <div class="panel panel-4 panel-bottom">
        <div class="title">
          <div class="txt">彩种设置</div>
          <el-checkbox v-model="data.lot.Enable" label="启用" class="cb"></el-checkbox>
        </div>
        <div class="body">
          <el-input class="inp" size="small" v-model="data.lot.Name" disabled>
            <template #prepend>名称</template>
          </el-input>
          <el-input type="number" class="inp" size="small" v-model="data.lot.Ftime">
            <template #prepend>封盘时间</template>
            <template #append>秒</template>
          </el-input>
          <el-input type="number" class="inp" size="small" v-model="data.lot.MaxScore">
            <template #prepend>最大限额</template>
          </el-input>
          <el-input type="number" class="inp" size="small" v-model="data.lot.MinScore">
            <template #prepend>最小限额</template>
          </el-input>
          <div class="cb-item">
            <el-checkbox v-model="data.lot.IsBanSz" label="禁止杀组" class="cb"></el-checkbox>
          </div>
          <div class="cb-item" v-if="data.openTypeFlag">
              <el-radio class="rd" v-model="data.lot.OpenType" :label="1" size="large">自开</el-radio>
              <el-radio class="rd" v-model="data.lot.OpenType" :label="2" size="large">庄赢</el-radio>
          </div>
        </div>
        <div class="bottom">
          <el-button @click="methods.save" type="success" round>保存</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import axios from 'axios'
  import { onMounted,watch,reactive } from 'vue'
  import { useRoute } from 'vue-router'
  import { loading,tips } from '@/common/func'
  import { user } from '@/data/token'
  export default{
    name:"LotsOdds",
    setup(props){
      const data = reactive({
        cats:[],
        lot:{},
        openTypeFlag:false,
      })
      const methods = {
        async save(){
          console.log("save",data.cats)
          try{
            loading.show()
            const list = []
            const lotData = {
              lotId:data.lot.LotId,
              enable:data.lot.Enable?1:0,
              ftime:parseInt(data.lot.Ftime),
              maxScore:parseFloat(data.lot.MaxScore),
              minScore:parseFloat(data.lot.MinScore),
              banSz:data.lot.IsBanSz?1:0,
              openType:data.lot.OpenType,
            }
            //log
            list.push(axios.put('/admin/log/odds',{
              lotName:data.lot.Name,
              params:JSON.stringify({
                cats:data.cats,
                lot:lotData,
              }),
            }))
            //lot
            list.push(axios.post('/admin/lot',lotData))
            //odds
            for(let item of data.cats){
                const lotItem = []
                if(item.Name == "单点" || item.Name=="组合"){
                  for(let li of item.LotItems){
                    lotItem.push({
                      enable:li.Enable?1:0,
                      id:li.Id,
                      odds:parseFloat(li.Odds)
                    })
                  }
                }
                const cond = []
                for(let li of item.Lot28AutoOdds){
                  cond.push({
                    enable:li.Enable?1:0,
                    id:li.Id,
                    odds:parseFloat(li.Odds),
                    total:parseFloat(li.Total)
                  })
                }
                const postData = {
                  lotCatId:item.Id,
                  enable:item.Enable?1:0,
                  odds:parseFloat(item.Odds),
                  maxScore:parseFloat(item.MaxScore),
                  lotItem:JSON.stringify(lotItem),
                  lot28AutoOdds:JSON.stringify(cond),
                }
                list.push(axios.post('/admin/lot/odds',postData))
            }
            if(list.length){
              await Promise.all(list)
            }
          }catch(e){
            console.log("err",e)
          }finally{
            loading.hide()
          }
          tips("修改成功")
        }
      }
      onMounted(()=>{
        const route = useRoute()
        const init = async (id)=>{
          if(user.type == 'admin' && ["9","10","11","12"].includes(id)){
            data.openTypeFlag = true
          }else{
            data.openTypeFlag = false
          }
          loading.show()
          let res = await axios.get('/admin/lot/odds?lotId='+id)
          res.Lot.IsBanSz = res.Lot.BanSz === 1
          data.cats = res.LotCats
          data.lot = res.Lot
          data.lot.Enable = data.lot.Enable ? true : false
          for(let item of data.cats){
            item.Enable = item.Enable ? true : false
            for(let item2 of item.Lot28AutoOdds){
              item2.Enable = item2.Enable ? true : false
            }
            if(item.Lot28AutoOdds.length){
              item.class= "panel-2"
            }else if(item.Name=="单点"){
              item.class= "panel-4"
            }
          }
          loading.hide()
        }
        watch(()=>route,()=>{
          console.log("watch",route.query,route)
          const id = route.query.id || 0
          if(id)init(id)
        },{immediate:true,deep:true})
      })
      return {
        methods,
        user,
        data
      }
    }
  }
</script>

<style lang="scss" scoped>
  .title{
    font-size: 2.5rem;
    font-weight: 700;
    color: #333;
    margin-top: 0.625rem;
  }
  .grid{
    padding: 2rem ;
    box-sizing: border-box;
    display: grid;
    grid-template-columns: repeat(4,1fr);
    gap: 2rem;
    .panel{
      background-color: #fff;
      border: 1px solid $border-color;
      border-top: 3px solid $color-info;
      border-radius: 0.5rem;
      >.bottom{
        text-align: right;
        padding: 2rem;
      }
      &.panel-2{
        grid-column-start: span 2;
      }
      &.panel-bottom{
        margin-bottom: 10rem;
      }
      &.panel-4{
        grid-column-start: span 4;
        >.body{
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          >.inp{
            width: 49%;
          }
          .lot-item{
            width: 100%;
            margin-top: 1rem;
            display: grid;
            grid-template-columns: repeat(4,1fr);
            gap: 1rem 3rem;
            .inp{
            }
          }

        }
      }
      >.title{
        font-weight: 700;
        font-size: 2.5rem;
        border-bottom: 1px solid $border-color;
        padding: 1.5rem 2rem;
        display: flex;
        justify-content: space-between;
      }
      >.body{
        padding: 1.5rem 2rem;
        .cb-item{
          border:1px solid $border-color;
          width: 49%;
          text-align: left;
          padding: 1rem;
          box-sizing: border-box;
          border-radius: 0.5rem;
        }
        .inp{
          margin-bottom: 1rem;
          --el-color-info:#333;
          &.inline-inp{
            display: flex;
          }
          .inp-prepend{
            >.cb{
              --el-checkbox-font-size: 1.8rem;
            }
          }
        }
      }
    }
  }
</style>

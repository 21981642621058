<template>
	<div class="cpn chat">
		<div class="top">
			<div class="header" @click="showChooseLot = !showChooseLot">
				<!-- <img src="@/static/icon_back_black.png" class="back" @click="back()"> -->
				<div class="title">{{name}}</div>
        <i class="el-icon-arrow-down"></i>
			</div>
      <div class="lots" v-show="showChooseLot">
        <div class="item" v-for="(item,idx) in lots" :key="idx" @click="chooseLot(item.id)">{{item.name}}</div>
      </div>
			<div class="cur-info">
				<div class="term">
					<div>当前 {{termNum}} 期</div>
					<div class="status">
            <view class="second">{{counterTxt}}</view>
            <view>{{status}}</view>
          </div>
				</div>
				<div class="balance">
					<div>账号余额</div>
					<div class="money">{{money}}</div>
				</div>
			</div>
			<div class="cur-term" @click="showModal = !showModal">
				<div>第</div>
				<div class="term">{{lastTerm.termNum}}</div>
				<div>期</div>
				<div class="ball">{{lastTerm.ball1}}</div>
				<div class="opt">+</div>
				<div class="ball">{{lastTerm.ball2}}</div>
				<div class="opt">+</div>
				<div class="ball">{{lastTerm.ball3}}</div>
				<div class="opt">=</div>
				<div class="ball">{{lastTerm.sum}}</div>
				<div class="res">{{lastTerm.dx}}</div>
				<div class="res">{{lastTerm.ds}}</div>
				<div class="more">更多</div>
			</div>
      <div class="modal" v-show="showModal" @click="showModal = false">
      	<div class="content">
      		<div v-for="(item,index) in terms" class="cur-term">
      			<div>第</div>
      			<div class="term">{{item.termNum}}</div>
      			<div>期</div>
      			<div class="ball">{{item.ball1}}</div>
      			<div class="opt">+</div>
      			<div class="ball">{{item.ball2}}</div>
      			<div class="opt">+</div>
      			<div class="ball">{{item.ball3}}</div>
      			<div class="opt">=</div>
      			<div class="ball">{{item.sum}}</div>
      			<div class="res">{{item.dx}}</div>
      			<div class="res">{{item.ds}}</div>
      		</div>
      	</div>
      </div>
		</div>
		<div class="body" ref="chatBody" id="chatBody">
      <div class="loading">{{loadingMsg?"加载中...":"已到顶部"}}</div>
			<div :class="{'self':item.self,'chat-item':true}" v-for="(item,idx) in msgs" :key="idx">
				<div class="date">{{item.date}}</div>
				<div class="msg">
					<img class="left" :src="item.avatar"/>
					<div class="right">
						<div class="nickname">{{item.nickname}}</div>
						<div class="content" v-html="item.content"></div>
					</div>
				</div>
			</div>
		</div>
		<div class="panel">
			<input v-model="sendTxt" class="inp" @keyup.enter.native="sendMsg" />
			<button class="btn" @click="sendMsg">发送</button>
		</div>

	</div>
</template>

<script>
	import ws from "@/common/Websocket"
  import event from "@/common/eventHub"
  import { user } from "@/data/token"
  import axios from "axios"
	export default {
    name:"ChatRoom",
		data() {
			return {
				showModal:false,
        showChooseLot:false,
				lastTerm:{},
				terms:[],
				termNum:"",
				status:"",
				sendTxt:"",
				roomId:"",
				name:"",
        counter:0,
        ftime:0,
        counterTxt:"",
        pageId:0,
        loadingMsg:false,
        autoToBottom:true,//是否自动回到底部
        lots:[],
				msgs:[]
			}
		},
    async mounted(opt){
      let res = await axios.get("/public/lot")
      const lots = []
      for(let item of res){
        lots.push({
          name:item.Name + "玩法",
          id:item.LotId + ""
        })
      }
      this.lots = lots
      this.chooseLot(lots[0].id)
      await this.init(opt)
    },
		unmounted() {
			ws.getInstance().exitGrop(this.roomId)
			event.$off("new_chatroom_msg",this.chatRoomFunc)
		},
		computed:{
			money(){
				return "0.00"
			}
		},
		methods: {
      async init(opt) {
      	console.log("opt",opt)

        //开封盘倒计算
        this.itv = setInterval(()=>{
        	if(!this.ftime)return
        	this.counter --
        	if(this.counter > this.ftime){
        		const second = this.counter - this.ftime
        		let min = Math.floor(second / 60)
        		min = min < 10 ? '0' + min : min
        		let scd = second % 60
        		scd = scd < 10 ? '0' + scd : scd
        		this.counterTxt = min + ":" + scd
        		this.status = "后封盘"
        	}else if(this.counter >= 0){
        		this.counterTxt = this.counter
        		this.status = "秒后开奖"
        	}else{
      			this.counterTxt = ""
      			this.status = "开奖中"
      		}
        },1000)

      	//监听聊天室消息
        this.chatRoomFunc = (data)=>{
      		const msg = data.data
      		const self = data.from == user.wsId
      		if(data.type=="ban"){

      		}else if(data.type=="open"){
      			this.loadCurTerm()
      			this.loadTermHis()
      			//结算需要一点延迟
      		}else if(data.type=="bet"){
      		}
          const date = this.formatDate()
      		this.renderMsg({
      			nickname:msg.userinfo.nickname,
      			avatar:msg.userinfo.avatar,
      			date,
      			content:msg.content,
      			self
      		})
          if(this.autoToBottom){
          	this.toPageBottom()
          }
      	}
      	event.$on("new_chatroom_msg",this.chatRoomFunc)

        try{
          document.querySelector("#chatBody").addEventListener('scroll',async ()=>{
            let scrollTop = document.querySelector("#chatBody").scrollTop;
            //上拉加载
            if(scrollTop===0){
                if(this.loadingMsg)return
                const pageId = this.msgs.length ? this.msgs[0].id : 0
                this.loadingMsg = true
                await this.loadMsg(pageId)
                setTimeout(()=>{
                    document.querySelector("#chatBody").scrollTop = 1
                    this.loadingMsg = false
                },1000)
            }
            //自动滚动
            const container = this.$refs.chatBody;
            if(container.scrollHeight - container.offsetHeight - container.scrollTop <= 200){
                this.autoToBottom = true
            }else{
                this.autoToBottom = false
            }
          })
        }catch(e){

        }
      },
      chooseLot(id){
        if(this.roomId){
          ws.getInstance().exitGrop(this.roomId)
        }
        this.roomId = id
        for(let item of this.lots){
          if(item.id == this.roomId){
            this.name = item.name
          }
        }
        ws.getInstance().joinGroup(this.roomId)
        this.showChooseLot = false
        this.msgs = []
        //加载这个聊天室最后一页聊天记录
        this.loadMsg()
        this.loadTermHis()
        this.loadCurTerm()
      },
			async loadCurTerm(){
				let res = await axios.get("/admin/term?lotId="+this.roomId)
				this.termNum = res.TermNum
				this.counter = res.BeforeOpen
				this.ftime = res.BeforeOpen - res.BeforeBan
			},
			async loadTermHis(){
				try{
          let res = await axios.get("/public/result?lotId="+this.roomId)
          const term = []
          for(let item of res.List){
          	const balls = item.Result.split(",")
          	const ball1 = parseInt(balls[0])
          	const ball2 = parseInt(balls[1])
          	const ball3 = parseInt(balls[2])
          	const sum = ball1+ball2+ball3
          	const tmp = {
          		termNum:item.TermNum,
          		ball1,
          		ball2,
          		ball3,
          		sum:ball1+ball2+ball3,
          		dx:sum >= 14 ? "大" : "小",
          		ds:sum % 2 === 0  ? "双" : "单",
          	}
          	term.push(tmp)
          }
          if(term.length>0){
          	this.lastTerm = term[0]
          }
          this.terms = term
        }catch(e){
          console.log("error")
        }
			},
			back(){

			},
			async loadMsg(pageId=0){
        const params = {
          roomId:this.roomId,
        }
        if(pageId){
          params.pageId = pageId
        }
				let res = await axios.get("/admin/chat",{
          params
        })
        if(!res)return
        for(let i = res.length -1;i >= 0;i--){
          const item = res[i]
					const data = JSON.parse(item.Msg)
					const self = data.from == user.wsId
					const msg = data.data
					this.renderMsg({
            id:item.Id,
						nickname:msg.userinfo.nickname,
						avatar:msg.userinfo.avatar,
						date:msg.date,
						content:msg.content,
						self
					},true)
				}
        if(!pageId)this.toPageBottom()
			},
			toPageBottom(){
				//console.log('触发聊天滚动到底部')
				this.$nextTick(()=>{
          const container = this.$refs.chatBody;
          container.scrollTop = container.scrollHeight;
				})
			},
			sendMsg(){
				const txt = this.sendTxt.trim()
				this.sendTxt = ""
				if(!txt)return
				const date = this.formatDate()

				const sendObj = {
					type:"txt",
					content:txt,
					date,
					userinfo:{
						id:user.wsId,
						nickname:user.name,
						avatar:user.avatar,
					}
				}
				ws.getInstance().sendToGroup(this.roomId,sendObj)
				this.renderMsg({
					nickname:user.name,
					avatar:user.avatar,
					date,
					content:txt,
					self:true
				})
				this.toPageBottom()
			},
      renderMsg(msg,pre = false){
      	const obj = {
          id:msg.id || 0,
      		nickname:msg.nickname,
      		avatar:msg.avatar,
      		date:msg.date,
      		content:msg.content,
      		self:msg.self
      	}
      	if(pre){
      		this.msgs.unshift(obj)
      	}else{
      		this.msgs.push(obj)
      	}
      },
			formatDate(){
				let d = new Date()
				let m = d.getMonth() + 1
				m = m < 9 ? '0' + m : m
				let dy = d.getDate()
				dy = dy < 9 ? '0' + dy : dy
				let h = d.getHours()
				h = h < 9 ? '0' + h : h
				let mn = d.getMinutes()
				mn = mn < 9 ? '0' + mn : mn
				let s = d.getSeconds()
				s = s < 9 ? '0' + s : s
				return `${m}-${dy} ${h}:${mn}:${s}`
			}
		}
	}
</script>

<style lang="scss" scoped>
	.cpn{

	}
	.chat{
    width:100%;
		$panel-height:8rem;
    display: flex;
    flex-direction: column;
    height: 100%;
    position: relative;
		.top{
			width: 100%;
			background-color: #fff;
			color: #333;
      position: relative;
			>.cur-term{
				display: flex;
				width: 90%;
				margin: 0 auto;
				border-top: 1px solid $border-color;
				padding-top: 1rem;
				margin-top: 1rem;
				align-items: center;
				box-sizing: border-box;
				padding-bottom: 2rem;
        cursor: pointer;
				>div{

				}
				.term{
					color: $color-primary;
					margin: 0 0.6rem;
				}
				.opt{

				}
				.ball{
					background-color: $color-primary;
					width: 3rem;
					height: 3rem;
					line-height: 3rem;
					color: #fff;
					border-radius: 50%;
					text-align: center;
					margin: 0 0.6rem;
				}
				.res{
					background-color: $color-warning;
					width: 3rem;
					height: 3rem;
					line-height: 3rem;
					color: #fff;
					border-radius: 50%;
					text-align: center;
					margin: 0 0.6rem;
				}
				.more{
					font-size: 1.8rem;
					margin-left: 0.5rem;
					color: $color-primary;
				}
			}
			.cur-info{
				display: flex;
				justify-content: space-between;
				margin-top: 2rem;
				>div{
					width: 50%;
					text-align: center;
					box-sizing: border-box;
				}
				.term{
					border-right: 1px solid $border-color;
					.status{
						margin-top: 1rem;
						font-weight: 700;
						font-size: 2.0rem;
            display: flex;
            justify-content: center;
            align-items: center;
            color: $color-primary;
            .second{
            	margin-right: 0.5rem;
            	color: $color-error;
            }
					}
				}
				.balance{
					.money{
						font-weight: 700;
						color: $color-error;
						margin-top: 1rem;
            font-size: 2.0rem;
					}
				}
			}
			.header{
				text-align: center;
				position: relative;
				padding: 1rem 2rem;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
				.title{
					font-weight: 700;
				}
        .el-icon-arrow-down{
          font-weight: 700;
          margin-left: 0.5rem;
          font-size: 1.8rem;
        }
			}
      .lots{
        position: absolute;
        z-index: 100;
        top: 5rem;
        left: 0;
        background-color: #f1f4f8;
        width: 100%;
        >.item{
          padding: 1.5rem 0;
          font-size: 2rem;
          cursor: pointer;
          border-top: 1px solid $border-color;
        }
      }
		}
		.body{
			background-color: #e6f1ff;
			box-sizing: border-box;
			padding: 0 2rem;
			padding-top: 4rem;
			padding-bottom: $panel-height + 4rem;
      flex: 1;
      overflow-y:scroll;
      .loading{
        margin-bottom: 1rem;
      }
			.chat-item{
				margin-bottom: 4rem;
				// display: none;
				&.self{
					.msg{
						.left{
							order:1;
						}
						.right{
							order: 0;
							margin-left: 0;
							margin-right: 2rem;
							align-items: flex-end;
							.content{
								background-color: #09b91e;
							}
						}
					}
				}
				.date{
					text-align: center;
					font-size: 1.6rem;
					color: #666;
				}
				.msg{
					display: flex;
					.left{
						width: 6rem;
						height: 6rem;
						border-radius: 50%;
					}
					.right{
						margin-left: 2rem;
						display: flex;
						flex-direction: column;
						flex: 1;
						.nickname{
							color: #333;
							font-size: 2rem;
              text-align: left;
						}
						.content{
							max-width: 80%;
							background-color: #fff;
							color: #333;
							font-size: 1.8rem;
							letter-spacing: 0.2rem;
							padding: 1.2rem;
							margin-top: 1rem;
							border-radius: 1rem;
							width: fit-content;
              text-align: left;
						}
					}
				}
			}
		}
		.panel{
			width: 100%;
			height: $panel-height;
			display: flex;
			background-color: #d8e1f1;
			padding: 2rem;
			box-sizing: border-box;
      align-items: center;
			.inp{
				background-color: #fff;
				height: 100%;
				flex: 1;
				border-radius: 1rem;
				padding: 0 1rem;
			}
			.btn{
				margin-left: 2rem;
				height: 100%;
				width: 12rem;
				font-size: 2rem;
				font-weight: bold;
				color: #333;
        cursor: pointer;
			}
		}
	}
	.top>.modal{
		position: absolute;
    top: 21rem;
    left: 0;
		z-index: 100;
    width: 100%;
    background-color: #fff;
		>.content{
			.cur-term{
				display: flex;
				width: 90%;
				margin: 0 auto;
				border-bottom: 1px solid $border-color;
				margin-top: 1rem;
				align-items: center;
				box-sizing: border-box;
				padding-bottom: 2rem;
				>div{

				}
				.term{
					color: $color-primary;
					margin: 0 0.6rem;
          width: 8rem;
				}
				.opt{

				}
				.ball{
					background-color: $color-primary;
					width: 3rem;
					height: 3rem;
					line-height: 3rem;
					color: #fff;
					border-radius: 50%;
					text-align: center;
					margin: 0 0.6rem;
				}
				.res{
					background-color: $color-warning;
					width: 3rem;
					height: 3rem;
					line-height: 3rem;
					color: #fff;
					border-radius: 50%;
					text-align: center;
					margin: 0 0.6rem;
				}
				.more{
					font-size: 2.4rem;
					margin-left: 1rem;
					color: $color-primary;
				}
			}
		}
	}
</style>

<template>
    <div class="login_bg">
        <div class="login_bg3">
            <div class="login_bg2">
                <div class="login_hg_left">
                    <img :src="require('@/assets/img/login_bg.png')" />
                </div>
                <div v-if="loginType === 'qrcode'" class="login_black_hg">
                  <img class="qrcode" src="../assets/logo.png" />
                  <span class="qrcode-txt">微信扫一扫登录</span>
                </div>
                <div class="login_black_hg" v-else>
                    <div class="head_log">
                        {{appname}}
                        <p>MANAGE SYSTEM</p>
                    </div>
                    <div class="unline" style="margin-bottom: 30px"></div>
                    <div class="form-group">
                        <el-input style="width:260px" size="small" v-model="username" placeholder="用户名"></el-input>
                    </div>
                    <div class="form-group">
                        <el-input style="width:260px" type="password" size="small" v-model="password" class="login_input"
                            placeholder="密码"></el-input>
                    </div>
                    <el-button size="mini" class="login_btn" @click="login" type="primary">登 录</el-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref } from 'vue'
import cfg from '@/common/conf'
import { navTo } from '@/common/func'
import { useRoute } from 'vue-router'
import axios from "axios"
import { setUser,logout } from "@/data/token"
import { updateShowPanel } from '@/data/default'
export default {
  name: 'Login',
  setup () {
    //清除登录信息
    // logout()
    const route = useRoute()
    const username = ref('')
    const password = ref('')
    // const loginType = ref('qrcode')
    const loginType = ref('')
    if (route.query.type) {
      loginType.value = route.query.type
    }
    const login = async () => {
      try{
        let res = await axios.post("/public/admin/login",{
          username:username.value,
          password:password.value,
        })
        setUser(res)
        updateShowPanel(true)
        navTo('/')
      }catch(e){

      }
      // navTo('/')
    }
    return {
      username,
      password,
      login,
      loginType,
      appname: cfg.appname
    }
  }
}
</script>

<style scoped>
.clear{
  clear: both;
}
.qrcode{
  width: 200px;
  height: 200px;
}
.qrcode-txt{
  display: block;
  text-align: center;
  font-size: 13px;
  margin-top: 10px;
  color: #333;
}
.login_bg {
    background-position: center;
    background-size: 100%;
    height: 100%;
    background-color: #f1f1f1;
    background-image: linear-gradient(to right, #5d80fe, #56c9ff);
}

.head_log {
    text-align: center;
    line-height: 20px;
    font-size: 26px;
}

.head_log p {
    color: #666;
    line-height: 40px;
    font-size: 12px;
    margin-bottom: 10px;
}

.login_bg2 {
    width: 100%;
    height: 100vh;
    max-width: 1000px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.login_bg3 {
    height: 100%;
    /* background: url('/login/bg.png'); */
    background-position: top right;
    background-repeat: no-repeat;
}

.login_bg2:after {
    display: block;
    content: '';
    clear: both;
}

.login_black_hg {
    background: #fff;
    border-radius: 6px;
    padding: 0px 40px 20px 40px;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    /* Firefox */
    -webkit-box-sizing: border-box;
    /* Safari */
    padding-top: 40px;
}

.login_hg_left {

}

.login_input {
    margin: 20px 0 20px 0;
}

.login_btn {
    margin-top: 20px;
    width: 100%;
    font-size: 14px;
}

.must_rad {
    font-size: 12px;
}
</style>

<template>
  <div class="content">
    <div class="card-box">
      <div class="header">
        <span class="title">查询条件</span>
      </div>
      <div class="inp-list">
        <div class="item">
          <span class="label">状态</span>
          <el-select class="inp" size="small" v-model="formData.valStatus" placeholder="请选择">
              <el-option
                v-for="item in render.statuses"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
        </div>
        <div class="item">
          <span class="label">昵称</span>
          <el-input class="inp" size="small" v-model="formData.valNickname" placeholder="请输入会员昵称"></el-input>
        </div>
        <div class="item">
          <span class="label">彩种</span>
          <el-select class="inp" size="small" v-model="formData.valLotId" placeholder="请选择">
              <el-option
                v-for="item in formData.lots.search"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
        </div>
      </div>
      <div class="btn-ct">
        <el-button @click="func.search" class="search" type="primary" size="small">查询</el-button>
      </div>
    </div>
    <el-alert
        class="el-alert-my"
        type="success">
        <template #title>
          <div class="el-alert-my-txt">
            <span class="tips">提示：</span>
            <span>双击记录进行编辑</span>
          </div>
        </template>
    </el-alert>
    <div class="card-box data-table">
      <div class="header">
        <span class="title">共{{formData.total}}条，每页{{formData.pageSize}}条</span>
        <div class="header-icons">
          <i @click="func.add()" class="el-icon-plus header-icon"></i>
          <!-- <i @click="func.refresh()" class="el-icon-refresh header-icon"></i> -->
        </div>
      </div>
      <el-table
            @row-dblclick="func.rowDblClick"
            :data="formData.tableData"
            style="width: 100%">
            <el-table-column type="expand">
                  <template #default="props">
                    <div class="table-expand">
                      <div class="item" v-if="props.row.BetType == 1">
                        <div class="label">下注内容</div>
                        <div class="value">{{props.row.BetContent}}</div>
                      </div>
                      <div class="item" v-else>
                        <div class="label">下注范围</div>
                        <div class="value">{{props.row.MinScore}}-{{props.row.MaxScore}}</div>
                      </div>
                    </div>
                  </template>
                </el-table-column>
            <el-table-column
              prop="UserId"
              label="用户ID"
              width="100">
            </el-table-column>
            <el-table-column
              prop="Nickname"
              label="昵称"
              width="200">
            </el-table-column>
            <el-table-column
              prop="BetTypeStr"
              width="80"
              label="下注方式">
            </el-table-column>
            <el-table-column
              prop="DateStart"
              width="120"
              label="开始下注时间">
            </el-table-column>
            <el-table-column
              prop="DateEnd"
              width="120"
              label="结束下注时间">
            </el-table-column>
            <el-table-column
              prop="EnableStr"
              width="100"
              label="状态">
            </el-table-column>
            <el-table-column
              width="140"
              label="头像">
              <template #default="scope">
                <img class="avatar" :src="scope.row.Avatar" />
              </template>
            </el-table-column>
            <el-table-column
              prop="lotNames"
              min-width="200"
              label="彩种">
            </el-table-column>
            <el-table-column
              prop="CreatedAtStr"
              min-width="200"
              label="注册时间">
            </el-table-column>
          </el-table>
    </div>
    <div class="pagination">
      <el-pagination
        background
        layout="prev, pager, next"
        @current-change="func.pageChange"
        :page-size="formData.pageSize"
        :current-page="formData.curPage"
        :total="formData.total">
      </el-pagination>
    </div>
    <el-dialog
      title="提示"
      v-model="formData.dialogVisible"
      width="50%">
      <el-form label-position="top" label-width="80px" :model="formData.curData">
        <div class="el-form-row img">
          <div class="label">头像</div>
          <el-upload
              class="avatar-uploader"
              action="/public/upload"
              :show-file-list="false"
              :on-success="func.handleUploadSuccess"
            >
            <img v-if="formData.curData.Avatar" class="img-item" :src="formData.curData.Avatar" />
            <div v-else class="upload-txt">点击上传</div>
          </el-upload>
          <el-select multiple class="inp" size="small" v-model="formData.curData.lots" placeholder="请选择彩种">
              <el-option
                v-for="item in formData.lots.choose"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
        </div>
        <div class="el-form-row">
          <el-form-item label="昵称">
            <el-input v-model="formData.curData.Nickname"></el-input>
          </el-form-item>
          <el-form-item label="是否启用">
            <el-select class="inp-block" v-model="formData.curData.Enable" placeholder="请选择">
                <el-option
                  v-for="item in render.addEnables"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
          </el-form-item>
        </div>

        <div class="el-form-row">
          <el-form-item label="开始下注时间(时)">
            <el-input type="number" v-model="formData.curData.DateStart"></el-input>
          </el-form-item>
          <el-form-item label="结束下注时间(时)">
            <el-input type="number" v-model="formData.curData.DateEnd"></el-input>
          </el-form-item>
        </div>

        <div class="el-form-row">
          <el-form-item label="下注方式">
            <el-select class="inp-block" v-model="formData.curData.BetType" placeholder="请选择">
                <el-option
                  v-for="item in render.BetTypes"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
          </el-form-item>
          <el-form-item label="下注内容"  v-if="formData.curData.BetType==1">
            <el-input v-model="formData.curData.BetContent" placeholder="用|分隔,如:大 100|大单 20|极大 20"></el-input>
          </el-form-item>
        </div>

        <div class="el-form-row" v-if="formData.curData.BetType==0">
          <el-form-item label="最小下注">
            <el-input type="number" v-model="formData.curData.MinScore"></el-input>
          </el-form-item>
          <el-form-item label="最大下注">
            <el-input type="number" v-model="formData.curData.MaxScore"></el-input>
          </el-form-item>
        </div>

      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="formData.dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="func.submit()">确 定</el-button>
        </span>
      </template>
    </el-dialog>

  </div>
</template>

<script>
import { reactive, onMounted } from 'vue'
import axios from 'axios'
import { shortcuts } from '@/data/default'
import { lots,getLotName } from '@/data/lot'
import { toDay,tips,loading,warn } from "@/common/func"
export default {
  name: 'MemberRobot',
  setup () {
    const tableData = []
    const statuses = [
      {
        label: '全部',
        value: -1
      },
      {
        label: '可用',
        value: 1
      },
      {
        label: '不可用',
        value: 0
      }
    ]
    const addEnables = [
      {
        label: '可用',
        value: 1
      },
      {
        label: '不可用',
        value: 0
      }
    ]
    const render = {
      shortcuts,
      statuses,
      addEnables,
      BetTypes:[
      {
        label: '自动',
        value: 0
      },
      {
        label: '手动',
        value: 1
      }
    ]
    }
    const formData = reactive({
      lots,
      tableData,
      curData: {},
      valStatus: -1,
      valNickname: '',
      valLotId:0,
      dialogVisible:false,
      // pagination
      curPage: 1,
      pageSize: 10,
      total: 1
    })
    const func = {
      handleUploadSuccess(res,file){
        if(res.Code !== 0){
          return warn(res.Msg)
        }
        formData.curData.Avatar = res.Data.url
      },
      add(){
        formData.curData = {
          lots:[]
        }
        formData.dialogVisible = true
      },
      rowDblClick (row, column, e) {
        console.log('rowDblClick', row, column, e)
        formData.curData = Object.assign({},row)
        formData.dialogVisible = true
      },
      search () {
        console.log('formData', formData)
        func.loadData(1)
      },
      pageChange (val) {
        console.log('page change', val)
        func.loadData(val)
      },
      async submit(){
        let lotIds = ""
        for(let item of formData.curData.lots){
          lotIds += "," + item
        }
        if(lotIds){
          lotIds = lotIds.substr(1)
        }
        const data = {
          maxScore:formData.curData.MaxScore,
          minScore:formData.curData.MinScore,
          betContent:formData.curData.BetContent,
          dateStart:formData.curData.DateStart,
          dateEnd:formData.curData.DateEnd,
          avatar:formData.curData.Avatar,
          nickname:formData.curData.Nickname,
          enable:formData.curData.Enable,
          betType:formData.curData.BetType,
          lotIds
        }
        if(formData.curData.Id){
          data.robotId = formData.curData.Id
          await axios.post('/admin/robot',data)
        }else{
          await axios.put('/admin/robot',data)
        }
        this.loadData()
        formData.dialogVisible = false
        tips("操作成功")
      },
      async loadData(page=1){
        try{
          formData.tableData = []
          formData.curPage = page
          let params = {
              page,
          }
          if(formData.valLotId){
            params.lotId = formData.valLotId
          }
          if(formData.valNickname){
            params.nickname = formData.valNickname
          }
          if(formData.valStatus != -1){
            params.enable = formData.valStatus
          }
          if(formData.valTime){
            params.startAt = Math.floor(formData.valTime[0].getTime()/1000)
            params.endAt = Math.floor(formData.valTime[1].getTime()/1000)
          }
          let res = await axios.get(`/admin/robot`,{
            params
          })
          formData.total = res.Total
          res.List.map((item)=>{
            item.CreatedAtStr = toDay(item.CreatedAt)
            item.EnableStr = item.Enable === 1 ? "可用" : "不可用"
            item.BetTypeStr = item.BetType === 0 ? "自动" : "手动"
            let lots = []
            let lotNames = ""
            if(item.LotIds){
              const lotsTmp = item.LotIds.split(",")
              for(let lotId of lotsTmp){
               lotNames += "," + getLotName(lotId)
               lots.push(parseInt(lotId))
              }
              lotNames = lotNames.substr(1)
            }
            item.lots = lots
            item.lotNames = lotNames
          })
          formData.tableData = res.List
          formData.pageSize = res.PageSize
        }catch(e){
          console.log("loadData",e)
        }
      }
    }
    onMounted(() => {
      console.log("onMounted")
      func.loadData()
    })
    return {
      func,
      render,
      formData
    }
  }
}
</script>

<style lang="scss" scoped>
.content{
  box-sizing: border-box;
  padding: 1.25rem;
  margin-bottom: 6.25rem;
  .avatar{
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
  }
  .inp-block{
    display: block;
  }
  >.card-box{
    font-size: 0.875rem;
    background-color: #fff;
    box-shadow: $box-shadow;
    box-sizing: border-box;
    padding: 1.25rem;
    border-radius: 0.3rem;
    margin-bottom: 1.25rem;
    .header{
      text-align:left;
      margin-bottom: 1.25rem;
      position: relative;
      >.title{
        font-size:2rem;
        font-weight: 500;
      }
      >.header-icons{
        position: absolute;
        right: 10px;
        top: 0px;
        z-index: 100;
        .header-icon{
          font-size: 2rem;
          font-weight: 500;
          border: 2px solid #ddd;
          color:#969696;
          padding: 0.25rem;
          border-radius: 50%;
          color: pointer;
          margin-left: 0.625rem;
          cursor: pointer;
        }
      }
    }
    >.btn-ct{
      text-align: right;
      :deep(.el-button--small){
        padding: 0.625rem 2rem;
      }
    }
    .inp-list{
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: space-between;
      >.item{
        width: 32%;
        display: flex;
        align-items: center;
        margin-bottom: 1.25rem;
        .label{
          margin-right: 0.625rem;
          width: 6.375rem;
          font-size: 1.8rem;
        }
        :deep(.el-range-editor--small.el-input__inner){
          flex: 1;
        }
        .inp{
          flex: 1;
        }
      }
    }
  }
  >.el-alert-my{
    margin-bottom: 1.25rem;
    box-shadow: $box-shadow;
    padding: 1.25rem;
    .el-alert-my-txt{
      font-size: 1.8rem;
      >.tips{
        font-weight: bold;
      }
    }
    :deep(.el-alert__closebtn){
      top: 1.25rem;
      font-size: 1rem;
    }
  }
  >.data-table{
    :deep(.el-table){
      .cell{
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      .el-table__row{
        cursor: pointer;
      }
    }
  }
  >.pagination{
    text-align: right;
    margin-top: 1.25rem;
  }
  .el-form-row{
    display:flex;
    justify-content: space-between;
    align-items: center;
    :deep(.el-form-item){
      width: 49%;
    }
    .img-item{
      width:10rem;
      height: 10rem;
    }
    .upload-txt{
      width:10rem;
      height: 10rem;
      line-height: 10rem;
      text-align: center;
      border: 2px dotted #333;
    }
  }
  .el-select-my{
    text-align: left;
    display: block;
  }
}
</style>

<template>
  <div class="component">
    <ChatRoom v-if="data.pageIdx==0"/>
    <ChatList v-else-if="data.pageIdx==1"/>
    <ChatAssist v-else @back="methods.singleChatBack" :userId="data.singleChatUserId" :title="data.singleChatTitle"/>
    <!-- <div class="fast-icon" v-if="data.pageIdx==0" @click="methods.switchPageIdx(1)">
      <div class="num" v-if="data.unReadNum">{{data.unReadNum}}</div>
      <i class="icon el-icon-message-solid"></i>
    </div>
    <div class="fast-icon" v-else  @click="methods.switchPageIdx(0)">
      <i class="icon el-icon-s-help"></i>
    </div> -->
  </div>
</template>

<script>
import { reactive,onMounted,onUnmounted, nextTick } from 'vue'
import event from "@/common/eventHub"
import axios from "axios"
import ChatRoom from '@/components/chat/chatroom'
import ChatList from '@/components/chat/chatList'
import ChatAssist from "@/components/chat/assist"
export default {
  name: 'PublicLeft',
  components:{
    ChatRoom,
    ChatList,
    ChatAssist
  },
  setup () {

    const methods = {
      singleChatBack(){
        methods.switchPageIdx(1)
      },
      switchPageIdx(idx){
        data.pageIdx = idx
      },
      async getCount(){
        let res = await axios.get("/admin/chat/list")
        let num = 0
        for(let item of res){
          num+=item.Unread
        }
        if(num===0){
          data.unReadNum = ""
        }else if(num>99){
          data.unReadNum = "99+"
        }else{
          data.unReadNum = num + ""
        }
      }
    }
    const data = reactive({
      pageIdx:0,
      unReadNum:"",
      singleChatUserId:"",
      singleChatTitle:"",
    })
    const onUserMsg = ()=>{
      methods.getCount()
    }
    const toChat = async (uid,nickname)=>{
      if(data.pageIdx == 2){
          data.pageIdx = 1
      }
      await nextTick()
      data.singleChatUserId = uid
      data.singleChatTitle = `${nickname}(${uid})`
      data.pageIdx = 2
    }
    onMounted(()=>{
      event.$on("new_user_msg",onUserMsg)
      event.$on("read_user_msg",onUserMsg)
      event.$on("to_chat",toChat)
      methods.getCount()
    })
    onUnmounted(()=>{
      event.$off("new_user_msg",onUserMsg)
      event.$off("read_user_msg",onUserMsg)
      event.$off("to_chat",toChat)
    })
    return {
      methods,
      data
    }
  }
}
</script>

<style lang="scss" scoped>
  .component{
    // width: 15vw;
    width: 50rem;
    padding-right:2rem;
    position: relative;
    //样式穿透
    .fast-icon{
      cursor: pointer;
      position: absolute;
      bottom: 40rem;
      right: 5rem;
      width: 7rem;
      height: 7rem;
      border-radius: 50%;
      background-color: #fff;
      box-shadow: $box-shadow;
      z-index: 2;
      display: flex;
      justify-content: center;
      align-items: center;
      >.num{
        position: absolute;
        background-color: $color-error;
        top: -1rem;
        right: -1rem;
        color: #fff;
        height: 3.8rem;
        width: 3.8rem;
        line-height: 3.8rem;
        font-weight: 700;
        border-radius: 50%;
        text-align: center;
      }
      >.icon{
        font-size: 3.5rem;
        color: #666666;
      }
    }
  }
</style>

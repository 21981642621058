export default{
  set(key,val){
    const type = typeof key
    const saveObj = {
      type,
      val
    }
    console.log("saveObj",saveObj)
    localStorage.setItem(key,JSON.stringify(saveObj))
  },
  get(key){
    let res = localStorage.getItem(key)
    if(!res)return false
    try{
      res = JSON.parse(res)
      return res.val
    }catch(e){
      return ""
    }
  }
}

import router from '@/router/index'
import { ElLoading,ElMessage } from 'element-plus'
export function navTo (path, query = {}) {
  router.push({
    path,
    query
  })
}
export const loading = {
  show(){
    if(this.lObj){
      this.lObj.close()
    }
    this.lObj = ElLoading.service({
      lock: true,
      text: 'Loading',
      background: 'rgba(0, 0, 0, 0.7)',
    })
  },
  hide(){
    if(this.lObj){
      this.lObj.close()
    }
  }
}

export function warn(str){
  ElMessage({
    type: 'warning',
    message: str
  })
}

export function tips(str){
  ElMessage({
    type: 'success',
    message: str
  })
}

export function toDay(timestamp){
  let date = new Date(timestamp * 1000)
  let y = date.getFullYear()
  let m = date.getMonth() + 1
  m = m < 10 ? "0"+m : m
  let d = date.getDate()
  d = d < 10 ? "0"+d : d
  return `${y}-${m}-${d} `+date.toTimeString().substr(0,8)
}

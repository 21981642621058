<template>
  <div class="content">
    <div class="card-box">
      <div class="header">
        <span class="title">查询条件</span>
      </div>
      <div class="inp-list">
        <div class="item item-3">
          <span class="label">时间</span>
          <el-date-picker
                class="inp"
                v-model="formData.valTime"
                size="small"
                type="daterange"
                unlink-panels
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                :shortcuts="render.shortcuts"
              >
              </el-date-picker>
        </div>
        <div class="item">
          <span class="label">昵称或用户id</span>
          <el-input class="inp" size="small" v-model="formData.valNickname" placeholder="请输入昵称或用户id"></el-input>
        </div>
        <div class="item">
          <span class="label">单号</span>
          <el-input class="inp" size="small" v-model="formData.valOrderSn" placeholder="请输入单号"></el-input>
        </div>
        <div class="item">
          <span class="label">提现方式</span>
          <el-select class="inp" size="small" v-model="formData.valType" placeholder="请选择">
              <el-option
                v-for="item in formData.accountTypes"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
        </div>

      </div>
      <div class="btn-ct">
        <el-button @click="func.search" class="search" type="primary" size="small">查询</el-button>
      </div>
    </div>
    <el-alert
        class="el-alert-my"
        type="success">
        <template #title>
          <div class="el-alert-my-txt">
            <span class="tips">提示：</span>
            <span>双击记录改变状态</span>
          </div>
        </template>
    </el-alert>
    <div class="card-box data-table">
      <div class="header">
        <span class="title">共{{formData.total}}条，每页{{formData.pageSize}}条</span>
        <div class="stat">
          <div class="item" v-for="item,idx in formData.accountTypeStats">
            <div class="key">{{item.Name}}:</div>
            <div class="value">{{item.Summary}}</div>
          </div>
        </div>
      </div>
      <el-table
            :data="formData.tableData"
            stripe
            @row-dblclick="func.rowDblClick"
            style="width: 100%">
            <el-table-column
              prop="Id"
              label="ID"
              width="100">
            </el-table-column>
            <el-table-column
              prop="OrderSn"
              width="200"
              label="单号">
            </el-table-column>
            <el-table-column
              prop="UserId"
              width="120"
              label="用户id">
            </el-table-column>
            <el-table-column
              prop="Nickname"
              width="140"
              label="用户名">
            </el-table-column>
            <el-table-column
              prop="Money"
              width="120"
              label="金额">
            </el-table-column>
            <el-table-column
              label="提现账号"
              min-width="440">
              <template #default="scope">
                <div class="account-str">
                  <span>{{scope.row.AccountStr}}</span>
                  <a v-if="scope.row.AccountQrcode" :href="scope.row.AccountQrcode" target="_blank">查看</a>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="ResultStr"
              width="140"
              label="结果">
              <template #default="scope">
                <div :class="'color-'+scope.row.ResultType">{{scope.row.ResultStr}}</div>
              </template>
            </el-table-column>
            <el-table-column
              prop="CreatedAtStr"
              width="200"
              label="时间">
            </el-table-column>
          </el-table>
    </div>
    <div class="pagination">
      <el-pagination
        background
        layout="prev, pager, next"
        @current-change="func.pageChange"
        :page-size="formData.pageSize"
        :current-page="formData.curPage"
        :total="formData.total">
      </el-pagination>
    </div>
    <el-dialog
      title="提示"
      v-model="formData.dialogVisible"
      width="50%">
      <el-form label-position="top" label-width="80px" :model="formData.curData">
        <img class="account-qrcode" v-if="formData.curData.AccountQrcode" :src="formData.curData.AccountQrcode"/>
        <div class="el-form-row">
          <el-form-item label="状态">
            <el-radio class="rd" v-model="formData.curData.Status" :label="1" size="large">通过</el-radio>
            <el-radio class="rd" v-model="formData.curData.Status" :label="2" size="large">拒绝</el-radio>
          </el-form-item>
        </div>

      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="formData.dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="func.submit()">确 定</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { reactive, onMounted,onUnmounted } from 'vue'
import axios from 'axios'
import { shortcuts } from '@/data/default'
import { getAccountTypes,getTypeName } from '@/data/accountType'
import { toDay,warn,loading,tips } from "@/common/func"
import event from "@/common/eventHub"
import md5 from "js-md5"
export default {
  name: 'MemberWithdraw',
  setup () {
    const tableData = []
    const render = {
      shortcuts,
    }
    const formData = reactive({
      tableData,
      accountTypes:[],
      curData: {},
      dialogVisible:false,
      valTime: '',
      valOrderSn:'',
      valNickname:'',
      valType:0,
      accountTypeStats:[],
      // pagination
      curPage: 1,
      pageSize: 10,
      total: 1
    })
    const func = {
      search () {
        console.log('formData', formData)
        func.loadData(1)
      },
      pageChange (val) {
        console.log('page change', val)
        func.loadData(val)
      },
      refresh(){
        formData.valTime = '',
        func.loadData(1)
      },
      rowDblClick (row, column, e) {
        console.log('rowDblClick', row, column, e)
        if(row.Status!=0){
          return warn("记录已被处理")
        }
        formData.curData = row
        formData.dialogVisible = true
      },
      async submit(){
        loading.show()
        try{
          await axios.post("/admin/withdraw",{
            id:formData.curData.Id,
            status:formData.curData.Status
          })
          await this.loadData(formData.curPage)
        }catch(e){
          formData.curData.Status = 0
          formData.dialogVisible = false
          loading.hide()
          return
        }
        formData.dialogVisible = false
        loading.hide()
        tips("操作成功")
      },
      async loadData(page=1){
        try{
          formData.tableData = []
          formData.curPage = page
          let params = {
              page,
          }
          if(formData.valOrderSn){
            params.orderSn = formData.valOrderSn
          }
          if(formData.valNickname){
            let id = parseInt(formData.valNickname)
            if(isNaN(id)){
              params.nickname = formData.valNickname
            }else{
              params.userId = formData.valNickname
            }
          }
          if(formData.valTime){
            params.startAt = Math.floor(formData.valTime[0].getTime()/1000)
            params.endAt = Math.floor(formData.valTime[1].getTime()/1000)
          }
          if(formData.valType){
            params.accountType = formData.valType
          }
          let res = await axios.get(`/admin/withdraw`,{
            params
          })
          formData.total = res.Total
          res.List.map((item)=>{
            item.CreatedAtStr = toDay(item.CreatedAt)
            item.ResultStr =  ""
            item.ResultType = 0
            if(item.Status==0){
              item.ResultStr = "未处理"
              item.ResultType = 1
            }else if(item.Status==1){
              item.ResultStr = "已通过"
              item.ResultType = 2
            }else if(item.Status==2){
              item.ResultStr ="已拒绝"
              item.ResultType = 3
            }else if(item.Status==3){
              item.ResultStr ="处理中"
              item.ResultType = 3
            }
            try{
              const account = JSON.parse(item.Account)
              if(account.Type==1){
                  item.AccountStr = `类型:${getTypeName(account.Type)},姓名:${account.Name},号码:${account.Account}`
              }else if(account.Type==5){
                  item.AccountStr = `类型:${getTypeName(account.Type)},地址:${account.Account}`
                  // item.AccountStr = `类型:${getTypeName(account.Type)},地址:${md5(account.Id+"")}`
              }else if(account.Type==4){
                  item.AccountStr = `类型:${getTypeName(account.Type)},协议:${account.Name},地址:${account.Account}`
              }else if(account.Type == 2){
                  item.AccountStr = `类型:${getTypeName(account.Type)},姓名:${account.Name},账号:${account.Account}`
                  item.AccountQrcode = account.Qrcode
              }else{
                  item.AccountStr = `类型:${getTypeName(account.Type)}`
                  item.AccountQrcode = account.Qrcode
              }
            }catch(e){
              console.log("err",e)
            }
          })
          formData.tableData = res.List
          formData.pageSize = res.PageSize
          formData.accountTypeStats = res.AccountTypeStats
        }catch(e){
          console.log("loadData",e)
        }
      }
    }
    const handleEventMsg = (msg)=>{
      if(msg.name=="withdraw"){
        func.loadData(1)
      }
    }
    onMounted(async () => {
      console.log("onMounted")
      event.$on("event",handleEventMsg)
      formData.accountTypes = await getAccountTypes(2)
      func.loadData()
    })
    onUnmounted(()=>{
      event.$off("event",handleEventMsg)
    })
    return {
      func,
      render,
      formData
    }
  }
}
</script>

<style lang="scss" scoped>
.content{
  box-sizing: border-box;
  padding: 1.25rem;
  margin-bottom: 6.25rem;
  .color-0{
    color: $color-warning;
    font-weight: bold;
  }
  .color-1{
    color: $color-info;
    font-weight: bold;
  }
  .color-2{
    color: $color-success;
    font-weight: bold;
  }
  .color-3{
    color: $color-error;
    font-weight: bold;
  }
  .avatar{
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
  }
  .inp-block{
    display: block;
  }
  .account-qrcode{
    width: 25rem;
    height: 25rem;
  }
  .account-str{
    white-space: normal;
    >a{
      margin-left: 1rem;
    }
  }
  >.card-box{
    font-size: 0.875rem;
    background-color: #fff;
    box-shadow: $box-shadow;
    box-sizing: border-box;
    padding: 1.25rem;
    border-radius: 0.3rem;
    margin-bottom: 1.25rem;
    .header{
      text-align:left;
      margin-bottom: 1.25rem;
      position: relative;
      >.title{
        font-size:2rem;
        font-weight: 500;
        display: block;
      }
      >.stat{
        font-size: 2.5rem;
        display: flex;
        margin-top: 1rem;
        font-weight: bold;
        >.item{
          display: flex;
          margin-right: 5rem;
          .key{
            margin-right: 1rem;
          }
          .value{

          }
        }
      }
      >.header-icons{
        position: absolute;
        right: 10px;
        top: 0px;
        z-index: 100;
        .header-icon{
          font-size: 0.875rem;
          font-weight: 500;
          border: 1px solid #ddd;
          color:#969696;
          padding: 0.25rem;
          border-radius: 50%;
          color: pointer;
          margin-left: 0.625rem;
          cursor: pointer;
        }
      }
    }
    >.btn-ct{
      text-align: right;
      :deep(.el-button--small){
        padding: 0.625rem 2rem;
      }
    }
    .inp-list{
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      >.item{
        width: 32%;
        display: flex;
        align-items: center;
        margin-bottom: 1.25rem;
        &.item-2{
          width: 99%;
        }
        &.item-3{
          width: 64%;
        }
        .label{
          margin-right: 0.625rem;
          width: 14rem;
          font-size: 1.8rem;

        }
        :deep(.el-range-editor--small.el-input__inner){
          flex: 1;
        }
        .inp{
          flex: 1;
        }
      }
    }
  }
  >.el-alert-my{
    margin-bottom: 1.25rem;
    box-shadow: $box-shadow;
    padding: 1.25rem;
    .el-alert-my-txt{
      font-size: 1.8rem;
      >.tips{
        font-weight: bold;
      }
    }
    :deep(.el-alert__closebtn){
      top: 1.25rem;
      font-size: 1rem;
    }
  }
  >.data-table{
    :deep(.el-table){
      .cell{
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      .el-table__row{
        cursor: pointer;
      }
    }
  }
  >.pagination{
    text-align: right;
    margin-top: 1.25rem;
  }
  .el-form-row{
    display:flex;
    justify-content: space-between;
    :deep(.el-form-item){
      width: 49%;
    }
  }
  .el-select-my{
    text-align: left;
    display: block;
  }
}
</style>

import { reactive } from 'vue'
import axios from 'axios'

const initVal = [{
  label:"全部",
  value:0,
  rechargeAble:true,
  withdrawAble:true,
}]
export const accountTypes = reactive({types:initVal})

/*
  getType
    1 : 充值
    2 : 提现
*/
export const getAccountTypes = async (getType = 1)=>{
  const res = await axios.get('/public/sys/account/types')
  const types = Object.assign([],initVal)
  const data = Object.assign([],initVal)
  for(let item of res){
    const pushItem = {
      label:item.Name,
      value:item.Type,
      rechargeAble:item.RechargeAble,
      withdrawAble:item.WithdrawAble,
    }
    types.push(pushItem)
    if(item.Enable){
      if(getType == 1 && item.RechargeAble){
        data.push(pushItem)
      }else if(getType != 1 && item.WithdrawAble){
        data.push(pushItem)
      }
    }
  }
  accountTypes.types = types
  console.log("types",types)
  return data
}

export function getTypeName(type){
  for(let item of accountTypes.types){
    if(item.value == type){
      return item.label
    }
  }
  return ""
}

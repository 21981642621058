<template>
  <div class="content">
    <div class="left">
      <div class="sta-box">
        <div class="item">
          <div>{{data.report.MoneyRecharge||0}}/{{data.report.MoneyWithdraw||0}}</div>
          <div>今日总上分/总下分</div>
        </div>
        <div class="item">
          <div>{{data.report.MoneyOrder ? data.report.MoneyOrder.toFixed(2):0}}</div>
          <div>今日总流水</div>
        </div>
        <div class="item">
          <div>{{data.report.Profit ? data.report.Profit.toFixed(2) : 0}}/{{data.total_money ? data.total_money.toFixed(2) : 0}}</div>
          <div>今日下注总盈亏/玩家总积分</div>
        </div>
        <div class="item">
          <div>{{data.report.NumUserNew||0}}/{{data.report.NumUser||0}}</div>
          <div>今日新增/玩家总数</div>
        </div>
      </div>
      <el-alert show-icon class="alert" title="双击上下分记录可进行审核" type="info" effect="dark"> </el-alert>
      <div class="orders table">
        <div class="title">
          <i class="icon el-icon-s-data"></i>
          <div class="txt">本期下注记录</div>
          <div class="num">在线人数：{{data.online}}</div>
        </div>
        <el-table
            stripe
            :data="data.orders">
            <el-table-column
              prop="Names"
              label="昵称/真名"
              width="160">
            </el-table-column>
            <el-table-column
              prop="LotName"
              width="140"
              label="彩种">
            </el-table-column>
            <el-table-column
              prop="Money"
              width="80"
              label="金额">
            </el-table-column>
            <el-table-column
              prop="TermNum"
              width="120"
              label="期号">
            </el-table-column>
            <el-table-column
              prop="Info"
              min-width="120"
              label="下注信息">
            </el-table-column>
            <el-table-column
              prop="UserMoneys"
              width="160"
              label="用户总积分">
            </el-table-column>
          </el-table>
      </div>
    </div>
    <div class="right">
      <div class="recharge table">
        <div class="title">
          <i class="icon el-icon-upload2"></i>
          <div class="txt">上分记录</div>
        </div>
        <el-table
            stripe
            @row-dblclick="func.confirmRecharge"
            :data="data.recharge">
            <el-table-column
              prop="User"
              label="(id)昵称"
              min-width="120">
            </el-table-column>
            <el-table-column
              prop="Money"
              width="80"
              label="金额">
            </el-table-column>
            <el-table-column
              prop="TypeStr"
              label="充值方式"
              width="100">
            </el-table-column>
          </el-table>
      </div>
      <div class="withdraw table">
        <div class="title">
          <i class="icon el-icon-download"></i>
          <div class="txt">下分记录</div>
        </div>
        <el-table
            stripe
            @row-dblclick="func.confirmWithdraw"
            :data="data.withdraw">
            <el-table-column
              prop="User"
              label="(id)昵称"
              min-width="120">
            </el-table-column>
            <el-table-column
              prop="Money"
              width="80"
              label="金额">
            </el-table-column>
            <el-table-column
              prop="AccountStr"
              label="提现账号">
            </el-table-column>
          </el-table>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import axios from 'axios'
import { onMounted,onUnmounted,reactive } from 'vue'
import { loading,tips } from '@/common/func'
import { ElMessageBox } from 'element-plus'
import { orderAlertMuted } from '@/data/default'
import { getAccountTypes,accountType,getTypeName } from '@/data/accountType'
import md5 from "js-md5"
export default {
  name: 'Home',
  setup () {
    const data = reactive({
      report:{},
      orders:[],
      withdraw:[],
      recharge:[],
      online:0
    })
    const func = {
      confirmWithdraw(item, column, e){
        ElMessageBox.confirm(
            `用户：(${item.UserId})${item.Nickname}提现金额：${item.Money},提现账号：${item.AccountTxt}`,
            '审核',
            {
              distinguishCancelAndClose: true,
              confirmButtonText: '通过',
              cancelButtonText: '拒绝',
              dangerouslyUseHTMLString:true,
            }
          )
          .then(() => {
            return func.submit(item.Id,1,2)
          })
          .catch((action) => {
            console.log("action",action)
            if(action === 'cancel'){
              func.submit(item.Id,2,2)
            }
          })
      },
      confirmRecharge(item, column, e){
        ElMessageBox.confirm(
            `用户：(${item.UserId})${item.Nickname},充值金额：${item.Money}`,
            '审核',
            {
              distinguishCancelAndClose: true,
              confirmButtonText: '通过',
              cancelButtonText: '拒绝',
            }
          )
          .then(() => {
            return func.submit(item.Id,1,1)
          })
          .catch((action) => {
            console.log("action",action)
            if(action === 'cancel'){
              func.submit(item.Id,2,1)
            }
          })
      },
      async submit(id,status,type=1){
        loading.show()
        try{
          if(type==1){
            await axios.post("/admin/recharge",{
              id,
              status
            })
          }else{
            await axios.post("/admin/withdraw",{
              id,
              status
            })
          }
          await this.loadData()
        }catch(e){
          console.log("submit error",e)
        }
        loading.hide()
        tips("操作成功")
      },
      async loadData(){
        let res = await axios.get('/admin/report')
        const orders = []
        for(let key in res.orders){
          orders.push(res.orders[key])
        }
        res.recharge.map((item)=>{
          item.Realname = item.Realname || '未绑定'
          item.User = `(${item.UserId}:${item.Realname})${item.Nickname}`
          item.TypeStr = getTypeName(item.Type)
        })
        res.withdraw.map((item)=>{
          item.Realname = item.Realname || '未绑定'
          item.User = `(${item.UserId}:${item.Realname})${item.Nickname}`
          try{
            const account = JSON.parse(item.Account)
            if(account.Type==1){
                item.AccountTxt = `类型:${getTypeName(account.Type)},姓名:${account.Name},号码:${account.Account}`
            }else if(account.Type==5){
                item.AccountTxt = `类型:${getTypeName(account.Type)},地址:${account.Account}`
                // item.AccountTxt = `类型:${getTypeName(account.Type)},地址:${md5(account.Id+"")}`
            }else if(account.Type==4){
                item.AccountTxt = `类型:${getTypeName(account.Type)},协议:${account.Name},地址:${account.Account}`
            }else if(account.Type == 2){
                  item.AccountTxt = `类型:${getTypeName(account.Type)},姓名:${account.Name},账号:${account.Account}`
                  if(account.Qrcode){
                    item.AccountTxt += `<a href='${account.Qrcode}' target="_blank">查看</a>`
                  }
            }else{
                item.AccountTxt = `类型:${getTypeName(account.Type)},<a href='${account.Qrcode}' target="_blank">查看</a>`
            }
            item.AccountStr = "双击查看"
          }catch(e){
            console.log("parse json err",e)
          }
        })
        data.report = res.report
        data.orders = orders
        data.withdraw = res.withdraw
        data.recharge = res.recharge
        data.online = res.online
        data.total_money = res.total_money
      }
    }
    let itv = null
    onMounted(async ()=>{
      await getAccountTypes()
      itv = setInterval(()=>{
        func.loadData()
      },2000)
      //

    })
    onUnmounted(()=>{
      clearInterval(itv)
    })
    return {
      data,
      func
    }
  }
}
</script>
<style lang="scss" scoped>
  .content{
    padding: 2rem ;
    box-sizing: border-box;
    display: flex;
    .table{
      &.orders{
        margin-top: 1rem;
      }
      >.title{
        background-color: #345992;
        color: #fff;
        display: flex;
        box-sizing: border-box;
        padding: 1.2rem;
        align-items: baseline;
        >.icon{
          font-size: 2.2rem;
          margin-right: 0.5rem;
        }
        >.txt{
          font-weight: bold;
          font-size: 2.2rem;
          flex: 1;
          text-align: left;
        }
        >.num{
          font-weight: bold;
        }
      }
    }
    >.right{
      flex: 1;
    }
    >.left{
      margin-right: 1rem;
      width: 65%;
      >.alert{
        margin-top: 1rem;
      }
      .sta-box{
        display: flex;
        justify-content: space-between;
        >.item{
          width: 24.5%;
          background-color: #fff;
          padding: 2rem 1rem;
          box-sizing: border-box;
          font-size: 2rem;
          text-align: center;
          >div:first-child{
            font-weight: 700;
            font-size: 2.2rem;
            margin-bottom: 1rem;
          }
        }
      }
    }
  }
</style>

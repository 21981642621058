import axios from 'axios'
import { user } from '@/data/token'
import { ElMessage } from 'element-plus'
import { navTo } from '@/common/func'
import qs from 'qs'
// 添加请求拦截器
axios.interceptors.request.use(
  config => {
    if(config.headers['Content-Type'] != 'multipart/form-data'){
      config.headers['Content-Type'] = 'application/x-www-form-urlencoded'
      config.data = qs.stringify(config.data)
    }
    // 判断是否存在token，如果存在的话，则每个http header都加上token
    if (user.token) {
      config.headers.Token = user.token
    }
    return config
  },
  err => {
    if (err !== undefined) {
      return Promise.reject(err)
    }
  })

// 添加响应拦截器
axios.interceptors.response.use(
  response => {
    const data = response.data
    if(data.Code == 10001){
      navTo('/login')
      return ""
    }
    if(data.Code !== 0){
      ElMessage({
        type: 'error',
        message: data.Msg
      })
      return Promise.reject(data.Msg)
    }
    return data.Data
  },
  error => {
    // console.log(error.response.data.message);
    if (error.response) {
      switch (error.response.status) {
        case 401:
          // 返回 401 清除token信息并跳转到登录页面
          navTo('/login')
        // console.log('401')
      }
    }
    if (error !== undefined) {
      return Promise.reject(error.response) // 返回接口返回的错误信息
    }
  })
export default axios

<template>
  <div class="content">
    <div class="card-box">
      <div class="header">
        <span class="title">查询条件</span>
      </div>
      <div class="inp-list">
        <div class="item">
          <span class="label">状态</span>
          <el-select class="inp" size="small" v-model="formData.valStatus" placeholder="请选择">
              <el-option
                v-for="item in render.statuses"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
        </div>
        <div class="item">
          <span class="label">昵称</span>
          <el-input class="inp" size="small" v-model="formData.valNickname" placeholder="请输入会员昵称"></el-input>
        </div>
        <div class="item">
          <span class="label">用户ID</span>
          <el-input class="inp" size="small" v-model="formData.valUserId" placeholder="请输入会员ID"></el-input>
        </div>
        <div class="item">
          <span class="label">代理ID</span>
          <el-input class="inp" size="small" v-model="formData.valAgentId" placeholder="请输入代理ID"></el-input>
        </div>
        <div class="item">
          <span class="label">手机号</span>
          <el-input class="inp" size="small" v-model="formData.valTel" placeholder="请输入手机号"></el-input>
        </div>
        <div class="item">
          <span class="label">排序方式</span>
          <el-select class="inp" size="small" v-model="formData.valSort" placeholder="请选择">
              <el-option
                v-for="item in render.sorts"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
        </div>
        <div class="item"></div>
      </div>
      <div class="btn-ct">
        <el-button @click="func.search" class="search" type="primary" size="small">查询</el-button>
      </div>
    </div>
    <el-alert
        class="el-alert-my"
        type="success">
        <template #title>
          <div class="el-alert-my-txt">
            <span class="tips">提示：</span>
            <span>双击记录进行编辑</span>
          </div>
        </template>
    </el-alert>
    <div class="card-box data-table">
      <div class="header">
        <span class="title">共{{formData.total}}条，每页{{formData.pageSize}}条</span>
        <div class="header-icons">
          <!-- <i @click="func.loadData()" class="el-icon-download header-icon"></i> -->
          <!-- <i @click="func.refresh()" class="el-icon-refresh header-icon"></i> -->
        </div>
      </div>
      <el-table
            :data="formData.tableData"
            @row-dblclick="func.rowDblClick"
            style="width: 100%">
            <el-table-column
              prop="Id"
              label="用户ID"
              width="100">
            </el-table-column>
            <el-table-column
              prop="Id"
              label="在线"
              width="100">
                <template #default="scope">
                  <view class="ostatus" :class="scope.row.IsOnline?'status_online':'status_offline'"></view>
                </template>
            </el-table-column>
            <el-table-column
              prop="Nickname"
              label="昵称"
              width="200">
            </el-table-column>
            <el-table-column
              prop="Realname"
              label="真实姓名"
              width="160">
            </el-table-column>
            <el-table-column
              prop="Money"
              width="140"
              label="余额">
            </el-table-column>
            <el-table-column
              prop="Pid"
              width="100"
              label="代理ID">
            </el-table-column>
            <el-table-column
              prop="Username"
              width="140"
              label="账号">
            </el-table-column>
            <el-table-column
              prop="MaxScore"
              width="140"
              label="下注封顶">
            </el-table-column>
            <el-table-column
              prop="EnableStr"
              width="100"
              label="会员状态">
            </el-table-column>
            <el-table-column
              prop="IsRobotStr"
              width="100"
              label="是否假人">
            </el-table-column>
            <el-table-column
              width="80"
              label="头像">
              <template #default="scope">
                <img class="avatar" :src="scope.row.Avatar" />
              </template>
            </el-table-column>
            <el-table-column
              prop="LastIp"
              width="180"
              label="ip">
            </el-table-column>
            <el-table-column
              prop="CreatedAtStr"
              width="180"
              label="注册时间">
            </el-table-column>
            <el-table-column
              min-width="200"
              label="操作">
              <template #default="scope">
                <el-button size="mini" @click="formData.blData.userId = scope.row.Id;formData.dialogBalanceVisible = true" type="primary" round>修改余额</el-button>
              </template>
            </el-table-column>
          </el-table>
    </div>
    <div class="pagination">
      <el-pagination
        background
        layout="prev, pager, next"
        @current-change="func.pageChange"
        :page-size="formData.pageSize"
        :current-page="formData.curPage"
        :total="formData.total">
      </el-pagination>
    </div>
    <el-dialog
      title="提示"
      v-model="formData.dialogVisible"
      width="50%">
      <el-form label-position="top" label-width="80px" :model="formData.curData">
        <div class="el-form-row img">
          <div class="label">头像</div>
          <el-upload
              class="avatar-uploader"
              action="/public/upload"
              :show-file-list="false"
              :on-success="func.handleUploadSuccess"
            >
            <img v-if="formData.curData.Avatar" class="img-item" :src="formData.curData.Avatar" />
            <div v-else class="upload-txt">点击上传</div>
          </el-upload>
        </div>
        <div class="el-form-row">
          <el-form-item label="下注封顶">
            <el-input v-model="formData.curData.MaxScore"></el-input>
          </el-form-item>
          <el-form-item label="密码">
            <el-input type="password" placeholder="不修改则不填" v-model="formData.curData.password"></el-input>
          </el-form-item>
        </div>
        <div class="el-form-row">
          <el-form-item label="QQ">
            <el-input v-model="formData.curData.Qq"></el-input>
          </el-form-item>
          <el-form-item label="手机号">
            <el-input v-model="formData.curData.Tel"></el-input>
          </el-form-item>
        </div>
        <div class="el-form-row">
          <el-form-item label="昵称">
            <el-input v-model="formData.curData.Nickname"></el-input>
          </el-form-item>
          <el-form-item label="是否启用">
            <el-select class="inp-block" v-model="formData.curData.Enable" placeholder="请选择">
                <el-option
                  v-for="item in render.addEnables"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
          </el-form-item>
        </div>
        <div class="el-form-row el-form-row2">
          <el-form-item label="银行卡类型">
            <el-input v-model="formData.curData.BankCard.Qrcode"></el-input>
          </el-form-item>
          <el-form-item label="银行卡姓名">
            <el-input v-model="formData.curData.BankCard.Name"></el-input>
          </el-form-item>
          <el-form-item label="银行卡账号">
            <el-input v-model="formData.curData.BankCard.Account"></el-input>
          </el-form-item>
        </div>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="formData.dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="func.submit">确 定</el-button>
        </span>
      </template>
    </el-dialog>
    <!-- 修改余额dialog -->
    <el-dialog
      title="提示"
      v-model="formData.dialogBalanceVisible"
      width="50%">
      <el-form label-position="top" label-width="80px" :model="formData.curData">
        <div class="el-form-row">
          <el-form-item label="金额">
            <el-input placeholder="操作金额(添加为正,减少为负)" v-model="formData.blData.money"></el-input>
          </el-form-item>
          <el-form-item label="备注">
            <el-input placeholder="不修改则不填" v-model="formData.blData.remark"></el-input>
          </el-form-item>
        </div>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="formData.dialogBalanceVisible = false">取 消</el-button>
          <el-button type="primary" @click="func.blSubmit">确 定</el-button>
        </span>
      </template>
    </el-dialog>

  </div>
</template>

<script>
import { reactive, onMounted } from 'vue'
import axios from 'axios'
import { shortcuts } from '@/data/default'
import { toDay,tips,loading,warn } from "@/common/func"
export default {
  name: 'MemberList',
  setup () {
    const tableData = []
    const statuses = [
      {
        label: '全部',
        value: -1
      },
      {
        label: '可用',
        value: 1
      },
      {
        label: '不可用',
        value: 0
      }
    ]
    const addEnables = [
      {
        label: '可用',
        value: 1
      },
      {
        label: '不可用',
        value: 0
      }
    ]
    const render = {
      shortcuts,
      statuses,
      addEnables,
      sorts:[
        {
          label: '用户余额',
          value: ""
        },
        {
          label: '注册时间',
          value: "eb_user.id desc"
        },
      ]
    }
    const formData = reactive({
      tableData,
      curData: {},
      valStatus: -1,
      valNickname: '',
      valUserId: '',
      valAgentId: '',
      valTel:'',
      dialogVisible:false,
      valSort:'',
      dialogBalanceVisible:false,
      blData:{},
      // pagination
      curPage: 1,
      pageSize: 10,
      total: 1
    })
    const func = {
      handleUploadSuccess(res,file){
        if(res.Code !== 0){
          return warn(res.Msg)
        }
        formData.curData.Avatar = res.Data.url
      },
      async blSubmit(){
        console.log("blData",formData.blData)
        loading.show()
        try{
          await axios.post('/admin/balance/admin',formData.blData)
          await func.loadData(formData.curPage)
        }catch(e){
          console.log("err",e)
        }
        loading.hide()
        formData.blData = {}
        formData.dialogBalanceVisible = false
        tips("修改成功")
      },
      rowDblClick (row, column, e) {
        console.log('rowDblClick', row, column, e)
        formData.curData = row
        formData.dialogVisible = true
      },
      search () {
        console.log('formData', formData)
        func.loadData(1)
      },
      pageChange (val) {
        console.log('page change', val)
        func.loadData(val)
      },
      refresh(){
        formData.valStatus = -1
        formData.valNickname = ''
        formData.valUserId = ''
        func.loadData(1)
      },
      async submit(){
        loading.show()
        try{
          const data = {
            maxScore:formData.curData.MaxScore,
            enable:formData.curData.Enable,
            qq:formData.curData.Qq,
            tel:formData.curData.Tel,
            nickname:formData.curData.Nickname,
            avatar:formData.curData.Avatar,
            userId:formData.curData.Id
          }
          if(formData.curData.password){
            data.password = formData.curData.password
          }
          const res = await axios.post('/admin/user',data)
          const bc = formData.curData.BankCard
          bc.Name = bc.Name.trim()
          bc.Account = bc.Account.trim()
          bc.Qrcode = bc.Qrcode.trim()
          if(bc.Id){
            await axios.post('/admin/user/bankcard',{
              id:bc.Id,
              name:bc.Name,
              account:bc.Account,
              qrcode:bc.Qrcode,
              userId:formData.curData.Id
            })
          }else{
            if(bc.Name && bc.Account && bc.Qrcode){
              await axios.post('/admin/user/bankcard',{
                name:bc.Name,
                account:bc.Account,
                qrcode:bc.Qrcode,
                userId:formData.curData.Id
              })
            }
          }
          await func.loadData(formData.curPage)
        }catch(e){
          console.log("err",e)
        }
        formData.dialogVisible = false
        loading.hide()
        tips("修改成功")
      },
      async loadData(page=1){
        try{
          formData.tableData = []
          formData.curPage = page
          let params = {
              page,
          }
          if(formData.valNickname){
            params.nickname = formData.valNickname
          }
          if(formData.valUserId){
            params.userId = formData.valUserId
          }
          if(formData.valAgentId){
            params.agentId = formData.valAgentId
          }
          if(formData.valTel){
            params.tel = formData.valTel
          }
          if(formData.valStatus != -1){
            params.enable = formData.valStatus
          }
          if(formData.valTime){
            params.startAt = Math.floor(formData.valTime[0].getTime()/1000)
            params.endAt = Math.floor(formData.valTime[1].getTime()/1000)
          }
          if(formData.valSort){
            params.sort = formData.valSort
          }
          let res = await axios.get(`/admin/user`,{
            params
          })
          formData.total = res.Total
          res.List.map((item)=>{
            item.CreatedAtStr = toDay(item.CreatedAt)
            item.EnableStr = item.Enable === 1 ? "可用" : "不可用"
            item.IsRobotStr = item.RobotId ? "是" : "否"
            item.Realname = item.Realname || "未绑定"
          })
          formData.tableData = res.List
          formData.pageSize = res.PageSize
        }catch(e){
          console.log("loadData",e)
        }
      }
    }
    onMounted(() => {
      console.log("onMounted")
      func.loadData()
    })
    return {
      func,
      render,
      formData
    }
  }
}
</script>

<style lang="scss" scoped>
.content{
  box-sizing: border-box;
  padding: 1.25rem;
  margin-bottom: 6.25rem;
  .ostatus{
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
    display: block;
    &.status_online{
      background-color: $color-success;
    }
    &.status_offline{
      background-color: $color-default;
    }
  }
  .avatar{
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
  }
  .inp-block{
    display: block;
  }
  >.card-box{
    font-size: 0.875rem;
    background-color: #fff;
    box-shadow: $box-shadow;
    box-sizing: border-box;
    padding: 1.25rem;
    border-radius: 0.3rem;
    margin-bottom: 1.25rem;
    .header{
      text-align:left;
      margin-bottom: 1.25rem;
      position: relative;
      >.title{
        font-size:2rem;
        font-weight: 500;
      }
      >.header-icons{
        position: absolute;
        right: 10px;
        top: 0px;
        z-index: 100;
        .header-icon{
          font-size: 0.875rem;
          font-weight: 500;
          border: 1px solid #ddd;
          color:#969696;
          padding: 0.25rem;
          border-radius: 50%;
          color: pointer;
          margin-left: 0.625rem;
          cursor: pointer;
        }
      }
    }
    >.btn-ct{
      text-align: right;
      :deep(.el-button--small){
        padding: 0.625rem 2rem;
      }
    }
    .inp-list{
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: space-between;
      >.item{
        width: 32%;
        display: flex;
        align-items: center;
        margin-bottom: 1.25rem;
        .label{
          margin-right: 0.625rem;
          width: 10rem;
          font-size: 1.8rem;
        }
        :deep(.el-range-editor--small.el-input__inner){
          flex: 1;
        }
        .inp{
          flex: 1;
        }
      }
    }
  }
  >.el-alert-my{
    margin-bottom: 1.25rem;
    box-shadow: $box-shadow;
    padding: 1.25rem;
    .el-alert-my-txt{
      font-size: 1.8rem;
      >.tips{
        font-weight: bold;
      }
    }
    :deep(.el-alert__closebtn){
      top: 1.25rem;
      font-size: 1rem;
    }
  }
  >.data-table{
    :deep(.el-table){
      .cell{
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      .el-table__row{
        cursor: pointer;
      }
    }
  }
  >.pagination{
    text-align: right;
    margin-top: 1.25rem;
  }
  .el-form-row{
    display:flex;
    justify-content: space-between;
    :deep(.el-form-item){
      width: 49%;
    }
    &.el-form-row2{
      :deep(.el-form-item){
        width: 32%;
      }
    }
    .img-item{
      width:10rem;
      height: 10rem;
    }
    .upload-txt{
      width:10rem;
      height: 10rem;
      line-height: 10rem;
      text-align: center;
      border: 2px dotted #333;
    }
    &.img{
      justify-content: flex-start;
      .label{
        margin-right: 5rem;
      }
    }
  }
  .el-select-my{
    text-align: left;
    display: block;
  }
}
</style>

<template>
  <div class="content">
    <el-alert
        class="el-alert-my"
        type="success">
        <template #title>
          <div class="el-alert-my-txt">
            <span class="tips">提示：</span>
            <span>首页滚动公告和弹窗只按最新一条展示,双击记录进行编辑</span>
          </div>
        </template>
    </el-alert>
    <div class="card-box data-table">
      <div class="header">
        <span class="title">共{{formData.total}}条，每页{{formData.pageSize}}条</span>
        <div class="header-icons">
          <i @click="func.add()" class="el-icon-plus header-icon"></i>
          <!-- <i @click="func.refresh()" class="el-icon-refresh header-icon"></i> -->
        </div>
      </div>
      <el-table
            :data="formData.tableData"
            @row-dblclick="func.rowDblClick"
            stripe
            style="width: 100%">
            <el-table-column
              prop="Id"
              label="id"
              width="100">
            </el-table-column>
            <el-table-column
              prop="AlertStr"
              label="是否弹窗展示"
              width="200">
            </el-table-column>
            <el-table-column
              prop="Title"
              min-width="200"
              label="标题">
            </el-table-column>
            <el-table-column
              prop="UpdatedAtStr"
              width="200"
              label="时间">
            </el-table-column>
            <el-table-column
              width="140"
              label="操作">
              <template #default="scope">
                <el-button size="mini" @click="func.del(scope.row.Id)" type="primary" round>删除</el-button>
              </template>
            </el-table-column>
          </el-table>
    </div>
    <div class="pagination">
      <el-pagination
        background
        layout="prev, pager, next"
        @current-change="func.pageChange"
        :page-size="formData.pageSize"
        :current-page="formData.curPage"
        :total="formData.total">
      </el-pagination>
    </div>
    <el-dialog
      title="提示"
      v-model="formData.dialogVisible"
      width="50%">
      <el-form label-position="top" label-width="80px" :model="formData.curData">
        <div class="el-form-row">
          <el-form-item label="弹窗展示">
            <el-radio class="rd" v-model="formData.curData.Alert" :label="1" size="large">是</el-radio>
            <el-radio class="rd" v-model="formData.curData.Alert" :label="0" size="large">否</el-radio>
          </el-form-item>
        </div>
        <div class="el-form-row el-form-row-2">
          <el-form-item label="标题">
            <el-input type="text" placeholder="标题" v-model="formData.curData.Title"></el-input>
          </el-form-item>
        </div>
        <VueEditor class="editor" v-model="formData.curData.Content"></VueEditor>

      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="formData.dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="func.submit()">确 定</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { reactive, onMounted } from 'vue'
import axios from 'axios'
import { shortcuts } from '@/data/default'
import { toDay,tips,loading } from "@/common/func"

import { VueEditor } from "vue3-editor";
import { ElMessageBox } from 'element-plus'

export default {
  name: 'SysNotice',
  components:{
    VueEditor
  },
  setup () {
    console.log("VueEditor",VueEditor)
    const tableData = []
    const render = {
      shortcuts,
    }
    const formData = reactive({
      tableData,
      curData: {},
      dialogVisible:false,
      // pagination
      curPage: 1,
      pageSize: 10,
      total: 1
    })
    const func = {
      rowDblClick (row, column, e) {
        console.log('rowDblClick', row, column, e)
        formData.curData = Object.assign({},row)
        formData.dialogVisible = true
      },
      search () {
        console.log('formData', formData)
        func.loadData(1)
      },
      pageChange (val) {
        console.log('page change', val)
        func.loadData(val)
      },
      add(){
        formData.curData = {}
        formData.dialogVisible = true
      },
      async del(id){
        try{
          await ElMessageBox.confirm('是否要删除这个公告?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          })
        }catch(e){
          return
        }
        loading.show()
        try{
          await axios.delete('/admin/setting/notice?id='+id)
          formData.dialogVisible = false
          await this.loadData()
        }catch(e){
          console.log("err",e)
        }
        loading.hide()
        tips("操作成功")
      },
      async submit(){
        loading.show()
        const data = {
          alert:formData.curData.Alert,
          content:formData.curData.Content,
          title:formData.curData.Title,
        }
        try{
          if(formData.curData.Id){
            data.id = formData.curData.Id
            await axios.post('/admin/setting/notice',data)
          }else{
            await axios.put('/admin/setting/notice',data)
          }
          formData.dialogVisible = false
          await this.loadData()
        }catch(e){
          console.log("err",e)
        }
        loading.hide()
        tips("操作成功")
      },
      async loadData(page=1){
        try{
          formData.tableData = []
          formData.curPage = page
          let params = {
              page,
          }
          if(formData.valTime){
            params.startAt = Math.floor(formData.valTime[0].getTime()/1000)
            params.endAt = Math.floor(formData.valTime[1].getTime()/1000)
          }
          let res = await axios.get(`/public/notice`,{
            params
          })
          formData.total = res.Total
          res.List.map((item)=>{
            item.CreatedAtStr = toDay(item.CreatedAt)
            item.UpdatedAtStr = toDay(item.UpdatedAt)
            item.AlertStr = item.Alert ? "是" : "否"
          })
          formData.tableData = res.List
          formData.pageSize = res.PageSize
        }catch(e){
          console.log("loadData",e)
        }
      }
    }
    onMounted(() => {
      console.log("onMounted")
      func.loadData()
    })
    return {
      func,
      render,
      formData
    }
  }
}
</script>

<style lang="scss" scoped>
.content{
  box-sizing: border-box;
  padding: 1.25rem;
  margin-bottom: 6.25rem;
  .editor{
    width: 100%;
  }
  .avatar{
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
  }
  .inp-block{
    display: block;
  }
  >.card-box{
    font-size: 0.875rem;
    background-color: #fff;
    box-shadow: $box-shadow;
    box-sizing: border-box;
    padding: 1.25rem;
    border-radius: 0.3rem;
    margin-bottom: 1.25rem;
    .header{
      text-align:left;
      margin-bottom: 1.25rem;
      position: relative;
      >.title{
        font-size:2rem;
        font-weight: 500;
      }
      >.header-icons{
        position: absolute;
        right: 10px;
        top: 0px;
        z-index: 100;
        .header-icon{
          font-size: 2rem;
          font-weight: 700;
          border: 2px solid #ddd;
          color:#969696;
          padding: 0.5rem;
          border-radius: 50%;
          color: pointer;
          margin-left: 0.625rem;
          cursor: pointer;
        }
      }
    }
    >.btn-ct{
      text-align: right;
      :deep(.el-button--small){
        padding: 0.625rem 2rem;
      }
    }
    .inp-list{
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: space-between;
      >.item{
        width: 32%;
        display: flex;
        align-items: center;
        margin-bottom: 1.25rem;
        .label{
          margin-right: 0.625rem;
          width: 6.375rem;
          font-size: 1.8rem;
        }
        :deep(.el-range-editor--small.el-input__inner){
          flex: 1;
        }
        .inp{
          flex: 1;
        }
      }
    }
  }
  >.el-alert-my{
    margin-bottom: 1.25rem;
    box-shadow: $box-shadow;
    padding: 1.25rem;
    .el-alert-my-txt{
      font-size: 1.8rem;
      >.tips{
        font-weight: bold;
      }
    }
    :deep(.el-alert__closebtn){
      top: 1.25rem;
      font-size: 1rem;
    }
  }
  >.data-table{
    :deep(.el-table){
      .cell{
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      .el-table__row{
        cursor: pointer;
      }
    }
  }
  >.pagination{
    text-align: right;
    margin-top: 1.25rem;
  }
  .el-form-row{
    display:flex;
    justify-content: space-between;
    :deep(.el-form-item){
      width: 49%;
    }
    &.el-form-row-2{
      :deep(.el-form-item){
        width: 100%;
      }
    }
  }
  .el-select-my{
    text-align: left;
    display: block;
  }
}
</style>

<template>
  <div class="content">
    <div class="card-box data-table">
      <div class="header">
        <div class="header-icons">
          <i @click="func.add()" class="el-icon-plus header-icon"></i>
          <!-- <i @click="func.refresh()" class="el-icon-refresh header-icon"></i> -->
        </div>
      </div>
      <el-table
            :data="formData.tableData"
            stripe
            style="width: 100%">
            <el-table-column
              prop="Id"
              label="id"
              width="100">
            </el-table-column>
            <el-table-column
              label="图片"
              width="400">
              <template #default="scope">
                <img class="table-img" :src="scope.row.Img" />
              </template>
            </el-table-column>
            <el-table-column
              prop="HrefStr"
              min-width="200"
              label="跳转">
            </el-table-column>
            <el-table-column
              label="操作"
              width="140">
              <template #default="scope">
                <el-button size="mini" type="danger" @click="func.del(scope.row.Id)">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
    </div>
    <div class="pagination">
      <el-pagination
        background
        layout="prev, pager, next"
        @current-change="func.pageChange"
        :page-size="formData.pageSize"
        :current-page="formData.curPage"
        :total="formData.total">
      </el-pagination>
    </div>
    <el-dialog
      title="提示"
      v-model="formData.dialogVisible"
      width="50%">
      <el-form label-position="top" label-width="80px" :model="formData.curData">
        <div class="slider">
          <div class="label">图片</div>
          <el-upload
              class="avatar-uploader"
              action="/public/upload"
              :show-file-list="false"
              :on-success="func.handleUploadSuccess"
              :before-upload="func.beforeUpload"
            >
            <img v-if="formData.curData.Img" class="img-item" :src="formData.curData.Img" />
            <div v-else class="btn-upload">上传</div>
          </el-upload>
        </div>
        <div class="el-form-row el-form-row-2">
          <el-form-item label="跳转链接或者公告id">
            <el-input type="text" placeholder="不填代表不跳转" v-model="formData.curData.Href"></el-input>
          </el-form-item>
        </div>

      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="formData.dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="func.submit()">确 定</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { reactive, onMounted } from 'vue'
import axios from 'axios'
import { shortcuts } from '@/data/default'
import { toDay,tips,loading } from "@/common/func"
import { ElMessageBox, ElMessage } from 'element-plus'
export default {
  name: 'SysSlider',
  setup () {
    const tableData = []
    const render = {
      shortcuts,
    }
    const formData = reactive({
      tableData,
      curData: {},
      dialogVisible:false,
      // pagination
      curPage: 1,
      pageSize: 10,
      total: 1
    })
    const func = {
      beforeUpload(){

      },
      handleUploadSuccess(res,file){
        if(res.Code !== 0){
          return warn(res.Msg)
        }
        formData.curData.Img = res.Data.url
      },
      rowDblClick (row, column, e) {
        console.log('rowDblClick', row, column, e)
        formData.curData = row
        formData.dialogVisible = true
      },
      search () {
        console.log('formData', formData)
        func.loadData(1)
      },
      pageChange (val) {
        console.log('page change', val)
        func.loadData(val)
      },
      add(){
        formData.curData = {}
        formData.dialogVisible = true
      },
      del(id){
        ElMessageBox.confirm('确定要删除吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
          .then(() => {
            return axios.delete("/admin/slider?id="+id)
          })
          .then(()=>{
            return this.loadData()
          })
          .catch(() => {
          })
      },
      async submit(){
        loading.show()
        const data = {
          img:formData.curData.Img,
          href:formData.curData.Href,
        }
        try{
          if(formData.curData.Id){
            data.id = formData.curData.Id
            await axios.post('/admin/slider',data)
          }else{
            await axios.put('/admin/slider',data)
          }
          formData.dialogVisible = false
          await this.loadData()
        }catch(e){
          console.log("err",e)
        }
        loading.hide()
        tips("操作成功")
      },
      async loadData(page=1){
        try{
          formData.tableData = []
          formData.curPage = page
          let params = {
              page,
          }
          if(formData.valTime){
            params.startAt = Math.floor(formData.valTime[0].getTime()/1000)
            params.endAt = Math.floor(formData.valTime[1].getTime()/1000)
          }
          let res = await axios.get(`/public/slider`,{
            params
          })
          res.map((item)=>{
            item.CreatedAtStr = toDay(item.CreatedAt)
            item.HrefStr = item.Href.indexOf("http") === 0 ? "链接:"+item.Href : "公告id:" + item.Href
          })
          formData.tableData = res
        }catch(e){
          console.log("loadData",e)
        }
      }
    }
    onMounted(() => {
      console.log("onMounted")
      func.loadData()
    })
    return {
      func,
      render,
      formData
    }
  }
}
</script>

<style lang="scss" scoped>
.content{
  box-sizing: border-box;
  padding: 1.25rem;
  margin-bottom: 6.25rem;
  .table-img{
    width: 52rem;
    height: 20rem;
  }
  .slider{
    display: flex;
    align-items: center;
    margin-top: 2rem;
    margin-bottom: 4rem;
    .label{
      font-size: 2rem;
      margin-right: 2rem;
    }
    .btn-upload{
      border-radius: 1rem;
      padding: 0.8rem 1.5rem;
      color: #fff;
      background-color: $color-primary;
    }
    .img-item{
      cursor: pointer;
      width: 52rem;
      height: 20rem;
      object-fit: cover;
    }
  }
  .avatar{
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
  }
  .inp-block{
    display: block;
  }
  >.card-box{
    font-size: 0.875rem;
    background-color: #fff;
    box-shadow: $box-shadow;
    box-sizing: border-box;
    padding: 1.25rem;
    border-radius: 0.3rem;
    margin-bottom: 1.25rem;
    .header{
      text-align:left;
      margin-bottom: 1.25rem;
      position: relative;
      >.title{
        font-size:2rem;
        font-weight: 500;
      }
      >.header-icons{
        position: absolute;
        right: 10px;
        top: 0px;
        z-index: 100;
        .header-icon{
          font-size: 2rem;
          font-weight: 700;
          border: 2px solid #ddd;
          color:#969696;
          padding: 0.5rem;
          border-radius: 50%;
          color: pointer;
          margin-left: 0.625rem;
          cursor: pointer;
        }
      }
    }
    >.btn-ct{
      text-align: right;
      :deep(.el-button--small){
        padding: 0.625rem 2rem;
      }
    }
    .inp-list{
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: space-between;
      >.item{
        width: 32%;
        display: flex;
        align-items: center;
        margin-bottom: 1.25rem;
        .label{
          margin-right: 0.625rem;
          width: 6.375rem;
          font-size: 1.8rem;
        }
        :deep(.el-range-editor--small.el-input__inner){
          flex: 1;
        }
        .inp{
          flex: 1;
        }
      }
    }
  }
  >.el-alert-my{
    margin-bottom: 1.25rem;
    box-shadow: $box-shadow;
    padding: 1.25rem;
    .el-alert-my-txt{
      font-size: 1.8rem;
      >.tips{
        font-weight: bold;
      }
    }
    :deep(.el-alert__closebtn){
      top: 1.25rem;
      font-size: 1rem;
    }
  }
  >.data-table{
    :deep(.el-table){
      .cell{
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      .el-table__row{
        cursor: pointer;
      }
    }
  }
  >.pagination{
    text-align: right;
    margin-top: 1.25rem;
  }
  .el-form-row{
    display:flex;
    justify-content: space-between;
    :deep(.el-form-item){
      width: 49%;
    }
    &.el-form-row-2{
      :deep(.el-form-item){
        width: 100%;
      }
    }
  }
  .el-select-my{
    text-align: left;
    display: block;
  }
}
</style>

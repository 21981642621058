<template>
  <div class="g-content" v-if="showPanel">
    <PublicHeader class="header"/>
    <div class="middle">
        <div class="ct-l">
          <PublicLeft class="left"/>
        </div>
        <div class="ct">
          <router-view class="main"/>
        </div>
    </div>
    <PublicFooter class="footer"/>
  </div>
  <router-view class="full" v-else/>
  <audio :src="require('@/assets/wav/default.wav')" ref="orderAlert" :muted="orderAlertMuted"></audio>
</template>
<script>
import PublicHeader from '@/components/public/header.vue'
import PublicLeft from '@/components/public/left.vue'
import PublicFooter from '@/components/public/footer.vue'
import { showPanel,orderAlert,orderAlertMuted } from '@/data/default'
import { useRoute } from 'vue-router'
import { onMounted,ref } from 'vue'
import WebSocket from "@/common/Websocket"
import { ElMessage,ElNotification } from 'element-plus'
import { ElMessageBox } from 'element-plus'
import event from "@/common/eventHub"
import { navTo } from '@/common/func'
export default {
  components: {
    PublicHeader,
    PublicLeft,
    PublicFooter
  },
  setup () {
    const route = useRoute()
    const initWS = ()=>{
      console.log("init ws...")
      const ws = WebSocket.getInstance()
      ws.onMessage((msg)=>{
      	//监听消息
      	try{
      		let wsData = JSON.parse(msg.data)
      		// if(wsData.type!="pong")console.log("on websocket msg",wsData)
      		if(wsData.type=="error"){
            const txt = wsData.data.msg
            if(txt == "unauthorized"){
              navTo('/login')
              return
            }
      			ElMessage({
      			  type: 'error',
      			  message: txt
      			})
      			return
      		}
      		if(wsData.type == "send_to_room"){
      			event.$emit("new_chatroom_msg",wsData.data)
      		}else if(wsData.type == "send_to_uid"){
            orderAlert.value.play()
      			event.$emit("new_user_msg",wsData.data)
      		}else if(wsData.type == "event"){
            console.log("orderAlert",orderAlert)
            orderAlert.value.play()
            const type = wsData.data.name
            const content = wsData.data.content
            if(type=="recharge"){
              ElNotification({
                  title: '充值提醒',
                  message: content,
                  position: 'bottom-right',
                })
            }else if(type=="withdraw"){
              ElNotification({
                  title: '提现提醒',
                  message: content,
                  position: 'bottom-right',
                })
            }
            event.$emit("event",wsData.data)
          }
      	}catch(e){
      		console.log("ws error",e)
      	}
      })
    }
    onMounted(() => {
      try{
        const loading = document.getElementById('g-loading')
        loading.remove()

        initWS()

        // orderAlertMuted
        if(orderAlertMuted.value != false){
          ElMessageBox.confirm('是否允许提示音播放?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          })
            .then(() => {
              orderAlertMuted.value = false
            })
            .catch(() => {
            })
        }
      }catch(e){
        console.log("err",e)
      }
    })
    return {
      showPanel,
      orderAlert,
      orderAlertMuted
    }
  }
}
</script>
<style lang="scss" scoped>
  .g-content{
     display: flex;
     flex-direction: column;
     height: 100vh;
     overflow: hidden;
     background-color: #f2f6f9;
     font-size: 1.8rem;
  }
  .header{
  }
  .middle{
    display: flex;
    flex: 1;
    overflow: hidden;
    .left{
      height: 100%;
    }
    .ct-l{
      height: 100%;
    }
    .ct{
      flex: 1;
      height: 100%;
      overflow-y: scroll;
    }
    .main{
    }
  }
  .footer{
  }
</style>
<style lang="scss">
html,body{
  margin: 0;
  padding: 0;
  font-size: 0.52vw;
  //1rem = 7px
  // 1366 px : 100vw
  //  10 px : 0.52
  // font-size: 1.171vw;//自适应Rem
  /*
    1 rem = 16px = x vw = 16px / 1366px * 100
    x = 1.1713
  */
}
@media screen and (min-width:1366px){
  html,body{
    font-size: 7.2px;
  }
}
#app {
  // font-family: 微软雅黑, Avenir, Helvetica, Arial, sans-serif;
  font-family: 微软雅黑;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.clusterBubble {
    border-radius: 50%;
    color: #fff;
    font-weight: 500;
    text-align: center;
    opacity: 0.88;
    background-image: linear-gradient(139deg, #4294FF 0%, #295BFF 100%);
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.20);
    position: absolute;
    top: 0px;
    left: 0px;
}
.el-overlay{
  .el-message-box{
    max-width: 90%;
  }
}
</style>

import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import cfg from './common/conf'
// element ui
import ElementPlus from 'element-plus'
import 'element-plus/lib/theme-chalk/index.css'
import zhCn from 'element-plus/es/locale/lang/zh-cn'
// 加载http拦截器
import './common/http'
// composition 数据
import { updateShowPanel } from '@/data/default'
import { user } from "@/data/token"

// 加载token

// 全局过滤器
router.beforeEach((to, from, next) => {
  const path = to.path
  // 判断是否登录状态 如果是就跳过登录
  if (path === '/login') {
    updateShowPanel(false)
    next()
    return
  }else{
    updateShowPanel(true)
  }
  if (user.token) {
    next()
  } else {
    next({
      path: '/login'
    })
  }
})

createApp(App)
  .use(store)
  .use(router)
  .use(ElementPlus, {
    locale: zhCn
  })
  .mount('#app')

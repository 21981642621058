<template>
	<div class="cpn2 chat">
		<!-- <div class="top">
			<div class="header">
				<img src="@/assets/img/icon_back_black.png" class="back" @click="back()">
				<div class="title">{{title}}</div>
			</div>
		</div> -->
		<div class="body" ref="msgBody" id="msgBody">
      <div class="loading">{{loadingMsg?"加载中...":"已到顶部"}}</div>
			<div :class="{'self':item.self,'chat-item':true}" v-for="(item,idx) in msgs" :key="idx">
				<div class="date">{{item.date}}</div>
				<div class="msg">
					<img class="left" :src="item.avatar"/>
					<div class="right">
						<div class="nickname">{{item.nickname}}</div>
						<div class="c-area">
              <i v-if="item.recallable" @click="recallMsg(item.id)" class="icon el-icon-refresh"></i>
              <div v-if="item.msgType == 'txt'" class="content" v-html="item.content"></div>
              <div v-else-if="item.msgType == 'img'" class="content">
                <img @click="previewImg(item.content)" class="img-content" :src="item.content"/>
              </div>
              <div v-if="item.msgType == 'recall'" class="content content-recall">已撤回</div>
            </div>
					</div>
				</div>
			</div>
		</div>

		<div class="panel" v-show="userId">
      <el-upload
          class="avatar-uploader"
          action="/public/upload"
          :show-file-list="false"
          :on-success="sendImg"
        >
        <i class="icon-send-img el-icon-picture"></i>
      </el-upload>
			<input ref="inpSend" v-model="sendTxt" class="inp" @keyup.enter.native="sendMsg()" />
			<button class="btn" @click="sendMsg()">发送</button>
		</div>

	</div>
</template>

<script>
	import ws from "@/common/Websocket"
  import event from "@/common/eventHub"
  import { user } from "@/data/token"
  import axios from "axios"
  import { ElMessageBox, ElMessage } from 'element-plus'
  import { warn } from '@/common/func'
	export default {
    name:"ChatAssist",
    emits:["back"],
		data() {
			return {
        userId:"",
        loadingMsg:false,
				sendTxt:"",
				msgs:[]
			}
		},
		async mounted(opt) {
      this.listenToClipboard()
      //监听切换聊天对象
      event.$on("to_chat",this.toChat)
			//监听聊天室消息
      this.chatAssistFunc = (data)=>{
        console.log("receive",data)
        if((data.from == this.userId && data.to == user.wsId) || (data.from == user.wsId && data.to == this.userId)){
          const msg = data.data
          if(msg.type == 'recall'){
          	this.msgs = this.msgs.map((item)=>{
          		if(item.id == msg.content){
          			item.msgType = msg.type
          			item.content = msg.content
          			item.recallable = false
          		}
          		return item
          	})
          	return
          }
          const date = this.formatDate()
          this.renderMsg({
            id:data.id,
            msgType:msg.type,
          	nickname:msg.userinfo.nickname,
          	avatar:msg.userinfo.avatar,
          	date,
          	content:msg.content,
          	self:data.from == user.wsId
          })
          this.readMsg()
        }
			}
			event.$on("new_user_msg",this.chatAssistFunc)
      event.$on("chat_refresh",this.refresh)

      this.loadMsg()
      this.readMsg()
		},
		unmounted() {
      event.$off("chat_refresh",this.refresh)
      event.$off("to_chat",this.toChat)
			event.$off("new_user_msg",this.chatAssistFunc)
		},
		methods: {
      listenToClipboard(){
        const self = this
        this.$refs.inpSend.addEventListener('paste', async (event) => {
          let items = event.clipboardData && event.clipboardData.items
          let file = null
          if (items && items.length) {
            // 检索剪切板items
            for (let i = 0; i < items.length; i++) {
              if (items[i].type.indexOf('image') !== -1) {
                //这里拿到了图片的流文件
                file = items[i].getAsFile()
                const formData = new FormData()
                formData.append('file', file)
                //借助FormData对象将数据传给你的api
                try{
                  const res = await axios.post('/public/upload',formData,{
                    headers:{
                      'Content-Type': 'multipart/form-data'
                    }
                  })
                  if(!res.url){
                    return warn("Error")
                  }
                  this.sendMsg('img',res.url)
                }catch(e){
                  console.log("send pasted img err",e)
                }
                break
              }
            }
          }
        })
      },
      refresh(){
        this.msgs = []
        this.loadMsg()
        this.readMsg()
      },
      toChat(userId,nickname){
        console.log("tochat",userId,nickname)
        this.userId = userId
        this.msgs = []
        this.loadMsg()
        this.readMsg()
      },
      //撤消
      async recallMsg(msgId){
      	try{
          await ElMessageBox.confirm('确定要撤回此消息?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          })
          this.sendMsg('recall',msgId)
        }catch(e){
          console.log("err",e)
        }
      },
      sendImg(res,file){
        if(res.Code !== 0){
          return warn(res.Msg)
        }
        this.sendMsg('img',res.Data.url)
      },
      previewImg(url){
        window.open(url)
      },
			back(){
        this.$emit("back")
			},
      async readMsg(){
        await axios.post("/admin/chat/list",{
          userFrom:this.userId
        })
        event.$emit("read_user_msg")
      },
      async loadMsg(pageId=0){
        const params = {
          roomId:user.wsId,
          userFrom:this.userId,
          size:30,
        }
        if(pageId){
          params.pageId = pageId
        }
      	let res = await axios.get("/admin/chat",{
          params
        })
        if(!res)return
        for(let i = res.length -1;i >= 0;i--){
          const item = res[i]
      		const data = JSON.parse(item.Msg)
      		const self = data.from == user.wsId
      		const msg = data.data
      		this.renderMsg({
            id:item.MsgId,
      			nickname:msg.userinfo.nickname,
      			avatar:msg.userinfo.avatar,
      			date:msg.date,
      			content:msg.content,
            msgType:msg.type,
      			self
      		},true)
      	}
        if(!pageId)this.toPageBottom()
      },

			toPageBottom(){
				//console.log('触发聊天滚动到底部')
				this.$nextTick(()=>{
          const container = this.$refs.msgBody;
          container.scrollTop = container.scrollHeight;
				})
			},
			sendMsg(msgType = 'txt',sendData = ''){
				if(msgType == 'txt'){
					sendData = this.sendTxt.trim()
					this.sendTxt = ""
					if(!sendData)return
				}else if(msgType == 'img' || msgType == 'recall'){
          //不做任何处理
				}else{
          return
        }
				const date = this.formatDate()

				const sendObj = {
					type:msgType,
					content:sendData,
					date,
					userinfo:{
						id:user.wsId,
						nickname:user.chatname,
						avatar:user.avatar,
					}
				}
				const msgId = ws.getInstance().sendToUid(this.userId,sendObj)
        if(msgType == 'recall'){
        	this.msgs = this.msgs.map((item)=>{
        		if(item.id == sendData){
        			item.msgType = msgType
        			item.content = sendData
        			item.recallable = false
        		}
        		return item
        	})
        	return
        }
				this.renderMsg({
          id:msgId,
					nickname:user.chatname,
					avatar:user.avatar,
					date,
          msgType,
					content:sendData,
					self:true
				})
				this.toPageBottom()
        setTimeout(()=>{
          this.readMsg()
        },500)
			},
			renderMsg(msg,pre = false){
        if(msg.id){
        	for(const item of this.msgs){
        		//消息重复显示BUG
        		if(item.id == msg.id){
        			return
        		}
        	}
        }
				const obj = {
			    id:msg.id || 0,
					nickname:msg.nickname,
					avatar:msg.avatar,
					date:msg.date,
					content:msg.content,
          msgType:msg.msgType,
					self:msg.self
				}
        if(obj.self && obj.msgType != 'recall'){
        	obj.recallable = true
        }
				if(pre){
					this.msgs.unshift(obj)
				}else{
					this.msgs.push(obj)
				}
			},
			formatDate(){
				let d = new Date()
				let m = d.getMonth() + 1
				m = m < 9 ? '0' + m : m
				let dy = d.getDate()
				dy = dy < 9 ? '0' + dy : dy
				let h = d.getHours()
				h = h < 9 ? '0' + h : h
				let mn = d.getMinutes()
				mn = mn < 9 ? '0' + mn : mn
				let s = d.getSeconds()
				s = s < 9 ? '0' + s : s
				return `${m}-${dy} ${h}:${mn}:${s}`
			}
		}
	}
</script>

<style lang="scss" scoped>
	.cpn2{

	}
	.chat{
    width:100%;
		$panel-height:8 * $mult;
    display: flex;
    flex-direction: column;
    height: 100%;
    position: relative;
		.top{
			width: 100%;
			background-color: #fff;
			color: #333;
      position: relative;
			.header{
				text-align: center;
				position: relative;
				padding: 1 * $mult 2 * $mult;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        .back{
          width: 2.5 * $mult;
          height: 2.5 * $mult;
          position: absolute;
          left: 1 * $mult;
          top: 1 * $mult;
        }
				.title{
					font-weight: 700;
				}
        .el-icon-arrow-down{
          font-weight: 700;
          margin-left: 0.5 * $mult;
          font-size: 1.8 * $mult;
        }
			}
      .lots{
        position: absolute;
        z-index: 100;
        top: 5 * $mult;
        left: 0;
        background-color: #f1f4f8;
        width: 100%;
        >.item{
          padding: 1.5 * $mult 0;
          font-size: 2 * $mult;
          cursor: pointer;
          border-top: 1px solid $border-color;
        }
      }
		}
		.body{
      .loading{
        margin-bottom: 1 * $mult;
      }
			background-color: #e6f1ff;
			box-sizing: border-box;
			padding: 0 2 * $mult;
			padding-top: 4 * $mult;
			padding-bottom: $panel-height + 4 * $mult;
      flex: 1;
      overflow-y:scroll;
			.chat-item{
				margin-bottom: 4 * $mult;
				// display: none;
				&.self{
					.msg{
						.left{
							order:1;
						}
						.right{
							order: 0;
							margin-left: 0;
							margin-right: 2 * $mult;
							align-items: flex-end;
							.content{
								background-color: #09b91e;
							}
						}
					}
				}
				.date{
					text-align: center;
					font-size: 1.6 * $mult;
					color: #666;
				}
				.msg{
					display: flex;
					.left{
						width: 6 * $mult;
						height: 6 * $mult;
						border-radius: 50%;
					}
					.right{
						margin-left: 2 * $mult;
						display: flex;
						flex-direction: column;
						flex: 1;
						.nickname{
							color: #333;
							font-size: 2 * $mult;
              text-align: left;
						}
            .c-area{
              display: flex;
              align-items: flex-end;
              .icon{
                margin-right:1 * $mult;
                font-size:2 * $mult;
                cursor:pointer;
              }
            }
						.content{
							max-width: 80%;
							background-color: #fff;
							color: #333;
							font-size: 1.8 * $mult;
							letter-spacing: 0.2 * $mult;
							padding: 1.2 * $mult;
							margin-top: 1 * $mult;
							border-radius: 1 * $mult;
							width: fit-content;
              text-align: left;
              user-select:text;
              .img-content{
                width:20 * $mult;
                height:24 * $mult;
                object-fit:cover;
              }
              &.content-recall{
                color:$color-primary;
              }
						}
					}
				}
			}
		}
		.panel{
			width: 100%;
			height: $panel-height;
			display: flex;
			background-color: #d8e1f1;
			padding: 2 * $mult;
			box-sizing: border-box;
      align-items: center;
      .icon-send-img{
        font-size:3 * $mult;
        margin-right:1 * $mult;
        cursor:pointer;
      }
			.inp{
				background-color: #fff;
				height: 100%;
				flex: 1;
				border-radius: 1 * $mult;
				padding: 0 1 * $mult;
			}
			.btn{
				margin-left: 2 * $mult;
				height: 100%;
				width: 12 * $mult;
				font-size: 2 * $mult;
				font-weight: bold;
				color: #333;
        cursor: pointer;
			}
		}
	}
	.top>.modal{
		position: absolute;
    top: 21 * $mult;
    left: 0;
		z-index: 100;
    width: 100%;
    background-color: #fff;
		>.content{
			.cur-term{
				display: flex;
				width: 90%;
				margin: 0 auto;
				border-bottom: 1px solid $border-color;
				margin-top: 1 * $mult;
				align-items: center;
				box-sizing: border-box;
				padding-bottom: 2 * $mult;
				>div{

				}
				.term{
					color: $color-primary;
					margin: 0 0.6 * $mult;
          width: 8 * $mult;
				}
				.opt{

				}
				.ball{
					background-color: $color-primary;
					width: 3 * $mult;
					height: 3 * $mult;
					line-height: 3 * $mult;
					color: #fff;
					border-radius: 50%;
					text-align: center;
					margin: 0 0.6 * $mult;
				}
				.res{
					background-color: $color-warning;
					width: 3 * $mult;
					height: 3 * $mult;
					line-height: 3 * $mult;
					color: #fff;
					border-radius: 50%;
					text-align: center;
					margin: 0 0.6 * $mult;
				}
				.more{
					font-size: 2.4 * $mult;
					margin-left: 1 * $mult;
					color: $color-primary;
				}
			}
		}
	}
  @media screen and (max-width:992px) {
    $mult:2vw;
    .chat{
      width:100%;
    	$panel-height:8 * $mult;
      display: flex;
      flex-direction: column;
      height: 100%;
      position: relative;
    	.top{
    		width: 100%;
    		background-color: #fff;
    		color: #333;
        position: relative;
    		.header{
    			text-align: center;
    			position: relative;
    			padding: 1 * $mult 2 * $mult;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          .back{
            width: 2.5 * $mult;
            height: 2.5 * $mult;
            position: absolute;
            left: 1 * $mult;
            top: 1 * $mult;
          }
    			.title{
    				font-weight: 700;
    			}
          .el-icon-arrow-down{
            font-weight: 700;
            margin-left: 0.5 * $mult;
            font-size: 1.8 * $mult;
          }
    		}
        .lots{
          position: absolute;
          z-index: 100;
          top: 5 * $mult;
          left: 0;
          background-color: #f1f4f8;
          width: 100%;
          >.item{
            padding: 1.5 * $mult 0;
            font-size: 2 * $mult;
            cursor: pointer;
            border-top: 1px solid $border-color;
          }
        }
    	}
    	.body{
        .loading{
          margin-bottom: 1 * $mult;
        }
    		background-color: #e6f1ff;
    		box-sizing: border-box;
    		padding: 0 2 * $mult;
    		padding-top: 4 * $mult;
    		padding-bottom: $panel-height + 4 * $mult;
        flex: 1;
        overflow-y:scroll;
    		.chat-item{
    			margin-bottom: 4 * $mult;
    			// display: none;
    			&.self{
    				.msg{
    					.left{
    						order:1;
    					}
    					.right{
    						order: 0;
    						margin-left: 0;
    						margin-right: 2 * $mult;
    						align-items: flex-end;
    						.content{
    							background-color: #09b91e;
    						}
    					}
    				}
    			}
    			.date{
    				text-align: center;
    				font-size: 1.6 * $mult;
    				color: #666;
    			}
    			.msg{
    				display: flex;
    				.left{
    					width: 6 * $mult;
    					height: 6 * $mult;
    					border-radius: 50%;
    				}
    				.right{
    					margin-left: 2 * $mult;
    					display: flex;
    					flex-direction: column;
    					flex: 1;
    					.nickname{
    						color: #333;
    						font-size: 2 * $mult;
                text-align: left;
    					}
              .c-area{
                display: flex;
                align-items: flex-end;
                .icon{
                  margin-right:1 * $mult;
                  font-size:2 * $mult;
                  cursor:pointer;
                }
              }
    					.content{
    						max-width: 80%;
    						background-color: #fff;
    						color: #333;
    						font-size: 1.8 * $mult;
    						letter-spacing: 0.2 * $mult;
    						padding: 1.2 * $mult;
    						margin-top: 1 * $mult;
    						border-radius: 1 * $mult;
    						width: fit-content;
                text-align: left;
                user-select:text;
                .img-content{
                  width:20 * $mult;
                  height:24 * $mult;
                  object-fit:cover;
                }
                &.content-recall{
                  color:$color-primary;
                }
    					}
    				}
    			}
    		}
    	}
    	.panel{
    		width: 100%;
    		height: $panel-height;
    		display: flex;
    		background-color: #d8e1f1;
    		padding: 2 * $mult;
    		box-sizing: border-box;
        align-items: center;
        .icon-send-img{
          font-size:3 * $mult;
          margin-right:1 * $mult;
          cursor:pointer;
        }
    		.inp{
    			background-color: #fff;
    			height: 100%;
    			flex: 1;
    			border-radius: 1 * $mult;
    			padding: 0 1 * $mult;
    		}
    		.btn{
    			margin-left: 2 * $mult;
    			height: 100%;
    			width: 12 * $mult;
    			font-size: 2 * $mult;
    			font-weight: bold;
    			color: #333;
          cursor: pointer;
    		}
    	}
    }
    .top>.modal{
    	position: absolute;
      top: 21 * $mult;
      left: 0;
    	z-index: 100;
      width: 100%;
      background-color: #fff;
    	>.content{
    		.cur-term{
    			display: flex;
    			width: 90%;
    			margin: 0 auto;
    			border-bottom: 1px solid $border-color;
    			margin-top: 1 * $mult;
    			align-items: center;
    			box-sizing: border-box;
    			padding-bottom: 2 * $mult;
    			>div{

    			}
    			.term{
    				color: $color-primary;
    				margin: 0 0.6 * $mult;
            width: 8 * $mult;
    			}
    			.opt{

    			}
    			.ball{
    				background-color: $color-primary;
    				width: 3 * $mult;
    				height: 3 * $mult;
    				line-height: 3 * $mult;
    				color: #fff;
    				border-radius: 50%;
    				text-align: center;
    				margin: 0 0.6 * $mult;
    			}
    			.res{
    				background-color: $color-warning;
    				width: 3 * $mult;
    				height: 3 * $mult;
    				line-height: 3 * $mult;
    				color: #fff;
    				border-radius: 50%;
    				text-align: center;
    				margin: 0 0.6 * $mult;
    			}
    			.more{
    				font-size: 2.4 * $mult;
    				margin-left: 1 * $mult;
    				color: $color-primary;
    			}
    		}
    	}
    }
  }
</style>

<template>
  <div class="component">
    <div class="rights">
      © 2021XXXX有限公司 . 版权所有
    </div>
  </div>
</template>

<script>
export default {
  name: 'PublicFooter'
}
</script>

<style lang="scss" scoped>
  .component{
    .rights{
      background-color: #f2f6f9;
      position: fixed;
      right: 0;
      bottom: 0;
      width: calc(100% - 55rem);
      height: 3rem;
      z-index: 1100;
      display: flex;
      align-items: center;
      box-sizing: border-box;
      padding-left: 1.25rem;
      font-size: 0.875rem;
    }
  }
  @media screen and (max-width:800px){
    .component{
      .rights{
        width: 100%;
        padding-left: 0;
        text-align: center;
        justify-content: center;
      }
    }
  }
</style>

<template>
  <div class="component">
    <div class="logo" @click="func.navTo('/')">
      <img src="@/assets/logo.png" />
      <span>{{appname}}</span>
    </div>
    <div class="menu">
      <div class="item" v-for="(item,idx) in data.menu" :key="idx">
        <div class="title" @click="func.tapMenu(item)">
          <div class="txt">{{item.title}}</div>
          <i class="icon el-icon-caret-bottom" v-if="item.sub"></i>
        </div>
        <div class="sub" v-if="item.sub && item.showSub">
          <div  @click="func.tapMenu(itm)" class="sub-item" v-for="(itm,idx2) in item.sub" :key="idx2">{{itm.title}}</div>
        </div>
      </div>
    </div>
    <div class="logout" @click="func.logOut">退出</div>
  </div>
</template>

<script>
import cfg from '@/common/conf'
import { navTo } from '@/common/func'
import { ElMessageBox, ElMessage } from 'element-plus'
import { ref,reactive } from 'vue'
import { logout,user } from "@/data/token"
import axios from 'axios'
export default {
  name: 'PublicHeader',
  setup () {
    const updatePwd = ()=>{
      ElMessageBox.prompt('请输入新密码', '修改密码', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
        })
          .then(({ value }) => {
            return axios.post('/admin/setting/pwd',{
              password:value
            })
          })
          .then(() => {
            ElMessage({
              type: 'success',
              message: `修改成功`,
            })
          })
          .catch(() => {
          })
    }
    const openAssistChat = ()=>{
      window.open("/chat")
    }
    const odds = []

    const menu = [
      {
        title:"首页",
        link:"/"
      },
      {
        title:"玩家列表",
        link:"/member"
      },
      {
        title:"开奖数据",
        link:"/lots/result"
      },
      {
        title:"财务管理",
        sub:[
          {
            title:"上分管理",
            link:"/member/recharge"
          },
          {
            title:"下分管理",
            link:"/member/withdraw"
          },
          {
            title:"总统计报表(日)",
            link:"/order/report"
          },
          {
            title:"总统计报表(月)",
            link:"/order/report/month"
          },
          {
            title:"历史下注",
            link:"/order"
          },
          {
            title:"积分变动",
            link:"/member/money"
          },
          {
            title:"回水历史",
            link:"/water"
          },
        ]
      },
      {
        title:"游戏设置",
        sub:odds
      },
      {
        title:"回水管理",
        sub:[
          {
            title:"回水设置",
            link:"/water/setting"
          }
        ]
      },
      {
        title:"系统设置",
        sub:[
          {
            title:"公告管理",
            link:"/sys/notice"
          },
          {
            title:"客服设置",
            link:"/sys/assist"
          },
          {
            title:"游戏说明",
            link:"/sys/info"
          },
          {
            title:"轮播管理",
            link:"/sys/slider"
          },
          {
            title:"基础设置",
            link:"/sys/base"
          },
          {
            title:"收款设置",
            link:"/sys/account"
          },
          {
            title:"自动拖",
            link:"/member/robot"
          },
          {
            title:"修改密码",
            func:updatePwd
          },
        ]
      },
    ]
    // if(user.type == "admin"){
      menu.push({
        title:"日志",
        link:"/log"
      })
    // }
    menu.push({
      title:"在线客服",
      func:openAssistChat
    })
    const data = reactive({
      menu
    })
    const func = {
      async loadOddsMenu(){
        const res = await axios.get('/admin/lot')
        const odds = []
        for(let item of res){
          odds.push({
            title:item.Name,
            link:"/lots/odds",
            params:{
              id:item.LotId
            }
          })
        }
        for(let item of data.menu){
          if(item.title == "游戏设置"){
            item.sub = odds
            console.log("game odds",odds)
          }
        }
      },
      tapMenu(item){
        for(let tmp of data.menu){
          if(tmp.title != item.title){
            if(tmp.sub){
              tmp.showSub = false
            }
          }
        }
        if(item.sub){
          item.showSub = !item.showSub
        }
        if(item.link){
          if(!item.params){
            item.params = {}
          }
          console.log("navTo",item.link,item.params)
          navTo(item.link,item.params)
        }else if(item.func){
          item.func()
        }
      }
    }
    func.loadOddsMenu()
    func.navTo = navTo
    func.logOut = () => {
      ElMessageBox.confirm('确定要退出登录吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          return axios.post("/admin/logout")
        })
        .then(()=>{
          logout()
          navTo('/login')
        })
        .catch(() => {
          ElMessage({
            type: 'info',
            message: '已取消'
          })
        })
    }
    return {
      func,
      data,
      appname: cfg.appname
    }
  }
}
</script>

<style lang="scss" scoped>
  .component{
    display: flex;
    align-items: center;
    padding: 1.5rem 2rem;
    justify-content: space-between;
    background-color: #333;
    color: #fff;
    .menu{
      display: flex;
      flex: 1;
      padding-left: 15rem;

      >.item{
        padding: 0 1.5rem;
        font-weight: bold;
        cursor: pointer;
        display: flex;
        align-items: center;
        position: relative;
        >.sub{
          position: absolute;
          top: 3.5rem;
          left: 0;
          background-color: #333;
          padding: 0.5rem;
          box-sizing: border-box;
          z-index: 100;
          width: max-content;
          .sub-item{
            padding: 1.5rem 2rem;
            border-top: 1px solid #ccc;
          }
        }
        >.title{
          display: flex;
          align-items: center;
          >.icon{
            margin-left: 1rem;
            font-weight: bold;
          }
        }
      }
    }
    .logout{
      cursor: pointer;
    }
    .logo{
      display: flex;
      align-items: center;
      font-weight: bold;
      cursor: pointer;
      font-size: 3rem;
      >img{
        width: 5rem;
        height: 5rem;
        margin-right: 1rem;
      }
    }

  }
</style>

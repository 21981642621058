<template>
  <div class="content">
    <div class="window">
      <div class="list" v-show="data.showTabIdx == 0 || data.showTabIdx == 1">
        <ChatList/>
      </div>
      <div class="chat" v-show="data.showTabIdx == 0 || data.showTabIdx == 2">
        <div class="nav" v-if="data.showTabIdx == 2">
          <img src="@/assets/img/icon_back_black.png" class="icon left" @click="data.showTabIdx = 1">
          <div class="title">{{data.userInfo.Nickname}}({{data.userInfo.Id}})</div>
          <img src="@/assets/img/icon_back_black.png" class="icon right" @click="data.showTabIdx = 3">
        </div>
        <ChatAssist/>
      </div>
      <div class="profile" v-show="data.showTabIdx == 0 || data.showTabIdx == 3">
        <img v-if="data.showTabIdx == 3" src="@/assets/img/icon_back_black.png" class="icon-left" @click="data.showTabIdx = 2">
        <div class="curren-user" v-if="data.userInfo.Id">
          <div class="head-line">
            <img class="avatar" :src="data.userInfo.Avatar"/>
          </div>
          <div class="item-line">
            <div class="key">昵称：</div>
            <div class="val">{{data.userInfo.Nickname}}</div>
          </div>
          <div class="item-line">
            <div class="key">ID：</div>
            <div class="val">{{data.userInfo.Id}}</div>
          </div>
          <div class="item-line">
            <div class="key">在线状态：</div>
            <div class="val">
              <div class="ostatus" :class="data.userInfo.IsOnline?'status_online':'status_offline'"></div>
            </div>
          </div>
          <div class="item-line" v-if="data.userInfo.LastIp">
            <div class="key">IP：</div>
            <div class="val">{{data.userInfo.LastIp}}</div>
          </div>
          <div class="item-line" v-if="data.userInfo.Money">
            <div class="key">余额：</div>
            <div class="val">{{data.userInfo.Money}}</div>
          </div>
          <div class="item-line" v-if="data.userInfo.Realname">
            <div class="key">真实姓名：</div>
            <div class="val">{{data.userInfo.Realname}}</div>
          </div>
          <div class="item-line" v-if="data.userInfo.CreatedAt">
            <div class="key">注册时间：</div>
            <div class="val">{{toDay(data.userInfo.CreatedAt)}}</div>
          </div>
          <div class="item-line" v-if="data.userInfo.AccessDomain">
            <div class="key">访问域名：</div>
            <div class="val">{{data.userInfo.AccessDomain}}</div>
          </div>
          <div class="user-options">
            <div class="date">
              <el-date-picker
                    class="inp"
                    v-model="data.valTime"
                    size="small"
                    type="daterange"
                    unlink-panels
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    :shortcuts="shortcuts"
                  >
                  </el-date-picker>
            </div>
            <el-button class="btn" size="mini" @click="clearMsg(data.userInfo.Id,data.valTime)" type="success" round>清空用户日期聊天记录</el-button>
            <el-button class="btn" size="mini" @click="clearMsg(data.userInfo.Id)" type="danger" round>清空用户所有聊天记录</el-button>
          </div>
        </div>
        <div class="line">-------全局操作--------</div>
        <div class="global-options">
          <div class="date">
            <el-date-picker
                  class="inp"
                  v-model="data.valTime"
                  size="small"
                  type="daterange"
                  unlink-panels
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  :shortcuts="shortcuts"
                >
                </el-date-picker>
          </div>
          <el-button class="btn" size="mini" @click="clearMsg(0,data.valTime)" type="primary" round>清空所有用户日期聊天记录</el-button>
          <el-button class="btn" size="mini" @click="clearMsg()" type="warning" round>清空所有用户所有聊天记录</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { updateShowPanel } from '@/data/default'
  import ChatList from '@/components/chat/chatList'
  import ChatAssist from "@/components/chat/assist"
  import event from "@/common/eventHub"
  import { reactive } from 'vue'
  import axios from 'axios'
  import { toDay,tips,loading,warn } from "@/common/func"
  import { shortcuts } from '@/data/default'
  import { ElMessageBox, ElMessage } from 'element-plus'
  export default {
    name: 'Chat',
    components:{
      ChatList,
      ChatAssist
    },
    setup () {
      updateShowPanel(false)
      const data = reactive({
        userInfo:{},
        valTime:'',
        showTabIdx:0,
      })
      //App.vue
      const isMobile = ()=> {
      	 let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      	 return flag;
      }
      if(isMobile()){
        data.showTabIdx = 1
      }
      const getUserInfo = async (userId) =>{
        if(isNaN(userId)){
          const res = await axios.get(`/public/guest`,{
            params:{
              guestId:userId,
            }
          })
          res.Id = res.GuestId
          data.userInfo = res
        }else{
          const res = await axios.get(`/admin/user`,{
            params:{
              userId,
            }
          })
          data.userInfo = res.List[0]
        }
      }
      const clearMsg = async (userId=0,valTime="") => {
        console.log("params",userId,valTime)
        try{
          await ElMessageBox.confirm('确定要清除聊天记录吗?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          })
          let url = "/admin/chat/clear?1=1"
          if(userId){
            url += "&chatUid="+userId
          }
          if(valTime){
              const startAt = Math.floor(valTime[0].getTime()/1000)
              const endAt = Math.floor(valTime[1].getTime()/1000)
              url += "&startAt="+startAt
              url += "&endAt="+endAt
          }
          await axios.delete(url)
          //刷新数据
          event.$emit("chat_refresh")
        }catch(e){

        }
      }
      event.$on("to_chat",(userId,nickname)=>{
        if(data.showTabIdx)data.showTabIdx = 2
        getUserInfo(userId)
      })
      return {
        data,
        shortcuts,
        toDay,
        clearMsg,
      }
    }
  }
</script>
<style lang="scss" scoped>
  .content{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: $color-bg;
    :deep(.el-range-editor--small.el-input__inner){
      width: 220px;
    }
    .ostatus{
      width: 1.5 * $mult;
      height: 1.5 * $mult;
      border-radius: 50%;
      display: block;
      &.status_online{
        background-color: $color-success;
      }
      &.status_offline{
        background-color: $color-default;
      }
    }
    .window{
      $win-border-radio:10px;
      width: 160 * $mult;
      height: 88vh;
      border-radius: $win-border-radio;
      box-shadow: $box-shadow;
      display: flex;
      .list{
        height: 100%;
        background-color: #fff;
        width: 40 * $mult;
        border-top-left-radius: $win-border-radio;
        border-bottom-left-radius: $win-border-radio;
      }
      .chat{
        flex: 1;
        // border-top: 0.5 * $mult solid #fff;
        box-shadow: $box-shadow;
        z-index:2;
      }
      .line{
        margin: 3 * $mult 0;
        font-weight: bold;
      }
      .global-options{
        display: flex;
        flex-direction: column;
        .btn{
          display: block;
          width: 80%;
          margin: 0 auto;
          margin-top: 3 * $mult;
        }
      }
      .profile{
        height: 100%;
        background-color: #fff;
        width: 40 * $mult;
        border-top-right-radius: $win-border-radio;
        border-bottom-right-radius: $win-border-radio;
        padding: 1 * $mult;
        box-sizing: border-box;
        font-size: 2 * $mult;
        display: flex;
        flex-direction: column;
        align-items: center;
        overflow: hidden scroll;
        .user-options{
          margin-top: 8 * $mult;
          display: flex;
          flex-direction: column;
          .btn{
            display: block;
            margin: 0 auto;
            width: 80%;
            margin-top: 3 * $mult;
          }
        }
        .item-line{
          display: flex;
          margin-top: 2 * $mult;
          align-items: center;
          .key{
            width: 15 * $mult;
          }
          .val{
            font-weight: bold;
          }
        }
        .head-line{
          display: flex;
          align-items: center;
          justify-content: center;
          margin-top: 3 * $mult;
          .avatar{
            width: 13 * $mult;
            height: 13 * $mult;
            border-radius: 50%;
            margin-bottom: 2 * $mult;
          }
        }
      }
    }
  }
  @media screen and (max-width:600px) {
    $mult:2vw;
    .content{
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100vh;
      background-color: $color-bg;
      :deep(.el-range-editor--small.el-input__inner){
        width: 220px;
      }
      .ostatus{
        width: 1.5 * $mult;
        height: 1.5 * $mult;
        border-radius: 50%;
        display: block;
        &.status_online{
          background-color: $color-success;
        }
        &.status_offline{
          background-color: $color-default;
        }
      }
      .window{
        $win-border-radio:10px;
        width: 160 * $mult;
        height: 88vh;
        border-radius: $win-border-radio;
        box-shadow: $box-shadow;
        display: flex;
        .list{
          height: 100%;
          background-color: #fff;
          width: 40 * $mult;
          border-top-left-radius: $win-border-radio;
          border-bottom-left-radius: $win-border-radio;
        }
        .chat{
          flex: 1;
          // border-top: 0.5 * $mult solid #fff;
          box-shadow: $box-shadow;
          z-index:2;
        }
        .line{
          margin: 3 * $mult 0;
          font-weight: bold;
        }
        .global-options{
          display: flex;
          flex-direction: column;
          .btn{
            margin-top: 3 * $mult;
          }
        }
        .profile{
          height: 100%;
          background-color: #fff;
          width: 40 * $mult;
          border-top-right-radius: $win-border-radio;
          border-bottom-right-radius: $win-border-radio;
          padding: 1 * $mult;
          box-sizing: border-box;
          font-size: 2 * $mult;
          display: flex;
          flex-direction: column;
          align-items: center;
          .user-options{
            margin-top: 8 * $mult;
            display: flex;
            flex-direction: column;
            .btn{
              margin-top: 3 * $mult;
            }
          }
          .item-line{
            display: flex;
            margin-top: 2 * $mult;
            align-items: center;
            .key{
              width: 15 * $mult;
            }
            .val{
              font-weight: bold;
            }
          }
          .head-line{
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 3 * $mult;
            .avatar{
              width: 13 * $mult;
              height: 13 * $mult;
              border-radius: 50%;
              margin-bottom: 2 * $mult;
            }
          }
        }
      }
    }
  }
  @media screen and (max-width:600px) {
    $mult : 1.5vw;
    .content{
      .window{
        width: 100vw;
        height: 100vh;
        .list{
          width: 100%;
        }
        .chat{
          width: 100%;
          .nav{
            display: flex;
            padding: 1 * $mult;
            background-color: #fff;
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            z-index: 9999;
            box-sizing: border-box;
            .icon{
              width: 3 * $mult;
              &.right{
                transform: rotate(180deg);
              }
            }
            .title{
              flex: 1;
              font-size: 2 * $mult;
              font-weight: bold;
            }
          }
        }
        .profile{
          width: 100%;
          position: relative;
          .icon-left{
            position: absolute;
            top: 1.5 * $mult;
            left: 1.5 * $mult;
            width: 3 * $mult;
          }
        }
      }
    }
  }
</style>

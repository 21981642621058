import { reactive } from 'vue'
import axios from 'axios'
export const lots = reactive({
  source:[],
  choose:[],
  search:[]
})
export function getLotName(id){
  for(let item of lots.choose){
    if(item.value == id){
      return item.label
    }
  }
  return ""
}
export async function updateLots(){
  try{
    let res = await axios.get('/admin/lot')
    lots.source = res
    const choose = []
    const search = [{
      label:"全部",
      value:0,
    }]
    for(let item of res){
      const tmp = {
        label:item.Name,
        value:item.LotId,
      }
      choose.push(tmp)
      search.push(tmp)
    }
    lots.choose = choose
    lots.search = search
  }catch(e){
    console.log("updateLots error",e)
  }
}

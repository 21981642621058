<template>
	<div class="cpn">
    <div class="chat-list">
      <input placeholder="输入想要聊天的用户ID并按回车键" v-model="data.desireChatId" class="inp" @keyup.enter.native="methods.toChat(data.desireChatId)" />
      <div @click="methods.toChat(item.UserFrom,item.Nickname)" v-for="(item,index) in data.data" :key="index" class="chat-item" :class="{'active':data.activeUid == item.UserFrom}">
        <i @click.stop="methods.del(item)" class="el-icon-close header-icon"></i>
        <img class="avatar" :src="item.Avatar" />
        <div class="right">
          <div class="top">
            <div class="nickname">{{item.title}}</div>
            <div class="date">{{item.msg.data.date}}</div>
          </div>
          <div class="bottom">
            <div class="content" v-if="item.msg.data.type == 'txt'">{{item.msg.data.content }}</div>
            <div class="content" v-if="item.msg.data.type == 'img'">[图片]</div>
            <div class="content" v-if="item.msg.data.type == 'recall'">已撤回</div>
            <div class="num" v-if="item.unread">{{item.unread}}</div>
          </div>
        </div>
      </div>
    </div>
	</div>
</template>

<script>
  import { user } from "@/data/token"
  import event from "@/common/eventHub"
  import { onMounted,onUnmounted,reactive } from "vue"
  import { ElMessageBox, ElMessage } from 'element-plus'
  import axios from "axios"
	export default {
    name:"ChatList",
    components:{
    },
    setup(){

    const data =reactive({
      data:[],
      desireChatId:"",
      activeUid:"",
		})
    const methods = {
        async del(target){
          try{
            await ElMessageBox.confirm('确定要清除用户聊天记录吗?', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            })
            data.data = data.data.filter((item)=>{
              return item.Id != target.Id
            })
            await axios.delete("/admin/chat/clear?chatUid="+target.UserFrom)
            //重新计算未读消息数
            event.$emit("new_user_msg")
          }catch(e){

          }
        },
        async toChat(userFrom,nickname){
          data.activeUid = userFrom
          event.$emit("to_chat",userFrom,nickname)
        },
        async toList(){
            console.log("toList")
            data.showList = true
        },
        async loadMsg(){
          const list = []
          let res = await axios.get("/admin/chat/list")
          for(let item of res){
            item.msg = JSON.parse(item.Msg)
            if(isNaN(item.UserFrom)){
              //访客
              item.title = `${item.Nickname}`
            }else{
              item.title = `${item.Nickname}(${item.UserFrom})`
            }
            item.unread = item.Unread === 0 ? "" : (item.Unread > 99 ? "99+":item.Unread + "")
            list.push(item)
          }
          data.data = list
        }
    }
    const onUserMsg = ()=>{
      methods.loadMsg()
    }
    const refresh = ()=>{
      methods.loadMsg()
    }
    onMounted(()=>{
      event.$on("new_user_msg",onUserMsg)
      event.$on("read_user_msg",onUserMsg)
      event.$on("chat_refresh",refresh)
      methods.loadMsg()
    })
    onUnmounted(()=>{
      event.$off("new_user_msg",onUserMsg)
      event.$off("read_user_msg",onUserMsg)
      event.$off("chat_refresh",refresh)
    })
    return {
      methods,
      data
    }
    }
	}
</script>

<style lang="scss" scoped>
	.cpn{
    height: 100%;
    overflow-y: scroll;
    background-color: #fff;
    .inp{
      width: 100%;
      border:0;
      box-sizing: border-box;
      padding: 10px 10px;
      text-align: center;
      border-bottom: 1px solid $color-bg;
      outline: none;
    }
    .chat-item{
      display: flex;
      padding: 2 * $mult;
      box-sizing: border-box;
      border-bottom: 1px solid $border-color;
      align-items: center;
      cursor: pointer;
      position: relative;
      &:hover{
        .header-icon{
          display: block;
        }
      }
      &.active{
        border-right: 4px solid $color-success;
      }
      .header-icon{
        background: $color-primary;
        font-size: 1.5 * $mult;
        font-weight: bold;
        position: absolute;
        right: 0.5 * $mult;
        top: 0.5 * $mult;
        cursor: pointer;
        color: #fff;
        border-radius: 50%;
        padding: 3px;
        display: none;
      }
      .avatar{
        width: 7 * $mult;
        height: 7 * $mult;
        border-radius: 50%;
        margin-right: 1 * $mult;
      }
      .right{
        flex: 1;
        .top{
          display: flex;
          font-size: 1.4 * $mult;
          align-items: flex-end;
          >.nickname{
            flex: 1;
            text-align: left;
            font-weight: 700;
            font-size: 2 * $mult;
          }
        }
        .bottom{
          display: flex;
          font-size: 1.4 * $mult;
          align-items: flex-end;
          margin-top: 0.5 * $mult;
          >.content{
            flex: 1;
            font-size: 2 * $mult;
            text-align: left;
          }
          >.num{
            background-color: $color-error;
            color: #fff;
            font-size: 1.4 * $mult;
            font-weight: 700;
            border-radius: 50%;
            width: 3 * $mult;
            height: 3 * $mult;
            line-height: 3 * $mult;
          }
        }
      }
    }
	}
  @media screen and (max-width:992px) {
    $mult:2vw;
    .cpn{
      height: 100%;
      overflow-y: scroll;
      background-color: #fff;
      .inp{
        width: 100%;
        border:0;
        box-sizing: border-box;
        padding: 10px 10px;
        text-align: center;
        border-bottom: 1px solid $color-bg;
        outline: none;
      }
      .chat-item{
        display: flex;
        padding: 2 * $mult;
        box-sizing: border-box;
        border-bottom: 1px solid $border-color;
        align-items: center;
        cursor: pointer;
        position: relative;
        &:hover{
          .header-icon{
            display: block;
          }
        }
        &.active{
          border-right: 4px solid $color-success;
        }
        .header-icon{
          background: $color-primary;
          font-size: 1.5 * $mult;
          font-weight: bold;
          position: absolute;
          right: 0.5 * $mult;
          top: 0.5 * $mult;
          cursor: pointer;
          color: #fff;
          border-radius: 50%;
          padding: 3px;
          display: none;
        }
        .avatar{
          width: 7 * $mult;
          height: 7 * $mult;
          border-radius: 50%;
          margin-right: 1 * $mult;
        }
        .right{
          flex: 1;
          .top{
            display: flex;
            font-size: 1.4 * $mult;
            align-items: flex-end;
            >.nickname{
              flex: 1;
              text-align: left;
              font-weight: 700;
              font-size: 2 * $mult;
            }
          }
          .bottom{
            display: flex;
            font-size: 1.4 * $mult;
            align-items: flex-end;
            margin-top: 0.5 * $mult;
            >.content{
              flex: 1;
              font-size: 2 * $mult;
              text-align: left;
            }
            >.num{
              background-color: $color-error;
              color: #fff;
              font-size: 1.4 * $mult;
              font-weight: 700;
              border-radius: 50%;
              width: 3 * $mult;
              height: 3 * $mult;
              line-height: 3 * $mult;
            }
          }
        }
      }
    }
  }
</style>

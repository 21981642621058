<template>
  <div class="content">
    <div class="panel panel-4 panel-bottom">
      <div class="title">
        <div class="txt">基础设置</div>
      </div>
      <div class="body">
        <el-input :key="index" v-for="(item,index) in data.setting" class="inp" size="small" v-model="item.Sval">
          <template #prepend>{{item.Skey}}</template>
        </el-input>

        <el-input v-if="user.type == 'admin'" :key="index" v-for="(item,index) in data.admSetting" class="inp" size="small" v-model="item.Sval">
          <template #prepend>{{item.Skey}}</template>
        </el-input>

        <div class="img">
          <div class="label">客服头像</div>
          <el-upload
              class="avatar-uploader"
              action="/public/upload"
              :show-file-list="false"
              :on-success="func.handleUploadSuccess"
              :before-upload="func.beforeUpload"
            >
            <img class="img-item" :src="data.avatar.Sval" />
          </el-upload>
        </div>
      </div>
      <div class="bottom">
        <el-button @click="func.save" type="success" round>保存</el-button>
      </div>
    </div>
  </div>
</template>

<script>
  import { reactive,onMounted } from 'vue'
  import axios from 'axios'
  import { loading,tips,warn } from '@/common/func'
  import { user } from '@/data/token'
  export default {
    name:"SysBase",
    setup(){
      const data = reactive({
        setting:[],
        admSetting:[],
        avatar:{}
      })
      const func = {
        beforeUpload(){

        },
        handleUploadSuccess(res,file){
          if(res.Code !== 0){
            return warn(res.Msg)
          }
          data.avatar.Sval = res.Data.url
        },
        async init(){
          let res = await axios.get('/public/setting')
          let setting = []
          for(let item of res){
            if(["自开彩名称","自开彩开奖间隔(秒)"].includes(item.Skey)){
              data.admSetting.push(item)
            }else if(!["微信收款码","支付宝收款码","客服微信","客服头像"].includes(item.Skey)){
              setting.push(item)
            }else if(["客服头像"].includes(item.Skey)){
              data.avatar = item
            }
          }
          data.setting = setting
        },
        async save(){
          try{
            loading.show()
            const list = []
            for(let item of data.setting){
              list.push(axios.post("/admin/setting",{
                key:item.Skey,
                val:item.Sval
              }))
            }
            for(let item of data.admSetting){
              list.push(axios.post("/admin/setting",{
                key:item.Skey,
                val:item.Sval
              }))
            }
            list.push(axios.post("/admin/setting",{
              key:data.avatar.Skey,
              val:data.avatar.Sval
            }))
            await Promise.all(list)
          }catch(e){
            console.log("err",e)
          }
          loading.hide()
          tips("修改成功")
        }
      }
      onMounted(()=>{
        func.init()
      })
      return {
        data,
        user,
        func
      }
    }
  }
</script>

<style lang="scss" scoped>
  .content{
    padding: 2rem ;
    box-sizing: border-box;
    display: grid;
    grid-template-columns: repeat(4,1fr);
    gap: 2rem;
    .panel{
      background-color: #fff;
      border: 1px solid $border-color;
      border-top: 3px solid $color-info;
      border-radius: 0.5rem;
      >.bottom{
        text-align: right;
        padding: 2rem;
      }
      &.panel-2{
        grid-column-start: span 2;
      }
      &.panel-bottom{
        margin-bottom: 10rem;
      }
      &.panel-4{
        grid-column-start: span 4;
        >.body{
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          >.inp{
            width: 49%;
          }
          .lot-item{
            width: 100%;
            margin-top: 1rem;
            display: grid;
            grid-template-columns: repeat(4,1fr);
            gap: 1rem 3rem;
            .inp{
            }
          }

        }
      }
      >.title{
        font-weight: 700;
        font-size: 2.5rem;
        border-bottom: 1px solid $border-color;
        padding: 1.5rem 2rem;
        display: flex;
        justify-content: space-between;
      }
      >.body{
        padding: 1.5rem 2rem;
        >.img{
          display: flex;
          align-items: center;
          margin-top: 2rem;
          .label{
            font-size: 2rem;
            margin-right: 2rem;
          }
          .img-item{
            cursor: pointer;
            width: 25rem;
            height: 25rem;
            object-fit: cover;
          }
        }
        .inp{
          margin-bottom: 1rem;
          --el-color-info:#333;
          &.inline-inp{
            display: flex;
          }
          .inp-prepend{
            >.cb{
              --el-checkbox-font-size: 1.8rem;
            }
          }
        }
      }
    }
  }
</style>

import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
import Chat from '../views/Chat.vue'
import MemberList from '../views/member/list.vue'
import LotsResult from '../views/lots/result.vue'
import OrderReport from '../views/order/report.vue'
import OrderReportMonth from '../views/order/report_month.vue'
import OrderList from '../views/order/list.vue'
import MemberMoney from '../views/member/money.vue'
import MemberWithdraw from '../views/member/withdraw.vue'
import MemberRecharge from '../views/member/recharge.vue'
import MemberRobot from '../views/member/robot.vue'
import LotsOdds  from '../views/lots/odds.vue'
import WaterSetting from '../views/water/setting.vue'
import SysNotice from '../views/sys/notice.vue'
import SysInfo from '../views/sys/info.vue'
import SysAccount from '../views/sys/account.vue'
import SysBase from '../views/sys/base.vue'
import SysSlider from '../views/sys/slider.vue'
import WaterList from '../views/water/list.vue'
import SysLog from '../views/log/system.vue'
import SysAssist from '../views/sys/assist.vue'
const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/chat',
    name: 'Chat',
    component: Chat
  },
  {
    path: '/log',
    name: 'SysLog',
    component: SysLog
  },
  {
    path: '/member',
    name: 'MemberList',
    component: MemberList
  },
  {
    path: '/lots/result',
    name: 'LotsResult',
    component: LotsResult
  },
  {
    path: '/order/report',
    name: 'OrderReport',
    component: OrderReport
  },
  {
    path: '/order/report/month',
    name: 'OrderReportMonth',
    component: OrderReportMonth
  },
  {
    path: '/order',
    name: 'OrderList',
    component: OrderList
  },
  {
    path: '/member/money',
    name: 'MemberMoney',
    component: MemberMoney
  },
  {
    path: '/member/withdraw',
    name: 'MemberWithdraw',
    component: MemberWithdraw
  },
  {
    path: '/member/recharge',
    name: 'MemberRecharge',
    component: MemberRecharge
  },
  {
    path: '/member/robot',
    name: 'MemberRobot',
    component: MemberRobot
  },
  {
    path: '/lots/odds',
    name: 'LotsOdds',
    component: LotsOdds
  },
  {
    path:'/water/setting',
    name: 'WaterSetting',
    component: WaterSetting,
  },
  {
    path:'/water',
    name: 'WaterList',
    component: WaterList,
  },
  {
    path:'/sys/notice',
    name: 'SysNotice',
    component: SysNotice,
  },
  {
    path:'/sys/assist',
    name: 'SysAssist',
    component: SysAssist,
  },
  {
    path:'/sys/info',
    name: 'SysInfo',
    component: SysInfo,
  },
  {
    path:'/sys/slider',
    name: 'SysSlider',
    component: SysSlider,
  },
  {
    path:'/sys/account',
    name: 'SysAccount',
    component: SysAccount,
  },
  {
    path:'/sys/base',
    name: 'SysBase',
    component: SysBase,
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router

import { ref, reactive } from 'vue'
import axios from "axios"

//订单提示音播放组件
export const orderAlert = ref(null)
export const orderAlertMuted = ref(true)
// 是否展示公共组件
export const showPanel = ref(true)
export const updateShowPanel = (flag) => {
  if (showPanel.value === flag) {
    return
  }
  showPanel.value = flag
}

//日期搜索快捷选项
export const shortcuts = [
  {
    text: '今天',
    value: () => {
      const end = new Date()
      end.setDate(end.getDate()+1)
      end.setHours(0)
      end.setMinutes(0)
      end.setSeconds(0)
      const start = new Date()
      start.setHours(0)
      start.setMinutes(0)
      start.setSeconds(0)
      start.setTime(start.getTime())
      return [start, end]
    }
  },
  {
    text: '昨天',
    value: () => {
      const end = new Date()
      end.setDate(end.getDate())
      end.setHours(0)
      end.setMinutes(0)
      end.setSeconds(0)
      const start = new Date()
      start.setHours(0)
      start.setMinutes(0)
      start.setSeconds(0)
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 1)
      return [start, end]
    }
  },
  {
    text: '最近一周',
    value: () => {
      const end = new Date()
      end.setDate(end.getDate()+1)
      end.setHours(0)
      end.setMinutes(0)
      end.setSeconds(0)
      const start = new Date()
      start.setHours(0)
      start.setMinutes(0)
      start.setSeconds(0)
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
      return [start, end]
    }
  }, {
    text: '最近一个月',
    value: () => {
      const end = new Date()
      end.setDate(end.getDate()+1)
      end.setHours(0)
      end.setMinutes(0)
      end.setSeconds(0)
      const start = new Date()
      start.setHours(0)
      start.setMinutes(0)
      start.setSeconds(0)
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
      return [start, end]
    }
  }, {
    text: '最近三个月',
    value: () => {
      const end = new Date()
      end.setDate(end.getDate()+1)
      end.setHours(0)
      end.setMinutes(0)
      end.setSeconds(0)
      const start = new Date()
      start.setHours(0)
      start.setMinutes(0)
      start.setSeconds(0)
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
      return [start, end]
    }
  }
]

<template>
  <div class="content">
    <div class="panel panel-4 panel-bottom">
      <div class="title">
        <div class="txt">收款账号设置</div>
      </div>
      <div class="body">
        <div class="ac-item" v-for="item,idx in data.accounts" :key="idx">
          <div class="title">{{item.type.Name}}</div>
          <el-button @click="func.addAccount(item.type.Type)" class="btn-add" type="primary" size="small" round>添加</el-button>
          <div class="cb-group">
            <el-checkbox v-model="item.type.Recommend" label="推荐使用" class="cb"></el-checkbox>
            <el-checkbox v-model="item.type.NewUserAble" label="新用户可用" class="cb"></el-checkbox>
            <el-checkbox v-model="item.type.OldUserAble" label="老用户可用" class="cb"></el-checkbox>
            <el-checkbox v-model="item.type.RechargeNeedCard" label="充值前需要绑定银行卡" class="cb"></el-checkbox>
            <el-checkbox v-model="item.type.WithdrawNeedCard" label="提现前需要绑定银行卡" class="cb"></el-checkbox>
            <el-checkbox v-model="item.type.RechargeNeedPhone" label="充值前需要绑定手机号" class="cb"></el-checkbox>
            <el-checkbox v-model="item.type.WithdrawNeedPhone" label="提现前需要绑定手机号" class="cb"></el-checkbox>
          </div>
          <div class="cond" :key="idx2" v-for="(item2,idx2) in item.data">
            <i @click="func.delAccount(idx,idx2)" class="el-icon-close close-icon"></i>
            <el-input type="number" class="inp" size="small" v-model="item2.GiftRate">
              <template #prepend>充值赠送比例 ( 送10%则填0.1 )</template>
            </el-input>
            <el-input type="number" class="inp" size="small" v-model="item2.MinMoney">
              <template #prepend>最少充值金额</template>
            </el-input>
            <!-- 银行卡 -->
            <el-input v-if="item2.Type==1" class="inp" size="small" v-model="item2.Cat">
              <template #prepend>银行类型</template>
            </el-input>
            <el-input v-if="item2.Type==1" class="inp" size="small" v-model="item2.Name">
              <template #prepend>银行卡持有人</template>
            </el-input>
            <el-input v-if="item2.Type==1" class="inp" size="small" v-model="item2.Account">
              <template #prepend>银行卡号</template>
            </el-input>
            <!-- USDT -->
            <el-input v-if="item2.Type==4" class="inp" size="small" v-model="item2.Cat">
              <template #prepend>当前汇率</template>
            </el-input>
            <el-input v-if="item2.Type==4" class="inp" size="small" v-model="item2.Name">
              <template #prepend>协议</template>
            </el-input>
            <el-input v-if="item2.Type==4" class="inp" size="small" v-model="item2.Account">
              <template #prepend>地址</template>
            </el-input>

            <el-input type="text" class="inp" size="small" v-model="item.type.Remark">
              <template #prepend>备注信息</template>
            </el-input>
            <!-- EBPay -->
            <!-- 默往 -->
            <div class="img" v-if="item2.Type==2 || item2.Type==3 || item2.Type==6 || item2.Type==11">
              <div class="label">二维码</div>
              <el-upload
                  class="avatar-uploader"
                  :action="'/public/upload?id='+idx+','+idx2"
                  :show-file-list="false"
                  :on-success="func.handleUploadSuccess"
                  :before-upload="func.beforeUpload"
                >
                <img class="img-item" :src="item2.Qrcode" />
              </el-upload>
            </div>
          </div>
        </div>
      </div>
      <div class="bottom">
        <el-button @click="func.save" type="success" round>保存</el-button>
      </div>
    </div>
  </div>
</template>

<script>
  import { reactive,onMounted } from 'vue'
  import axios from 'axios'
  import { loading,tips,warn } from '@/common/func'
  export default {
    name:"SysAccount",
    setup(){
      const data = reactive({
        accounts:[],
        avatar:{}
      })
      const func = {
        async save(){
            try{
              loading.show()
              const list = []
              for(let typeAccount of data.accounts){
                list.push(axios.post('/admin/sys/account/type',typeAccount.type))
                for(let item of typeAccount.data){
                  list.push(axios.post('/admin/sys/account',item))
                }
              }
              if(list.length){
                await Promise.all(list)
              }
              await func.init()
            }catch(e){
              console.log("err",e)
            }
            loading.hide()
            tips("修改成功")
        },
        async delAccount(i,j){
          console.log("data.accounts[i].data",data.accounts[i].data[j])
          if(data.accounts[i].data[j].Id){
            //删除数据库
            try{
              await axios.delete("/admin/sys/account?id="+data.accounts[i].data[j].Id)
            }catch(e){
              return
            }
          }
          data.accounts[i].data.splice(j,1)
        },
        addAccount(type){
          for(let item of data.accounts){
            if(item.type.Type==type){
              item.data.push({
                Id:0,
                Type:type,
                Cat:"",
                Name:"",
                Account:"",
                Qrcode:"",
                MinMoney:0,
                GiftRate:0,
              })
            }
          }
        },
        beforeUpload(){

        },
        handleUploadSuccess(res,file){
          if(res.Code !== 0){
            return warn(res.Msg)
          }
          let id = res.Data.id
          let idArr = id.split(",")
          let i = parseInt(idArr[0])
          let j = parseInt(idArr[1])
          data.accounts[i].data[j].Qrcode = res.Data.url
        },
        async init(){
          let res = await axios.get('/admin/sys/account')
          let accounts = []
          for(let i=0;i<res.length;i++){
            accounts.push({
              type:res[i].AccountType,
              data:res[i].Accounts
            })
          }
          console.log("accounts",accounts)
          data.accounts = accounts
        },
      }
      onMounted(()=>{
        func.init()
      })
      return {
        data,
        func
      }
    }
  }
</script>

<style lang="scss" scoped>
  .content{
    padding: 2rem ;
    box-sizing: border-box;
    display: grid;
    .panel{
      background-color: #fff;
      border: 1px solid $border-color;
      border-top: 3px solid $color-info;
      border-radius: 0.5rem;
      >.bottom{
        text-align: right;
        padding: 2rem;
      }
      &.panel-bottom{
        margin-bottom: 10rem;
      }
      >.title{
        font-weight: 700;
        font-size: 2.5rem;
        border-bottom: 1px solid $border-color;
        padding: 1.5rem 2rem;
        display: flex;
        justify-content: space-between;
      }
      >.body{
        padding: 1.5rem 2rem;
        >.ac-item{
          position: relative;
          min-height: 10rem;
          border-radius: 1rem;
          border: 1px solid $border-color;
          margin-bottom: 1.5rem;
          padding: 1rem;
          .cb-group{
            text-align: left;
            padding: 0 1rem 1rem 1rem;
          }
          >.title{
            font-size: 2.2rem;
            text-align: left;
            padding-left: 1rem;
            font-weight: bold;
            margin-bottom: 4rem;
          }
          >.btn-add{
            position: absolute;
            right: 1rem;
            top: 1rem;
          }
          >.cond{
            display: flex;
            flex-wrap: wrap;
            margin-top: 1rem;
            border: 1px solid $border-color;
            padding: 1rem;
            position: relative;
            >.img{
              display: flex;
              align-items: center;
              margin-top: 2rem;
              width: 100%;
              .label{
                font-size: 2rem;
                margin-right: 2rem;
              }
              .img-item{
                cursor: pointer;
                width: 25rem;
                height: 25rem;
                object-fit: cover;
              }
            }
            .close-icon{
              position: absolute;
              right: -6px;
              top: -6px;
              background-color: $color-danger;
              color: #fff;
              border-radius:50%;
              padding: 0.4rem;
              font-weight: bold;
              font-size: 2rem;
              cursor: pointer;
            }
            .inp{
              width: 45rem;
              margin-right: 1rem;
            }
          }
        }
        .inp{
          margin-bottom: 1rem;
          --el-color-info:#333;
          &.inline-inp{
            display: flex;
          }
          .inp-prepend{
            >.cb{
              --el-checkbox-font-size: 1.8rem;
            }
          }
        }
      }
    }
  }
</style>

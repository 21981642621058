<template>
  <div class="content">
    <div class="card-box">
      <div class="header">
        <span class="title">查询条件</span>
      </div>
      <div class="inp-list">
        <div class="item item-3">
          <span class="label">时间</span>
          <el-date-picker
                class="inp"
                v-model="formData.valTime"
                size="small"
                type="daterange"
                unlink-panels
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                :shortcuts="render.shortcuts"
              >
              </el-date-picker>
        </div>
        <div class="item">
          <span class="label">昵称或用户id</span>
          <el-input class="inp" size="small" v-model="formData.valNickname" placeholder="请输入昵称或用户id"></el-input>
        </div>
        <div class="item">
          <span class="label">单号</span>
          <el-input class="inp" size="small" v-model="formData.valOrderSn" placeholder="请输入单号"></el-input>
        </div>

      </div>
      <div class="btn-ct">
        <el-button @click="func.search" class="search" type="primary" size="small">查询</el-button>
      </div>
    </div>
    <div class="card-box data-table">
      <div class="header">
        <span class="title">共{{formData.total}}条，每页{{formData.pageSize}}条</span>
        <div class="header-icons">
          <!-- <i @click="func.loadData()" class="el-icon-download header-icon"></i> -->
          <!-- <i @click="func.refresh()" class="el-icon-refresh header-icon"></i> -->
        </div>
      </div>
      <el-table
            :data="formData.tableData"
            stripe
            style="width: 100%">
            <el-table-column
              prop="Id"
              label="ID"
              width="100">
            </el-table-column>
            <!-- <el-table-column
              prop="OrderSn"
              width="200"
              label="单号">
            </el-table-column> -->
            <el-table-column
              prop="UserId"
              width="100"
              label="用户id">
            </el-table-column>
            <el-table-column
              prop="Nickname"
              width="140"
              label="用户名">
            </el-table-column>
            <el-table-column
              prop="NumOrder"
              width="100"
              label="订单数">
            </el-table-column>
            <el-table-column
              prop="NumTerm"
              width="100"
              label="期数">
            </el-table-column>
            <!-- <el-table-column
              prop="WaterTypeStr"
              label="计算方式"
              width="140">
            </el-table-column> -->
            <el-table-column
              prop="Money"
              width="140"
              label="下注总额">
            </el-table-column>
            <el-table-column
              prop="MoneyCombo"
              width="140"
              label="组合金额">
            </el-table-column>
            <el-table-column
              prop="Profit"
              width="140"
              label="盈亏">
            </el-table-column>
            <!-- <el-table-column
              prop="Rate"
              width="140"
              label="回水比例">
            </el-table-column> -->
            <!-- <el-table-column
              prop="MoneyWater"
              width="140"
              label="应回水">
            </el-table-column> -->
            <el-table-column
              prop="ResultStr"
              width="240"
              label="结果">
              <template #default="scope">
                <div :class="'color-'+scope.row.ResultType">{{scope.row.ResultStr}}</div>
              </template>
            </el-table-column>
            <el-table-column
              prop="Day"
              min-width="200"
              label="时间">
            </el-table-column>
            <el-table-column
              width="180"
              label="操作">
              <template #default="scope">
                <el-button class="btn" size="mini" @click="func.showWater(scope.row)" type="primary" round>查看</el-button>
                <el-button v-if="scope.row.Status==0" size="mini" @click="func.waterEdit(scope.row)" type="danger" round>回水</el-button>
              </template>
            </el-table-column>
          </el-table>
    </div>
    <div class="pagination">
      <el-pagination
        background
        layout="prev, pager, next"
        @current-change="func.pageChange"
        :page-size="formData.pageSize"
        :current-page="formData.curPage"
        :total="formData.total">
      </el-pagination>
    </div>
    <el-dialog
      title="明细"
      v-model="formData.dialogVisible"
      width="70%">
      <div class="m-table">
        <div class="item">
          <div class="title">会员统计信息</div>
          <div class="header">
            <div class="td">充值次数</div>
            <div class="td">充值金额</div>
            <div class="td">充值赠送金额</div>
            <div class="td">提现次数</div>
            <div class="td">提现金额</div>
            <div class="td">盈亏总计</div>
            <div class="td">下注总计</div>
          </div>
          <div class="body">
            <div class="td">{{formData.userDetail.RechargeTimes}}</div>
            <div class="td">{{formData.userDetail.RechargeMoney.toFixed(2)}}</div>
            <div class="td">{{formData.userDetail.RechargeMoneyGift.toFixed(2)}}</div>
            <div class="td">{{formData.userDetail.WithdrawTimes}}</div>
            <div class="td">{{formData.userDetail.WithdrawMoney.toFixed(2)}}</div>
            <div class="td">{{formData.userDetail.Profit.toFixed(2)}}</div>
            <div class="td">{{formData.userDetail.MoneyBet.toFixed(2)}}</div>
          </div>
        </div>
        <div class="item">
          <div class="title">游戏统计</div>
          <div class="header">
            <div class="td">彩种类型</div>
            <div class="td">总下注</div>
            <div class="td">组合总分</div>
            <div class="td">组合比例</div>
            <div class="td">盈亏</div>
            <div class="td">竞猜期数</div>
            <div class="td">超无视次数</div>
          </div>
          <div class="body" v-for="(item,index) in formData.gameStats" :key="index">
            <div class="td">{{item.LotName}}</div>
            <div class="td">{{item.Total.toFixed(2)}}</div>
            <div class="td">{{item.Combo.toFixed(2)}}</div>
            <div class="td">{{item.ComboRate}}</div>
            <div class="td">{{item.Profit.toFixed(2)}}</div>
            <div class="td">{{item.NumTerm}}</div>
            <div class="td">{{item.NumIgnore}}</div>
          </div>
        </div>
        <div class="item">
          <div class="title">下注明细</div>
          <div class="header">
            <div class="td">期数</div>
            <div class="td">彩种类型</div>
            <div class="td">下注信息</div>
            <div class="td">下注时间</div>
            <div class="td">下注总额</div>
            <div class="td">盈亏</div>
            <div class="td">剩余积分</div>
          </div>
          <div class="body" v-for="(item,index) in formData.orderDetail" :key="index">
            <div class="td">{{item.TermNum}}</div>
            <div class="td">{{item.LotName}}</div>
            <div class="td">{{item.BetInfo}}</div>
            <div class="td">{{item.CreatedAtStr}}</div>
            <div class="td">{{item.Total.toFixed(2)}}</div>
            <div class="td">{{item.Profit.toFixed(2)}}</div>
            <div class="td">{{item.MoneyAfter.toFixed(2)}}</div>
          </div>
        </div>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="formData.dialogVisible = false">确 定</el-button>
        </span>
      </template>
    </el-dialog>

    <el-dialog
      title="回水"
      v-model="formData.dialogWaterConfirmVisible"
      width="40%">
      <div class="form">
        <div class="item">
          <span class="label">回水金额</span>
          <el-input class="inp" type="number" size="small" v-model="formData.valMoneyWater" placeholder="请输入回水金额"></el-input>
          <el-button class="btn-count" size="small" type="warning" @click="func.getWaterMoney16()">计算</el-button>
        </div>
        <div class="item">
          <span class="label">回红金额</span>
          <el-input class="inp" type="number" size="small" v-model="formData.valMoneyRed" placeholder="请输入回红金额"></el-input>
        </div>
        <div class="item">
          <span class="label">福利金额</span>
          <el-input class="inp" type="number" size="small" v-model="formData.valMoneyWelfare" placeholder="请输入福利金额"></el-input>
        </div>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="default" @click="formData.dialogWaterConfirmVisible = false">取 消</el-button>
          <el-button type="primary" @click="func.submit">确 定</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { reactive, onMounted,onUnmounted } from 'vue'
import axios from 'axios'
import { shortcuts } from '@/data/default'
import { toDay,warn,loading,tips } from "@/common/func"
import event from "@/common/eventHub"
import { ElMessageBox } from "element-plus"
export default {
  name: 'WaterList',
  setup () {
    const tableData = []
    const render = {
      shortcuts,
    }
    const formData = reactive({
      tableData,
      curData: {},
      gameStats:[],
      orderDetail:[],
      userDetail:{},
      dialogVisible:false,
      dialogWaterConfirmVisible:false,
      valTime: '',
      valOrderSn:'',
      valNickname:'',
      valMoneyWater:0,
      valMoneyRed:0,
      valMoneyWelfare:0,
      // pagination
      curPage: 1,
      pageSize: 10,
      total: 1
    })
    const func = {
      async showWater(row){
        loading.show()
        try{
          let res = await axios.get("/admin/water/detail",{
            params:{
              id:row.Id
            }
          })
          res.orderDetail.map((item)=>{
            item.CreatedAtStr = toDay(item.CreatedAt)
          })
          formData.gameStats = res.gameStats
          formData.orderDetail = res.orderDetail
          formData.userDetail = res.userDetail
        }catch(e){
          console.log("err",e)
        }
        loading.hide()
        formData.dialogVisible = true

      },
      search () {
        console.log('formData', formData)
        func.loadData(1)
      },
      pageChange (val) {
        console.log('page change', val)
        func.loadData(val)
      },
      refresh(){
        formData.valTime = '',
        func.loadData(1)
      },
      waterEdit (row) {
        console.log('waterEdit', row)
        if(row.Status!=0){
          return warn("记录已被处理")
        }
        formData.curData = row
        formData.valMoneyWater = ""
        formData.valMoneyRed = ""
        formData.valMoneyWelfare = ""
        formData.dialogWaterConfirmVisible = true
      },
      async getWaterMoney16(){
        loading.show()
        try{
          const res = await axios.get("/admin/water/money16?id="+formData.curData.Id)
          formData.valMoneyWater = res.waterMoney
        }catch(e){

        }
        loading.hide()
      },
      async submit(){
        // formData.curData.Status = 1
        if(formData.valMoneyWater == "" || formData.valMoneyRed == "" || formData.valMoneyWelfare == ""){
          return warn("请输入正确的金额")
        }
        formData.dialogWaterConfirmVisible = false
        loading.show()
        try{
          await axios.post("/admin/water",{
            id:formData.curData.Id,
            status:formData.curData.Status,
            money:formData.valMoneyWater,
            moneyRed:formData.valMoneyRed,
            moneyWelfare:formData.valMoneyWelfare,
          })
          await this.loadData(formData.curPage)
        }catch(e){

        }
        formData.dialogVisible = false
        loading.hide()
        tips("操作成功")
      },
      async loadData(page=1){
        try{
          formData.tableData = []
          formData.curPage = page
          let params = {
              page,
          }
          if(formData.valOrderSn){
            params.orderSn = formData.valOrderSn
          }
          if(formData.valNickname){
            let id = parseInt(formData.valNickname)
            if(isNaN(id)){
              params.nickname = formData.valNickname
            }else{
              params.userId = formData.valNickname
            }
          }
          if(formData.valTime){
            params.startAt = Math.floor(formData.valTime[0].getTime()/1000)
            params.endAt = Math.floor(formData.valTime[1].getTime()/1000)
          }
          let res = await axios.get(`/admin/water`,{
            params
          })
          formData.total = res.Total
          res.List.map((item)=>{
            item.CreatedAtStr = toDay(item.CreatedAt)
            item.Profit = (item.MoneyWin - item.MoneyLose).toFixed(2)
            item.ResultStr =  ""
            item.ResultType = 0
            item.Nickname = item.Nickname + "/" + (item.Realname || "未绑定")
            if(item.Status==0){
              item.ResultStr = "未回水"
              item.ResultType = 1
            }else if(item.Status==1){
              item.ResultStr = `退水:${item.MoneyWaterReal} | 回红:${item.MoneyRed} | 福利:${item.MoneyWelfare}`
              item.ResultType = 2
            }else if(item.Status==2){
              item.ResultStr ="已拒绝"
              item.ResultType = 3
            }
            if(item.WaterType === 1){
              item.WaterTypeStr = "按流水"
            }else if(item.WaterType === 2){
              item.WaterTypeStr = "按盈利"
            }else if(item.WaterType === 3){
              item.WaterTypeStr = "按亏损"
            }
          })
          formData.tableData = res.List
          formData.pageSize = res.PageSize
        }catch(e){
          console.log("loadData",e)
        }
      }
    }
    const handleEventMsg = (msg)=>{
      if(msg.name=="withdraw"){
        func.loadData(1)
      }
    }
    onMounted(() => {
      console.log("onMounted")
      func.loadData()
      event.$on("event",handleEventMsg)
    })
    onUnmounted(()=>{
      event.$off("event",handleEventMsg)
    })
    return {
      func,
      render,
      formData
    }
  }
}
</script>

<style lang="scss" scoped>
.content{
  box-sizing: border-box;
  padding: 1.25rem;
  margin-bottom: 6.25rem;
  .btn-count{
    margin-left: 1rem;
  }
  .form{
    >.item{
      display: flex;
      margin-top: 1.5rem;
      align-items: center;
      .label{
        width: 30%;
      }
      .inp{
        flex: 1;
      }
    }
  }
  .m-table{
    >.item{
      margin-bottom: 1rem;
      >.title{
        font-weight: 700;
        font-size: 2rem;
        margin-bottom: 0.8rem;
        text-align: left;
      }
      >.header{
        display: flex;
        >.td{
          background-color: $color-primary;
          color: #fff;
        }
      }
      >.body{
        display: flex;
      }
      .td{
        padding: 0.5rem;
        margin-right: 0.5rem;
        width: 18rem;
        text-align: center;
      }
    }
  }
  .color-0{
    color: $color-warning;
    font-weight: bold;
  }
  .color-1{
    color: $color-info;
    font-weight: bold;
  }
  .color-2{
    color: $color-success;
    font-weight: bold;
  }
  .color-3{
    color: $color-error;
    font-weight: bold;
  }
  .avatar{
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
  }
  .inp-block{
    display: block;
  }
  >.card-box{
    font-size: 0.875rem;
    background-color: #fff;
    box-shadow: $box-shadow;
    box-sizing: border-box;
    padding: 1.25rem;
    border-radius: 0.3rem;
    margin-bottom: 1.25rem;
    .header{
      text-align:left;
      margin-bottom: 1.25rem;
      position: relative;
      >.title{
        font-size:2rem;
        font-weight: 500;
      }
      >.header-icons{
        position: absolute;
        right: 10px;
        top: 0px;
        z-index: 100;
        .header-icon{
          font-size: 0.875rem;
          font-weight: 500;
          border: 1px solid #ddd;
          color:#969696;
          padding: 0.25rem;
          border-radius: 50%;
          color: pointer;
          margin-left: 0.625rem;
          cursor: pointer;
        }
      }
    }
    >.btn-ct{
      text-align: right;
      :deep(.el-button--small){
        padding: 0.625rem 2rem;
      }
    }
    .inp-list{
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      >.item{
        width: 32%;
        display: flex;
        align-items: center;
        margin-bottom: 1.25rem;
        &.item-2{
          width: 99%;
        }
        &.item-3{
          width: 64%;
        }
        .label{
          margin-right: 0.625rem;
          width: 14rem;
          font-size: 1.8rem;

        }
        :deep(.el-range-editor--small.el-input__inner){
          flex: 1;
        }
        .inp{
          flex: 1;
        }
      }
    }
  }
  >.el-alert-my{
    margin-bottom: 1.25rem;
    box-shadow: $box-shadow;
    padding: 1.25rem;
    .el-alert-my-txt{
      font-size: 1.8rem;
      >.tips{
        font-weight: bold;
      }
    }
    :deep(.el-alert__closebtn){
      top: 1.25rem;
      font-size: 1rem;
    }
  }
  >.data-table{
    :deep(.el-table){
      .cell{
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      .el-table__row{
        cursor: pointer;
      }
    }
  }
  >.pagination{
    text-align: right;
    margin-top: 1.25rem;
  }
  .el-form-row{
    display:flex;
    justify-content: space-between;
    :deep(.el-form-item){
      width: 49%;
    }
  }
  .el-select-my{
    text-align: left;
    display: block;
  }
}
</style>

<template>
  <div class="content">
    <div class="card-box">
      <div class="header">
        <span class="title">查询条件</span>
      </div>
      <div class="inp-list">
      	<div class="item item-2">
      		<span class="label">月份</span>
      		<el-date-picker size="small" class="inp" v-model="formData.valTime" type="month" placeholder="选择时间">
      		</el-date-picker>
      	</div>
      </div>
      <div class="btn-ct">
        <el-button @click="func.search" class="search" type="primary" size="small">查询</el-button>
      </div>
    </div>
    <div class="card-box data-table">
      <div class="header">
        <span class="title">共{{formData.total}}条，每页{{formData.pageSize}}条</span>
        <div class="header-icons">
          <!-- <i @click="func.loadData()" class="el-icon-download header-icon"></i> -->
          <!-- <i @click="func.refresh()" class="el-icon-refresh header-icon"></i> -->
        </div>
      </div>
      <el-table
            :data="formData.tableData"
            style="width: 100%">
            <el-table-column
              prop="UserId"
              label="用户ID"
              width="100">
            </el-table-column>
            <el-table-column
              prop="Nickname"
              label="用户名"
              width="200">
            </el-table-column>
            <el-table-column
              prop="Profit"
              label="总盈亏"
              width="140">
            </el-table-column>
            <el-table-column
              prop="MoneyOrder"
              width="140"
              label="总下注">
            </el-table-column>
            <el-table-column
              prop="NumOrder"
              width="140"
              label="总下注量">
            </el-table-column>
            <el-table-column
              prop="MoneyRecharge"
              width="140"
              label="总上分">
            </el-table-column>
            <el-table-column
              prop="MoneyWithdraw"
              width="140"
              label="总下分">
            </el-table-column>
            <el-table-column
              prop="MoneyRechargeGift"
              width="140"
              label="充值赠送">
            </el-table-column>
            <el-table-column
              prop="MoneyWater"
              min-width="140"
              label="总回水">
            </el-table-column>
          </el-table>
    </div>
    <div class="pagination">
      <el-pagination
        background
        layout="prev, pager, next"
        @current-change="func.pageChange"
        :page-size="formData.pageSize"
        :current-page="formData.curPage"
        :total="formData.total">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { reactive, onMounted } from 'vue'
import axios from 'axios'
import { shortcuts } from '@/data/default'
import { toDay,loading } from "@/common/func"
export default {
  name: 'OrderReport',
  setup () {
    const tableData = []
    const render = {
      shortcuts,
    }
    const now = new Date()
    const valTime = new Date(`${now.getFullYear()}/${now.getMonth()+1}/01 00:00:00`)
    const formData = reactive({
      tableData,
      curData: {},
      valTime,
      // pagination
      curPage: 1,
      pageSize: 10,
      total: 1
    })
    const func = {
      search () {
        console.log('formData', formData)
        func.loadData(1)
      },
      pageChange (val) {
        console.log('page change', val)
        func.loadData(val)
      },
      refresh(){
        formData.valTime = '',
        formData.valLotId = 0,
        formData.valTermNum = '',
        func.loadData(1)
      },
      async loadData(page=1){
        loading.show()
        try{
          formData.tableData = []
          formData.curPage = page
          let params = {
              page,
          }
          const date = new Date(formData.valTime.getTime())
          params.startAt = Math.floor(date.getTime() / 1000)
          date.setMonth(date.getMonth() + 1)
          params.endAt = Math.floor(date.getTime() / 1000)

          let res = await axios.get(`/admin/balance/report/month`,{
            params
          })
          formData.total = res.Total
          res.List.map((item)=>{
            item.Nickname = item.Nickname + "/" + (item.Realname || "未绑定")
            item.Profit = item.Profit.toFixed(2)
            item.MoneyOrder = item.MoneyOrder.toFixed(2)
            item.MoneyRecharge = item.MoneyRecharge.toFixed(2)
            item.MoneyRechargeGift = item.MoneyRechargeGift.toFixed(2)
            item.MoneyWater = item.MoneyWater.toFixed(2)
            item.MoneyWithdraw = item.MoneyWithdraw.toFixed(2)
          })
          formData.tableData = res.List
          formData.pageSize = res.PageSize
        }catch(e){
          console.log("loadData",e)
        }
        loading.hide()
      }
    }
    onMounted(() => {
      console.log("onMounted")
      func.loadData()
    })
    return {
      func,
      render,
      formData
    }
  }
}
</script>

<style lang="scss" scoped>
.content{
  box-sizing: border-box;
  padding: 1.25rem;
  margin-bottom: 6.25rem;
  .avatar{
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
  }
  .inp-block{
    display: block;
  }
  >.card-box{
    font-size: 0.875rem;
    background-color: #fff;
    box-shadow: $box-shadow;
    box-sizing: border-box;
    padding: 1.25rem;
    border-radius: 0.3rem;
    margin-bottom: 1.25rem;
    .header{
      text-align:left;
      margin-bottom: 1.25rem;
      position: relative;
      >.title{
        font-size:2rem;
        font-weight: 500;
      }
      >.header-icons{
        position: absolute;
        right: 10px;
        top: 0px;
        z-index: 100;
        .header-icon{
          font-size: 0.875rem;
          font-weight: 500;
          border: 1px solid #ddd;
          color:#969696;
          padding: 0.25rem;
          border-radius: 50%;
          color: pointer;
          margin-left: 0.625rem;
          cursor: pointer;
        }
      }
    }
    >.btn-ct{
      text-align: right;
      :deep(.el-button--small){
        padding: 0.625rem 2rem;
      }
    }
    .inp-list{
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      >.item{
        width: 32%;
        display: flex;
        align-items: center;
        margin-bottom: 1.25rem;
        &.item-2{
          width: 99%;
        }
        .label{
          margin-right: 0.625rem;
          width: 10rem;
          font-size: 1.8rem;
        }
        :deep(.el-range-editor--small.el-input__inner){
          flex: 1;
        }
        .inp{
          flex: 1;
        }
      }
    }
  }
  >.el-alert-my{
    margin-bottom: 1.25rem;
    box-shadow: $box-shadow;
    padding: 1.25rem;
    .el-alert-my-txt{
      font-size: 0.875rem;
      >.tips{
        font-weight: bold;
      }
    }
    :deep(.el-alert__closebtn){
      top: 1.25rem;
      font-size: 1rem;
    }
  }
  >.data-table{
    :deep(.el-table){
      .cell{
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      .el-table__row{
        cursor: pointer;
      }
    }
  }
  >.pagination{
    text-align: right;
    margin-top: 1.25rem;
  }
  .el-form-row{
    display:flex;
    justify-content: space-between;
    :deep(.el-form-item){
      width: 49%;
    }
  }
  .el-select-my{
    text-align: left;
    display: block;
  }
}
</style>

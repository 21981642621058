import { reactive,nextTick } from 'vue'
import storage from "@/common/storage"
import WebSocket from "@/common/Websocket"
import { updateLots } from "@/data/lot"
const key = "chat28-token"
export const user = reactive({
  name:"",
  id:"",
  token:"",
  wsId:"",
  avatar:"",
  type:"",
  chatname:""
})
export const logout = ()=>{
  user.token = ""
  user.id = ""
  user.name = ""
  user.type = ""
  user.wsId = ""
  user.avatar = ""
  storage.set(key,user)
  const ws = WebSocket.getInstance()
  ws.close()
}
export const setUser = (obj)=>{
  user.token = obj.Token
  user.id = obj.Id
  user.name = obj.Nickname
  user.type = obj.Id == 1 ? "admin" : "employee"
  user.wsId = obj.WsId
  user.avatar = obj.Avatar
  user.chatname = obj.Chatname
  storage.set(key,user)
  //登录之后要执行的操作
  loginDo()
}
//load user
const userData = storage.get(key)
if(userData){
  user.token = userData.token
  user.id = userData.id
  user.name = userData.name
  user.type = userData.type
  user.wsId = userData.wsId
  user.avatar = userData.avatar
  user.chatname = userData.chatname
  //登录之后要执行的操作
  loginDo()
}
function loginDo(){
  const ws = WebSocket.getInstance()
  ws.bindUid(user.wsId)
  nextTick(()=>{
    updateLots()
  })
}

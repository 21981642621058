<template>
  <div class="content">
    <div class="card-box">
      <div class="header">
        <span class="title">查询条件</span>
      </div>
      <div class="inp-list">
        <div class="item item-2">
          <span class="label">开奖时间</span>
          <el-date-picker
                class="inp"
                v-model="formData.valTime"
                size="small"
                type="daterange"
                unlink-panels
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                :shortcuts="render.shortcuts"
              >
              </el-date-picker>
        </div>
        <div class="item">
          <span class="label">彩种</span>
          <el-select class="inp" size="small" v-model="formData.valLotId" placeholder="请选择">
              <el-option
                v-for="item in formData.lots.search"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
        </div>
        <div class="item">
          <span class="label">期数</span>
          <el-input class="inp" size="small" v-model="formData.valTermNum" placeholder="请输入期数"></el-input>
        </div>

      </div>
      <div class="btn-ct">
        <el-button @click="func.search" class="search" type="primary" size="small">查询</el-button>
      </div>
    </div>
    <div class="card-box data-table">
      <div class="header">
        <span class="title">共{{formData.total}}条，每页{{formData.pageSize}}条</span>
        <div class="header-icons">
          <i @click="func.add()" class="el-icon-plus header-icon"></i>
          <!-- <i @click="func.refresh()" class="el-icon-refresh header-icon"></i> -->
        </div>
      </div>
      <el-table
            :data="formData.tableData"
            style="width: 100%">
            <el-table-column
              prop="Id"
              label="ID"
              width="100">
            </el-table-column>
            <el-table-column
              prop="LotId"
              width="100"
              label="彩种ID">
            </el-table-column>
            <el-table-column
              prop="LotName"
              label="类型"
              width="200">
            </el-table-column>
            <el-table-column
              prop="TermNum"
              width="140"
              label="期号">
            </el-table-column>
            <el-table-column
              prop="Result"
              width="140"
              label="结果">
            </el-table-column>
            <el-table-column
              prop="ProfitStr"
              width="140"
              label="盈利">
            </el-table-column>
            <el-table-column
              prop="Type"
              width="140"
              v-if="user.type == 'admin'"
              label="来源">
            </el-table-column>
            <el-table-column
              prop="OpenTimeStr"
              min-width="200"
              label="开奖时间">
            </el-table-column>
            <el-table-column
              width="180"
              label="操作">
              <template #default="scope">
                <el-button class="btn" size="mini" @click="func.delete(scope.row)" type="primary" round>删除</el-button>
              </template>
            </el-table-column>
          </el-table>
    </div>
    <div class="pagination">
      <el-pagination
        background
        layout="prev, pager, next"
        @current-change="func.pageChange"
        :page-size="formData.pageSize"
        :current-page="formData.curPage"
        :total="formData.total">
      </el-pagination>
    </div>

    <el-dialog
      title="手动开奖"
      v-model="formData.diaglogFlag"
      width="50%">
      <el-form label-position="top" label-width="80px" :model="formData.addParams">
        <div class="el-form-row">
          <el-select class="inp" size="small" v-model="formData.addParams.lotType" placeholder="请选择彩种">
              <el-option
                v-for="item in formData.openLots"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
        </div>
        <div class="el-form-row">
          <el-form-item label="期号">
            <el-input v-model="formData.addParams.termNum" placeholder="纯数字的期号,不要填错"></el-input>
          </el-form-item>
          <el-form-item label="开奖结果">
            <el-input v-model="formData.addParams.result" placeholder="例如:0,9,6"></el-input>
          </el-form-item>
        </div>

      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="formData.diaglogFlag = false">取 消</el-button>
          <el-button type="primary" @click="func.addDo()">确 定</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { reactive, onMounted } from 'vue'
import axios from 'axios'
import { shortcuts } from '@/data/default'
import { lots } from '@/data/lot'
import { toDay } from "@/common/func"
import { user } from '@/data/token'
import { ElMessageBox } from 'element-plus'
export default {
  name: 'LotsResult',
  setup () {
    const tableData = []
    const statuses = [
      {
        label: '全部',
        value: -1
      },
      {
        label: '可用',
        value: 1
      },
      {
        label: '不可用',
        value: 0
      }
    ]
    const openLots = [
      {
        label: '请选择彩种',
        value: -1
      },
      {
        label: '加拿大28',
        value: 0
      },
      {
        label: '极速28',
        value: 1
      },
      {
        label: '自开极速',
        value: 2
      },
    ]
    const render = {
      shortcuts,
      statuses,
    }
    const formData = reactive({
      tableData,
      curData: {},
      valTime: '',
      valLotId: 0,
      valTermNum: '',
      //add
      openLots,
      diaglogFlag:false,
      addParams:{
        lotType:-1,
        termNum:"",
        result:"",
      },
      // pagination
      curPage: 1,
      pageSize: 10,
      lots,
      total: 1
    })
    const func = {
      async delete(item){
        try{
          await ElMessageBox.confirm('是否要删除这期?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          })
          await axios.delete(`/admin/term?id=${item.Id}`,{})
          func.loadData()
        }catch(e){
          console.log("error",e)
        }
      },
      add(){
        formData.diaglogFlag = true
      },
      async addDo(){
        await axios.post("/admin/term/manul",formData.addParams)
        await func.loadData()
        formData.diaglogFlag = false
      },
      search () {
        console.log('formData', formData)
        func.loadData(1)
      },
      pageChange (val) {
        console.log('page change', val)
        func.loadData(val)
      },
      refresh(){
        formData.valTime = '',
        formData.valLotId = 0,
        formData.valTermNum = '',
        func.loadData(1)
      },
      async loadData(page=1){
        try{
          formData.tableData = []
          formData.curPage = page
          let params = {
              page,
          }
          if(formData.valLotId){
            params.lotId = formData.valLotId
          }
          if(formData.valTermNum){
            params.termNum = formData.valTermNum
          }
          if(formData.valTime){
            params.startAt = Math.floor(formData.valTime[0].getTime()/1000)
            params.endAt = Math.floor(formData.valTime[1].getTime()/1000)
          }
          let res = await axios.get(`/admin/lot/result`,{
            params
          })
          formData.total = res.Total
          res.List.map((item)=>{
            item.CreatedAtStr = toDay(item.CreatedAt)
            item.OpenTimeStr = toDay(item.OpenTime)
            item.ProfitStr = item.IsCount ? item.Profit.toFixed(2) : "结算中"
          })
          formData.tableData = res.List
          formData.pageSize = res.PageSize
        }catch(e){
          console.log("loadData",e)
        }
      }
    }
    onMounted(() => {
      console.log("onMounted")
      func.loadData()
    })
    return {
      func,
      render,
      user,
      formData
    }
  }
}
</script>

<style lang="scss" scoped>
.content{
  box-sizing: border-box;
  padding: 1.25rem;
  margin-bottom: 6.25rem;
  .avatar{
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
  }
  .inp-block{
    display: block;
  }
  >.card-box{
    font-size: 0.875rem;
    background-color: #fff;
    box-shadow: $box-shadow;
    box-sizing: border-box;
    padding: 1.25rem;
    border-radius: 0.3rem;
    margin-bottom: 1.25rem;
    .header{
      text-align:left;
      margin-bottom: 1.25rem;
      position: relative;
      >.title{
        font-size:2rem;
        font-weight: 500;
      }
      >.header-icons{
        position: absolute;
        right: 10px;
        top: 0px;
        z-index: 100;
        .header-icon{
          font-size: 2.2rem;
          font-weight: 700;
          border: 1px solid #ddd;
          color:#969696;
          padding: 0.25rem;
          border-radius: 50%;
          color: pointer;
          margin-left: 0.625rem;
          cursor: pointer;
        }
      }
    }
    >.btn-ct{
      text-align: right;
      :deep(.el-button--small){
        padding: 0.625rem 2rem;
      }
    }
    .inp-list{
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      >.item{
        width: 32%;
        display: flex;
        align-items: center;
        margin-bottom: 1.25rem;
        &.item-2{
          width: 99%;
        }
        .label{
          margin-right: 0.625rem;
          width: 10rem;
          font-size: 1.8rem;
        }
        :deep(.el-range-editor--small.el-input__inner){
          flex: 1;
        }
        .inp{
          flex: 1;
        }
      }
    }
  }
  >.el-alert-my{
    margin-bottom: 1.25rem;
    box-shadow: $box-shadow;
    padding: 1.25rem;
    .el-alert-my-txt{
      font-size: 0.875rem;
      >.tips{
        font-weight: bold;
      }
    }
    :deep(.el-alert__closebtn){
      top: 1.25rem;
      font-size: 1rem;
    }
  }
  >.data-table{
    :deep(.el-table){
      .cell{
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      .el-table__row{
        cursor: pointer;
      }
    }
  }
  >.pagination{
    text-align: right;
    margin-top: 1.25rem;
  }
  .el-form-row{
    display:flex;
    justify-content: space-between;
    :deep(.el-form-item){
      width: 49%;
    }
  }
  .el-select-my{
    text-align: left;
    display: block;
  }
}
</style>

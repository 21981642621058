import { user } from '@/data/token'
import shortid from './shortid'
class AkWebSocket {
	constructor() {
		this.room_ids = []
		this.uid = ""
    this.ws = null
	}
	static init () {
		if (!this.instance) {
			this.instance = new AkWebSocket()
			this.instance._connect()
		}
		return this.instance
	}
	//获取实例
	static getInstance () {
		return this.init()
	}
  close(){
    this.room_ids = []
    this.uid = ""
    // this.ws.close()
    // this.ws = null
  }
	//发送给指定群
	sendToGroup (room_id, data, ack = 0) {
		this.sendMsg({
			"type": "send_to_room",
			"data": {data,to:room_id},
			"ack": ack
		})
	}
	//发送给所有人
	sendToAll (data, ack = 0) {
		this.sendMsg({
			"type": "send_to_all",
			"data": {data},
			"ack": ack
		})
	}
	//发送给用户
	sendToUid (uid, data, ack = 0) {
		uid += ""
		return this.sendMsg({
			"type": "send_to_uid",
			"data": {data,to:uid},
			"ack": ack
		})
	}
	//发送pong
	sendPong () {
		this.sendMsg({
			"type": "pong",
			"data": {},
			"ack" : 0
		})
	}
	sendPing(){
		this.sendMsg({
			"type": "ping",
			"data": {},
			"ack" : 0
		})
	}
	//加入群聊
	joinGroup (room_id) {
		let flag = false
		for (let i in this.room_ids) {
			if (this.room_ids[i] === room_id) {
				flag = true
			}
		}
		if (!flag) this.room_ids.push(room_id)
		this.sendMsg({
			"type": "join_room",
			"data": {room_id},
			"ack": 1
		})
	}
	//退出群聊
	exitGrop (room_id) {
		let room_ids = this.room_ids
		let new_room_ids = []
		for (let i in room_ids) {
			if (room_ids[i] !== room_id) {
				new_room_ids.push(room_ids[i])
			}
		}
		this.room_ids = new_room_ids
		this.sendMsg({
			"type": "exit_room",
			"data": {room_id},
			"ack": 1
		})
	}
	//绑定用户id,不绑定无法实现单聊
	bindUid (uid,cb) {
		this.uid = uid + ""

		if(typeof cb == "function"){
			this._onBindUid = cb
		}

		this.sendMsg({
			"type": "bind_uid",
			"data": {uid},
			"ack": 1
		})
	}
	//发送消息(基类)
	sendMsg (data) {
		this._log(data, "Send WebSocket Msg")
		let time = new Date().getTime()
		if(["bind_uid","join_room","exit_room"].includes(data.type)){
			data['id'] = data.type
		}else{
			data['id'] = shortid.gen()
      data['data']['id'] = data['id']
		}
		//token
		if(user.token){
			data['token'] = user.token
		}
		data['timestamp'] =  Math.round(time / 1000)
    const msgId = data['id']
		data = JSON.stringify(data)
		this.onOpenOnce(()=>{
      this.ws.send(data)
    })
    return msgId
	}
	onBindUid(){
		if(typeof this._onBindUid == "function"){
			this._onBindUid()
		}
	}
	onJoinRoom(){

	}
	onExitRoom(){

	}
	//监听消息
	onMessage (cb) {
		this.onMessageCallBack = cb
	}
  //只执行一次的监听
  onOpenOnce(cb){
    if (this.isConnected){
      return cb()
    }
    const func = this.onOpenOnceCallBack
    this.onOpenOnceCallBack = ()=>{
      if (typeof func === "function") {
      	func()
      }
      cb()
    }
  }
	onOpen (cb) {
		const func = this.onOpenCallBack
    this.onOpenCallBack = ()=>{
      if (typeof func === "function") {
      	func()
      }
      cb()
    }
		if (this.isConnected) cb()
	}
	onError (cb) {
		this.onErrorCallBack = cb
	}
	onClose (cb) {
		this.onCloseCallBack = cb
	}
	_connect () {
		this._log("connect")
		const checkPingTimeSpace = 5000 //检测ping时间的间隔
		const timeOutForWS = 60000 //多少秒没接收到ping视为超时
		const WS_URL = `ws://${window.location.host}/ws`
    // const WS_URL = `ws://127.0.0.1:8008/ws`
    console.log("WS_URL",WS_URL)
		if (this.checkPingItv) {
			clearInterval(this.checkPingItv)
			this.checkPingItv = null
		}
		this.checkPingItv = setInterval(() => {
			let time = new Date().getTime()
			if (time - this.lastPing >= timeOutForWS) {
				clearInterval(this.checkPingItv)
				this.checkPingItv = null
				this._reconnect()
			}
		}, checkPingTimeSpace)

    this.ws = new WebSocket(WS_URL)


		this.ws.onopen = (e) => {
			this._onOpen()
			if (typeof this.onOpenCallBack === "function") {
				this.onOpenCallBack()
			}
      if(typeof this.onOpenOnceCallBack === "function"){
        this.onOpenOnceCallBack()
        this.onOpenOnceCallBack = null
      }
		}
		this.ws.onmessage = (msg) => {
			this._onMessage(msg)
			if (typeof this.onMessageCallBack === "function") {
				this.onMessageCallBack(msg)
			}
		}
		this.ws.onerror = (e) => {
			this._onError(e)
			if (typeof this.onErrorCallBack === "function") {
				this.onErrorCallBack(e)
			}
		}
		this.ws.onclose = (e) => {
			this._onClose(e)
			if (typeof this.onCloseCallBack === "function") {
				this.onCloseCallBack(e)
			}
		}
	}
	_reconnect () {
		this._log("reconnect")
		try {
			if (this.isConnected) {
				this.ws.close()
			}
		} catch (e) {

		}
    this._connect()
	}
	_log (data, tag = "WebSocket") {
		// console.log(tag, data)
	}
	_onOpen () {
		this._log("open")
		this.isConnected = true
		this.lastPing = new Date().getTime()
		this.sendPingTime = 15;

		//重连后要重新绑定uid和roomid
		if(this.uid){
			this.bindUid(this.uid,()=>{
				console.log("on bind success")
				for (let i in this.room_ids) {
					this.joinGroup(this.room_ids[i])
				}
			})
		}
		//发送ping
		if (this.sendPingItv) {
			clearInterval(this.sendPingItv)
			this.sendPingItv = null
		}
		this.sendPingItv = setInterval(()=>{
			this.sendPing()
		},this.sendPingTime * 1000)
	}
	//全局监听消息
	_onMessage (e) {
		this._log(e, "WebSocket Msg")
		try {
			let data = JSON.parse(e.data)
			if (data.type == 'pong') {
				this.lastPing = new Date().getTime()
			}else if (data.type == "close") {
				this.ws.close()
			}
			if(data.id == "bind_uid"){
				this.onBindUid(data.data.uid)
			}else if(data.id == "join_room"){
				this.onJoinRoom(data.data.room_id)
			}else if(data.id == "exit_room"){
				this.onExitRoom(data.data.room_id)
			}
		} catch (e) {
			this._log(e)
		}
	}
	_onClose (e) {
		this._log("close")
		this.isConnected = false
    if(this.toH)return
		this.toH = setTimeout(()=>{
      this.toH = null
			this._reconnect()
		},3000)
	}
	_onError (e) {
		this._log(e, "WebSocket Error")
		// this.isConnected = false
		// setTimeout(()=>{
		// 	this._reconnect()
		// },3000)
	}
}
export default AkWebSocket;
